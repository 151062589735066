import { DeleteTwoTone, EditOutlined, EyeOutlined, LockOutlined, SearchOutlined, SendOutlined } from '@ant-design/icons';
import React, { useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Button, Input, Space, Table, Tag } from 'antd';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import { Link } from 'react-router-dom';
import saleService from '../../service/saleService';
import ResponsiveDialog from '../dialog/dialog';
import { useNavigate } from 'react-router-dom';

const NewTableComponent = ({data}) => {

  const usuario = JSON.parse(localStorage.getItem('user_token'));
  const permissionSale = usuario.roles.filter(item=>item.name === "Vendas")[0];
  const permSendSale = usuario.roles.filter(item=>item.name === "Enviar Vendas")[0];
  const permissionReopen = usuario.roles.filter(item=>item.name === "Reabrir Vendas")[0];  

  let navigate = useNavigate();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const [openDialog, setOpenDialog] = useState();
  const [messageDialog, setMessageDialog] = useState();
  const [titleDialog, setTitleDialog] = useState();
  const [statusDialog, setStatusDialog] = useState();

  const [openDialogFree, setOpenDialogFree] = useState();
  const [messageDialogFree, setMessageDialogFree] = useState();
  const [titleDialogFree, setTitleDialogFree] = useState();
  const [statusDialogFree, setStatusDialogFree] = useState();


  function refreshPage() {
    window.location.reload(false);
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
          position: 'fixed',
          background: '#FFF'
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Pesquisar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Procurar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Limpar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Fechar
          </Button>
        </Space>
      </div>
    ),

    filterIcon: (filtered) => (

      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />

    ),

    onFilter: (value, record) =>{
      
      if(typeof value === 'string'){
        return record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase())
      }
      else{
        return record[dataIndex] === value;
      }

    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });


  const showStatusSituationSale = (status) => {
        
      if(status === 'Em Orcamento'){
        return "geekblue";
      }else if (status === 'Venda'){
          return "success";
      }else if (status === 'Em Autorizacao'){
        return "warning";
          
      }else if (status === 'Orcamento recusado') {
        return "error";
      }

  }

  const showLog = (log)=> {

      setOpenDialogFree(true);
      setStatusDialogFree('warning')
      setTitleDialogFree(log.title)
      setMessageDialogFree(log.description)

  }

  const deleteService = (id) => {

    saleService.delete(id).then(item=> {
        setOpenDialog(true);
        setStatusDialog('success')
        setTitleDialog("Muito bem!")
        setMessageDialog("Venda deletada com sucesso")

    }).catch(error=>{
        setOpenDialog(true);
        setStatusDialog('error')
        setTitleDialog("Ops!")
        setMessageDialog(error)
    })

  }

  const sendSale = (id) => {

      var data = {
          issend: true,
          idsale: id
      }
      
      saleService.sendSale(data).then(item=> {
          setOpenDialog(true);
          setStatusDialog('success')
          setTitleDialog("Muito bem!")
          setMessageDialog("Venda enviada com sucesso, nesse momento a venda não pode ser mais alterada.")

      }).catch(error=>{
          setOpenDialog(true);
          setStatusDialog('error')
          setTitleDialog("Ops!")
          setMessageDialog(error)
      })
  }

  const columns = [
    {
        title: 'Data Venda',
        dataIndex: 'dateSale',
        key: 'dateSale',
        width: '13%',
        ...getColumnSearchProps('dateSale'),
        sorter: (a, b) => a.dateSale?.length - b.dateSale?.length,
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Código P.I',
        dataIndex: 'codPi',
        width: '13%',
        key: 'codPi',
        ...getColumnSearchProps('codPi'),
        sorter: (a, b) => a.codPi - b.codPi,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Código Interno',
        dataIndex: 'codIntern',
        width: '13%',
        key: 'codIntern',
        ...getColumnSearchProps('codIntern'),
        sorter: (a, b) => a.codIntern - b.codIntern,
        sortDirections: ['descend', 'ascend'],
      },
      {
          title: 'Cliente',
          dataIndex: 'client',
          key: 'client',
          width: '55%',
          ...getColumnSearchProps('client'),
          sorter: (a, b) => a.client?.length - b.client?.length,
          sortDirections: ['descend', 'ascend'],
      },
      
      {
        title: 'Campanha',
        dataIndex: 'name',
        key: 'name',
        width: '55%',
        ...getColumnSearchProps('name'),
        sorter: (a, b) => a.name?.length - b.name?.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Status',
        dataIndex: 'situation',
        key: 'situation',
        ...getColumnSearchProps('situation'),
        sorter: (a, b) => a.situation?.length - b.situation?.length,
        sortDirections: ['descend', 'ascend'],
        render: (tags) => (
        <span>
            {
              <Tag color={showStatusSituationSale(tags)} key={1}>
                  {tags}
              </Tag>
            }
        </span>
        ),
    },
    {
        title: 'Valor',
        dataIndex: 'vlAFaturar',
        key: 'vlAFaturar',
        // ...getColumnSearchProps('vlAFaturar'),
        sorter: (a, b) => a.vlAFaturar?.length - b.vlAFaturar?.length,
        sortDirections: ['descend', 'ascend'],
        render: (_, record) => (
          <span>
              {
                <>
                <Tag>
                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(record.vlAFaturar)}
                </Tag>
                <span style={{fontSize: '12px'}}>
                    {record.totalContrato > 0 ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(record.totalContrato) : ''}
                </span>
                </>
              }
          </span>
          ),
    },
    
    {
        title: 'Ticket Médio',
        dataIndex: 'ticketMiddle',
        key: 'ticketMiddle',
        // ...getColumnSearchProps('ticketMiddle'),
        sorter: (a, b) => a.ticketMiddle?.length - b.ticketMiddle?.length,
        sortDirections: ['descend', 'ascend'],
        render: (ticket) => (
          <span>
              {
                new Intl.NumberFormat('pt-BR').format(ticket)
              }
          </span>
          ),
    },
    {
        title: 'Painéis',
        dataIndex: 'qtdPanel',
        key: 'qtdPanel',
        // width: '5%',
        // ...getColumnSearchProps('qtdPanel'),
        sorter: (a, b) => a.qtdPanel - b.qtdPanel,
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Ações',
        // dataIndex: 'data',
        key: 'action',
            render: (_, record) => (

              
              <Space size="middle">
               
                 <Link to={'/sale/' + record.idsale}>
                  {permissionSale?.isinsert && !record?.issend ? <EditOutlined style={{
                                    fontSize: '20px',
                                    color: '#4169E1'
                                  }} 
                                  
                            /> 
                            : 
                            <EyeOutlined 
                             title='Visualizar venda'
                             style={{
                              fontSize: '20px',
                              color: '#4169E1'
                            }} 
                            />
                  }
                </Link>
                {
                    permissionSale?.isremove && !record.issend ? 
                        <Link to={'#'}>
                            <DeleteTwoTone 
                              twoToneColor="#DC143C"
                              style={{
                                    fontSize: '20px',
                                  }} 
                              onClick={(e) => deleteService(record.idsale)} 
                              />
                        </Link>
                    : 
                    ""
                }
                {
                    permSendSale?.isinsert && !record.issend ? 
                        <Link to={'#'}>
                            <SendOutlined 
                              style={{
                                    fontSize: '20px',
                                    color: '#008000'
                                  }} 
                              onClick={(e) => sendSale(record.idsale)} 
                            />
                        </Link>
                    : 
                    ""
                }
                {
                  record.logError?.title && !record.issend ? 
                        <Link to={'#'}>
                            <WarningOutlinedIcon onClick={(e) => showLog(record.logError)} color='warning'/>
                        </Link>
                    : 
                    ""
                } 
                  <Link to={'/saleReopen/' + record.idsale + '/'+ true}>
                    {permissionReopen?.isinsert && record?.issend ? 
                              <LockOutlined title='Reabrir venda' alt='Reabrir venda' style={{
                                fontSize: '18px',
                                color: '#4169E1'
                              }} 
                              /> : ''
                    }
                  </Link>
                 
                </Space>
            ),
    },
  ];

  const handleDialogCloseFree = () => {
    setOpenDialogFree(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    navigate("/listSale");
    refreshPage();
  }

  return (

    <>

      <ResponsiveDialog 
            open={openDialog} 
            handleClose={handleDialogClose}
            title={titleDialog}
            message={messageDialog} 
            status={statusDialog}
      />

      <ResponsiveDialog 
          open={openDialogFree} 
          handleClose={handleDialogCloseFree}
          title={titleDialogFree}
          message={messageDialogFree} 
          status={statusDialogFree}
      />
      
      <Table
        columns={columns} 
        dataSource={data} />
    </>
     
  )
  

};

export default NewTableComponent;
