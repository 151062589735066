import React, { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import { Button, CardContent, Container, Typography, Paper, Card, FormControl, InputLabel, Select, MenuItem, FormControlLabel, FormGroup, FormHelperText, Autocomplete, Checkbox } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from "react-router-dom";
import ResponsiveDialog from "../../component/dialog/dialog";
import panelService from "../../service/servicePanel";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CustomizedBreadcrumbs from "../../component/breadcrumbs/breadcrumbs";
import customerService from "../../service/serviceCustomer";
import DrawerPrincipal from "../../component/drawer/drawer-principal";

function NewPanel(){

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    
    const [type, setType] = useState("");
    const [typeError, setTypeError] = useState("");

    const [availability, setAvailability] = useState(false);
    const [user, setUser] = useState("");

    const [idAddress, setIdAddress] = useState();    
    
    const [cep, setCep] = useState("");
    const [cepError, setCepError] = useState("");

    const [address, setAddress] = useState("");
    const [addressError, setAddressError] = useState("");

    const [number, setNumber] = useState();
    const [numberError, setNumberError] = useState();

    const [neighborhood, setNeighborhood] = useState("");
    const [neighborhoodError, setNeighborhoodError] = useState("");

    const [city, setCity] = useState("");
    const [cityError, setCityError] = useState("");

    const [complement, setComplement] = useState("");

    const [state, setState] = useState("");
    const [stateError, setStateError] = useState("");

    const [country, setCountry] = useState("Brasil");
    const [countryError, setCountryError] = useState("");

    const [qtdInsercao, setQtdInsercao] = useState("");
    const [qtdInsercaoError, setQtdInsercaoError] = useState("");

    const [isActive, setIsActive] = useState(false);

    const [openDialog, setOpenDialog] = useState();
    const [messageDialog, setMessageDialog] = useState();
    const [titleDialog, setTitleDialog] = useState();
    const [statusDialog, setStatusDialog] = useState();

   const [listaIDCustomer, setListaIDCustomer] = useState([]);
   const [listCustomer, setListCustomer] = useState([]);

   const [idCustomer, setIdCustomer] = useState();
   const [idCustomerError, setIdCustomerError] = useState('');


    const {id} = useParams();

    let navigate = useNavigate();


    const usuario = JSON.parse(localStorage.getItem('user_token'));
    const permission = usuario.roles.filter(item=>item.name == "Painel")[0];

    useEffect(() => {

        if(id)  {
            panelService.edit(id)
            .then(item => {

                setName(item?.name)
                setType(item?.type)
                setQtdInsercao(item?.qtdInsercao)
                setUser(item?.user)
                setIsActive(item?.isActive)

                setIdAddress(item?.address?.idAddress)
                setAddress(item?.address?.address)
                setNumber(item?.address?.number)
                setNeighborhood(item?.address?.neighborhood)
                setCity(item?.address?.city)
                setComplement(item?.address?.complement)
                setState(item?.address?.state)
                setCountry(item?.address?.country)
                setCep(item?.address?.cep)

                customerService.editCustomer(item?.idCustomer).then(customer=> {
                    setListaIDCustomer(customer);
                });

            }).catch(error => {
                console.log(error)
            });
        }


        var listCust = [];
        customerService.listCustomerLocatario().then(item => { 
            item.forEach(element => {
                listCust.push(element);
            });

            setListCustomer(listCust)
            
        }).catch(error => {
            console.log(error)
        });


    },[id]);

    function save(){

        if(valid()) {

            var addressData = {
                idAddress: idAddress,
                cep: cep,
                address: address,
                number: number,
                neighborhood: neighborhood,
                city: city,
                complement: complement,
                state: state,
                country: country
                
            }

            var data = {
                name: name,
                type: type,
                qtdInsercao: qtdInsercao,
                idCustomer: idCustomer,
                isActive: isActive,
                address: addressData
            }

            console.log(data)
    
            panelService.save(data).then(item => {

                setOpenDialog(true);
                setStatusDialog('success')
                setTitleDialog("Muito bem!")
                setMessageDialog("Painel cadastrado com sucesso!")
                
            }).catch(error => {
                handleDialogOpen()
                setTitleDialog("Ops")
                setMessageDialog(error.response.data)
            });
        }
    }




    //Função para consultar CEP
    function buscarCep(input) {
    
        if(input.length < 8) { 
            return;
        } else {
            
                fetch('https://brasilapi.com.br/api/cep/v2/'+input, {mode: 'cors'})
                .then((res) => res.json())
                .then((data) => {
                    if (data.hasOwnProperty("erro") || data?.type == "service_error") {
                        setCepError("O " + cep + " não foi encontrado em nenhum serviço de consulta")
                        setAddress("")
                        setCity("")
                        setNeighborhood("")
                        setNumber("")
                        setState("")
                    } else {
                        setAddress(data.street)
                        setCity(data.city)
                        setNeighborhood(data.neighborhood)
                        setState(data.state)
                    }
                })
                .catch(err => console.log(err));
        } 
    }
    


    function update(){

        if(valid()) {

            var addressData = {
                idAddress: idAddress,
                cep: cep,
                address: address,
                number: number,
                neighborhood: neighborhood,
                city: city,
                complement: complement,
                state: state,
                country: country
                
            }

            var data = {
                idpanel: id,
                name: name,
                qtdInsercao: qtdInsercao,
                type: type,
                idCustomer: idCustomer,
                isActive: isActive,
                address: addressData
            }

            panelService.update(data).then(item => {
                setOpenDialog(true);
                setStatusDialog('success')
                setTitleDialog("Muito bem!")
                setMessageDialog("Painel atualizado com sucesso")    
            }).catch(error => {
                setOpenDialog(true);
                setStatusDialog('error')
                setTitleDialog("Ops")
                setMessageDialog(error.response.data)
            });
        }

    }

    function valid(){

        let thatsOk = true;

        if(!name){
            setNameError("O nome precisa ser preenchido")
            thatsOk = false;
        }
        if(!type){
            setTypeError("O tipo precisa ser preenchido")
            thatsOk = false;
        }
        if(!qtdInsercao){
            setQtdInsercao("A quantidade precisa ser preenchida")
            thatsOk = false;
        }
        if(!cep){
            setCepError("O cep precisa ser preenchido")
            thatsOk = false;
        }
        if(!address){
            setAddressError("O Endereço precisa ser preenchido")
            thatsOk = false;
        }
        if(!number){
            setNumberError("O Número precisa ser preenchido");
            thatsOk = false;
        }
        if(!neighborhood){
            setNeighborhoodError("O Bairro precisa ser preenchido")
            thatsOk = false;
        }
        if(!city){
            setCityError("A cidade precisa ser preenchida")
            thatsOk = false;
        }
        if(!state){
            setStateError("O Estado precisa ser preenchido")
            thatsOk = false;
        }
        if(!country){
            setCountryError("O Pais precisa ser preenchido")
            thatsOk = false;
        }

        return thatsOk;

    }


    const handleDialogOpen = () => {
        setOpenDialog(true);
    };
    
    const handleDialogClose = () => {
        setOpenDialog(false);
        navigate("/listPanel")
    };

    const cancel = () => {
        navigate("/listPanel")
    }

    const handleChangeClient = (input, newInput) => {
        console.log(input.idcustomer)
        setIdCustomer(input.idcustomer)
        setListaIDCustomer(input)
    }

    const handleCheckd = (value) => {
        setIsActive(value)
    }

    
    return(

    <>

        <Container maxWidth="lx" sx={{ bgcolor: '#EBEBEB', height: '100vh' }}>
            
            <DrawerPrincipal divOpen={
                <div>
                    <div>
                    <div className="row mb-3">
                        <CustomizedBreadcrumbs title1={"Home"} link1={"/"} 
                                title2={"Lista de Painéis"} link2={"#"} 
                                title3={"Cadastro de Painel"} link3={"#"}/>
                    </div>
                    <ResponsiveDialog 
                        open={openDialog} 
                        handleClose={handleDialogClose}
                        title={titleDialog}
                        message={messageDialog} 
                        status={statusDialog}
                        />

                        <div className="row">

                            <h1 className="h3 mb-3 text-black font-weight-bold"> Cadastro de Painel </h1>

                        </div>

                        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
                            <div className="container">
                                <div className="row">     
                                    <div className="col-12">
                                            <Card>
                                                <div className="form-cadastro" style={{padding: 20}}>
                                                    <Typography sx={{ width: 14 }} className="mb-3" gutterBottom variant="h7" component="div">
                                                        Painel
                                                    </Typography>

                                                    <div className="row">
                                                        <div className="col-md-8 mb-3">
                                                            <TextField
                                                                    fullWidth                                                            
                                                                    label={name ? "Nome do painel" : "Nome do painel"}
                                                                    id="outlined-size-small"
                                                                    focused={name && name}
                                                                    size="small"
                                                                    value={name && name}
                                                                    onChange={(e)=> {
                                                                        setName(e.target.value);
                                                                        setNameError("")
                                                                    }}
                                                                    error={nameError && nameError}
                                                                    helperText={nameError ? nameError : ""}
                                                                />
                                                        </div>

                                                        <div className="col-md-3 mb-3">

                                                            <FormControl fullWidth size="small" error={typeError && typeError}>
                                                                <InputLabel id="demo-select-small">Tipo</InputLabel>
                                                                <Select
                                                                    labelId="demo-select-small"
                                                                    id="demo-select-small"
                                                                    value={type && type}
                                                                    focused={type && type}
                                                                    label="Tipo"
                                                                    onChange={(e) => {
                                                                        setType(e.target.value);
                                                                        setTypeError("");
                                                                    }}
                                                                    
                                                                    helperText={typeError ? typeError : ""}
                                                                >
                                                                    <MenuItem value={"Horizontal"}>Horizontal</MenuItem>
                                                                    <MenuItem value={"Vertical"}>Vertical</MenuItem>
                                                                    <MenuItem value={"Indoor"}>Indoor</MenuItem>
                                                                </Select>
                                                                <FormHelperText >{typeError ? typeError : ""}</FormHelperText>
                                                            </FormControl>
                                                        </div>

 
                                                    </div>

                                                    <div className="row">


                                                        <div className="col-md-3">
                                                            <TextField
                                                                fullWidth                                                            
                                                                label={qtdInsercao ? "Qtd de inserção" : "Qtd de inserção"}
                                                                id="outlined-size-small"
                                                                type="number"
                                                                focused={qtdInsercao && qtdInsercao}
                                                                size="small"
                                                                value={qtdInsercao && qtdInsercao}
                                                                onChange={(e)=> {
                                                                    setQtdInsercao(e.target.value);
                                                                    setQtdInsercaoError("")
                                                                }}
                                                                error={qtdInsercaoError && qtdInsercaoError}
                                                                helperText={qtdInsercaoError ? qtdInsercaoError : ""}
                                                            />
                                                        </div>

                                                        <div className="col-md-3 mb-3">
                                                            <Autocomplete
                                                                size='small'
                                                                id="combo-box-demo1"
                                                                value={listaIDCustomer && listaIDCustomer}
                                                                sx={{ width: '100%' }}
                                                                // getOptionLabel={(listCustomer) => `${listCustomer.idcustomer ? listCustomer.name + listCustomer.idcustomer : "Escolha o cliente"}`}
                                                                getOptionLabel={(listCustomer) => {
                                                                    if (listCustomer.idcustomer ) {
                                                                      return `${listCustomer.name} (${listCustomer.idcustomer})`;
                                                                    } else {
                                                                      return "Escolha o cliente";
                                                                    }
                                                                  }}
                                                                options={listCustomer}
                                                                // isOptionEqualToValue={(option, value) => option.name === value.name}
                                                                isOptionEqualToValue={(option, value) => option.idcustomer === value.idcustomer}
                                                                onChange={(event, newValue) => {
                                                                    handleChangeClient(newValue)
                                                                    // setIdClientError('')
                                                                }}
                                                                noOptionsText={"Nenhum Cliente com esse nome foi encontrando"}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                    {...params}
                                                                    label="Escolher cliente"
                                                                    error={!idCustomer ? idCustomerError : ''}
                                                                    helperText={!idCustomer ? idCustomerError : ''}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="col-md-3">
                                                        </div>
                                                        <div className="col-md-2">
                                                            <FormGroup>
                                                                <FormControlLabel control={<Checkbox checked={isActive && isActive} onClick={(e) => handleCheckd(e.target.checked)} />} label="Ativo" />
                                                            </FormGroup>
                                                        </div>

                                                    </div>

                                                </div>
                                            </Card>     

                                            <div style={{padding: 5}}>
                                            </div>
                                            <Card>
                                                <CardContent>
                                                    <Typography sx={{ width: 14 }} className="mb-3" gutterBottom variant="h7" component="div">
                                                        Endereço
                                                    </Typography>
                                                        <div className="row">
                                                            <div className="col-md-3 mb-3">
                                                                <TextField
                                                                    fullWidth
                                                                    focused={cep && cep}
                                                                    label="Cep "
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    maxLength="8"
                                                                    value={cep && cep}
                                                                    onChange={(e)=> {
                                                                        setCep(e.target.value);
                                                                        buscarCep(e.target.value);
                                                                        setCepError("");
                                                                    }}
                                                                    inputProps={{ maxLength: 8 }}
                                                                    error={cepError && cepError}
                                                                    helperText={cepError ? cepError : ""}
                                                                />
                                                            </div>
                                                            <div className="col-md-3 mb-3">
                                                                <TextField
                                                                    fullWidth
                                                                    focused={address && address}
                                                                    label="Endereço "
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    value={address && address}
                                                                    onChange={(e)=> {
                                                                        setAddress(e.target.value);
                                                                        setAddressError("");
                                                                    }}
                                                                    error={addressError && addressError}
                                                                    helperText={addressError ? addressError : ""}
                                                                />
                                                            </div>
                                                            <div className="col-md-2 mb-3">
                                                                <TextField
                                                                    fullWidth
                                                                    focused={number && number}
                                                                    label="Número"
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    value={number && number}
                                                                    onChange={(e)=> {
                                                                        setNumber(e.target.value);
                                                                        setNumberError("")
                                                                    }}
                                                                    error={numberError && numberError}
                                                                    helperText={numberError ? numberError : ""}
                                                                />
                                                            </div>
                                                            <div className="col-md-4 mb-3">
                                                                <TextField
                                                                    fullWidth
                                                                    focused={neighborhood && neighborhood}
                                                                    label="Bairro"
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    value={neighborhood && neighborhood}
                                                                    onChange={(e)=> {
                                                                        setNeighborhood(e.target.value);
                                                                        setNeighborhoodError("");
                                                                    }}
                                                                    error={neighborhoodError && neighborhoodError}
                                                                    helperText={neighborhoodError ? neighborhoodError : ""}
                                                                />
                                                            </div>

                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-3 mb-3">
                                                                <TextField
                                                                    fullWidth
                                                                    focused={complement && complement}
                                                                    label="Complemento "
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    value={complement && complement}
                                                                    onChange={(e)=> {
                                                                        setComplement(e.target.value);
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className="col-md-4 mb-3">
                                                                <TextField
                                                                    fullWidth
                                                                    focused={city && city}
                                                                    label="Cidade"
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    value={city && city}
                                                                    onChange={(e)=> {
                                                                        setCity(e.target.value);
                                                                        setCityError("");
                                                                    }}
                                                                    error={cityError && cityError}
                                                                    helperText={cityError ? cityError : ""}
                                                                />
                                                            </div>
                                                            <div className="col-md-2 mb-3">

                                                                <FormControl fullWidth size="small" error={stateError && stateError}>
                                                                    <InputLabel id="demo-select-small">Estado</InputLabel>
                                                                    <Select
                                                                        labelId="demo-select-small"
                                                                        id="demo-select-small"
                                                                        focusede={state && state}
                                                                        value={state && state}
                                                                        label="Estado"
                                                                        onChange={(e) => {
                                                                            setState(e.target.value);
                                                                            setStateError("");
                                                                        }}
                                                                    >
                                                                        <MenuItem value={"AC"}>AC</MenuItem>
                                                                        <MenuItem value={"AL"}>AL</MenuItem>
                                                                        <MenuItem value={"AP"}>AP</MenuItem>
                                                                        <MenuItem value={"AM"}>AM</MenuItem>
                                                                        <MenuItem value={"BA"}>BA</MenuItem>
                                                                        <MenuItem value={"CE"}>CE</MenuItem>
                                                                        <MenuItem value={"GO"}>GO</MenuItem>
                                                                        <MenuItem value={"MA"}>MA</MenuItem>
                                                                        <MenuItem value={"MT"}>MT</MenuItem>
                                                                        <MenuItem value={"MS"}>MS</MenuItem>
                                                                        <MenuItem value={"MG"}>MG</MenuItem>
                                                                        <MenuItem value={"PA"}>PA</MenuItem>
                                                                        <MenuItem value={"PB"}>PB</MenuItem>
                                                                        <MenuItem value={"PR"}>PR</MenuItem>
                                                                        <MenuItem value={"PE"}>PE</MenuItem>
                                                                        <MenuItem value={"PI"}>PI</MenuItem>
                                                                        <MenuItem value={"RJ"}>RJ</MenuItem>
                                                                        <MenuItem value={"RN"}>RN</MenuItem>
                                                                        <MenuItem value={"RS"}>RS</MenuItem>
                                                                        <MenuItem value={"RO"}>RO</MenuItem>
                                                                        <MenuItem value={"RR"}>RR</MenuItem>
                                                                        <MenuItem value={"SC"}>SC</MenuItem>
                                                                        <MenuItem value={"SP"}>SP</MenuItem>
                                                                        <MenuItem value={"SE"}>SE</MenuItem>
                                                                        <MenuItem value={"TO"}>TO</MenuItem>
                                                                        <MenuItem value={"DF"}>DF</MenuItem>
                                                                    </Select>
                                                                    <FormHelperText >{stateError ? stateError : ""}</FormHelperText>
                                                                </FormControl>

                                                            </div>

                                                            <div className="col-md-2 mb-3">

                                                                <FormControl fullWidth size="small" error={countryError && countryError}>
                                                                    <InputLabel id="demo-select-small">Pais</InputLabel>
                                                                    <Select
                                                                        labelId="demo-select-small"
                                                                        id="demo-select-small"
                                                                        focused={country && country}
                                                                        value={country && country}
                                                                        label="Pais"
                                                                        onChange={(e) => {
                                                                            setCountry(e.target.value);
                                                                            setCountryError("");
                                                                        }}                                                                        
                                                                    >
                                                                        <MenuItem value={"Brasil"}>Brasil</MenuItem>

                                                                    </Select>
                                                                    <FormHelperText >{countryError ? countryError : ""}</FormHelperText>
                                                                </FormControl>

                                                            </div>

                                                        </div>
                                                </CardContent>

                                            </Card>                                       

                                    </div>  {/** Fecha segundo lado */}

                                    <div className='row' style={{padding: 50}}>
                                            <div className="col-md-8">

                                            </div>
                                            <div className="col-md-2">
                                                <Button variant="contained" color="warning" onClick={cancel} sx={{ mr: 1 }}>
                                                    <HighlightOffIcon />{"Cancelar"}
                                                </Button>
                                            </div>
                                            <div className="col-md-2">
                                            {
                                                permission.isinsert ? 
                                                <Button variant="contained" onClick={id ? update : save} sx={{ mr: 1 }}>
                                                    <AddIcon /> {"Salvar" }
                                                </Button>
                                                : 
                                                ""
                                            }
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </Paper>
                    </div>
                </div>
            }/>
        </Container>

    </>

    )

}
export default NewPanel;