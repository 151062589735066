import { apiClient } from "./api";


const customerService = {


    async listCustomer(){

        var retorno = await apiClient.get('/customer');
        
        return retorno.data;
    },


    async listCustomerActive(){

        var retorno = await apiClient.get('/customer/active');
        
        return retorno.data;
    },


    async listCustomerLocatario(){

        var retorno = await apiClient.get('/customer/locatario');
        
        return retorno.data;
    },

    async listCustomerFilter(id){
        console.log(id)

        var retorno = await apiClient.get('/customer/filter/'+id);

        return retorno.data;
    },


    async editCustomer(id){

        var retorno = await apiClient.get("/customer/"+id);
        console.log(retorno.data)
        return retorno.data;
    },

    async save(customer){
        console.log(customer)

        var retorno = await apiClient.post("/customer", customer);

        return retorno;

    },

    async update(customer){
        console.log(customer)

        var retorno = await apiClient.put("/customer", customer);

        return retorno;

    },

    async delete(idcustomer){
        console.log(idcustomer)

        var retorno = await apiClient.delete("/customer/"+idcustomer);

        return retorno;

    }

}

export default customerService;