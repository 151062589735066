import * as React from 'react';
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export default function CustomizedBreadcrumbs({title1, link1, title2, link2, title3, link3}) {
  return (
    <div className='mt-4' role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        <StyledBreadcrumb
          href={"/"}
          label={title1}
          icon={<HomeIcon fontSize="small" />}
        />
         {
            title2 ? 
          <StyledBreadcrumb component="a" href={link2} label={title2} />
          :
          ""
         }
        {
            title3 ? 
            <StyledBreadcrumb
            href={link3} 
            label={title3}
            />
            : 
            ""
        }
      </Breadcrumbs>
    </div>
  );
}