import { CheckCircleOutlined, DeleteTwoTone, EditOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import React, { useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Button, Input, Space, Table, Tag } from 'antd';
import { Link } from 'react-router-dom';
import ResponsiveDialog from '../dialog/dialog';
import { useNavigate } from 'react-router-dom';
import customerService from '../../service/serviceCustomer';

const TableCustomer = ({data}) => {

  const usuario = JSON.parse(localStorage.getItem('user_token'));
  const permission = usuario.roles.filter(item=>item.name == "Cliente")[0];

  let navigate = useNavigate();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const [openDialog, setOpenDialog] = useState();
  const [messageDialog, setMessageDialog] = useState();
  const [titleDialog, setTitleDialog] = useState();
  const [statusDialog, setStatusDialog] = useState();


  function refreshPage() {
    window.location.reload(false);
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Pesquisar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Procurar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Limpar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Fechar
          </Button>
        </Space>
      </div>
    ),

    filterIcon: (filtered) => (

      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />

    ),

    onFilter: (value, record) =>{

      if(typeof value === 'string'){
        return record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase())
      }else{
        return record[dataIndex] == value;
      }

    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });


  const showStatusTypePerson = (status) => {
        
    if(status === 'Pessoa Física'){
        return 'geekblue'
        // (
        //     <>
        //       <Stack spacing={1} alignItems="center">
        //         <Chip label={status} color="primary" variant="outlined" />
        //       </Stack>
        //     </>
        // )
    }else {
        return 'success'
        // (
        //     <>
        //         <Stack spacing={1} alignItems="center">
        //             <Chip label={status} color="success" variant="outlined" />
        //         </Stack>
        //     </>
        // )
        
    }

}


const showStatusTypeClient = (status) => {
    
    if(status === 'Agencia'){
        return 'primary';
        // return (
        //     <>
        //       <Stack spacing={1} alignItems="center">
        //         <Chip label={status} color="primary" variant="outlined" />
        //       </Stack>
        //     </>
        // )
    }else if (status === 'anunciante'){
        return 'warning';
        // return (
        //     <>
        //         <Stack spacing={1} alignItems="center">
        //             <Chip label={status} color="warning" variant="outlined" />
        //         </Stack>
        //     </>
        // )
    }else if (status === 'broker') {
        return 'success';
        // return (
        //     <>
        //         <Stack spacing={1} alignItems="center">
        //         <Chip label={status} color="success" variant="outlined" />
        //         </Stack>
        //     </>
        // )
    }else if (status === 'locatario') {
        return 'error';
        // return (
        //     <>
        //         <Stack spacing={1} alignItems="center">
        //         <Chip label={status} color="error" variant="outlined" />
        //         </Stack>
        //     </>
        // )
    }

  }

  function formatCnpjCpf(value){
    const cnpjCpf = value.replace(/\D/g, '');
    
    if (cnpjCpf.length === 11) {
        return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
    } 
    
    return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
  }


  const deleteCustomer = (id) => {

    customerService.delete(id).then(item=> {
      setOpenDialog(true);
         setStatusDialog('success')
         setTitleDialog("Muito bem!")
         setMessageDialog("Cliente " + id + " deletado com sucesso")

     }).catch(error=>{
         setOpenDialog(true);
         setStatusDialog('error')
         setTitleDialog("Ops!")
         setMessageDialog("Erro ao excluir o cliente " + id + ", verifique se o mesmo encontra-se inserida em alguma Venda. ")
     })

  }


  const columns = [
    {
        title: 'Código',
        dataIndex: 'idcustomer',
        width: '13%',
        key: 'idcustomer',
        // ...getColumnSearchProps('idcustomer'),
        sorter: (a, b) => a.idcustomer - b.idcustomer,
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
        width: '55%',
        ...getColumnSearchProps('name'),
        sorter: (a, b) => a.name?.length - b.name?.length,
        sortDirections: ['descend', 'ascend'],
    },
    
    {
      title: 'CNPJ',
      dataIndex: 'cpfCnpj',
      key: 'cpfCnpj',
      width: '55%',
      ...getColumnSearchProps('cpfCnpj'),
      sorter: (a, b) => a.cpfCnpj?.length - b.cpfCnpj?.length,
      sortDirections: ['descend', 'ascend'],
      render: (cnpj) => (
          <>
              {formatCnpjCpf(cnpj)}
          </>
        ),
    },
    {
        title: 'Tipo Pessoa',
        dataIndex: 'typePerson',
        key: 'typePerson',
        // ...getColumnSearchProps('typePerson'),
        sorter: (a, b) => a.typePerson?.length - b.typePerson?.length,
        sortDirections: ['descend', 'ascend'],
        render: (typePerson) => (
        <span>
            {
              <Tag color={showStatusTypePerson(typePerson)} key={1}>
                  {typePerson}
              </Tag>
            }
        </span>
        ),
    },
    {
      title: 'Tipo Cliente',
      dataIndex: 'typeClient',
      key: 'typeClient',
      // ...getColumnSearchProps('typeClient'),
      sorter: (a, b) => a.typeClient?.length - b.typeClient?.length,
      sortDirections: ['descend', 'ascend'],
      render: (typeClient) => (
      <span>
          {
            <Tag color={showStatusTypeClient(typeClient)} key={1}>
                {typeClient}
            </Tag>
          }
      </span>
      ),
    },
    {
        title: 'Ações',
        // dataIndex: 'data',
        key: 'action',
            render: (_, record) => (

              
                <Space size="middle">
               
                  <Link to={'/customer/' + record.idcustomer}>
                      { permission?.isinsert ? <EditOutlined style={{
                                    fontSize: '20px',
                                    color: '#4169E1'
                                  }} />
                                   :
                                   <EyeOutlined style={{
                                    fontSize: '20px',
                                    color: '#4169E1'
                                  }}  />}
                  </Link>
                  {
                      permission?.isremove ?
                          <Link to={'#'}>
                               <DeleteTwoTone 
                                  twoToneColor="#DC143C"
                                  style={{
                                    fontSize: '20px',
                                  }}  
                                  onClick={(e) => deleteCustomer(record.idcustomer)} 
                                  />
                          </Link>
                      : 
                      ""
                  }
                  {
                      record.codExterno ?
                          <Link to={'#'}>
                              <CheckCircleOutlined titleAccess={'Cliente integrado'} color='success'/>
                          </Link>
                      : 
                      ""
                  } 
                </Space>
            ),
    },
  ];

  const handleDialogClose = () => {
    setOpenDialog(false);
    navigate("/listCustomer");
    refreshPage();
  }

  return (

    <>

      <ResponsiveDialog 
            open={openDialog} 
            handleClose={handleDialogClose}
            title={titleDialog}
            message={messageDialog} 
            status={statusDialog}
      />

      <Table
        columns={columns} 
        dataSource={data} />
    </>
     
  )
  

};

export default TableCustomer;
