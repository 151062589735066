import React from 'react';
import { ConfigProvider, DatePicker, Space } from 'antd';
import ptBR from "antd/es/locale/pt_BR";

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
const { RangePicker } = DatePicker;
dayjs.extend(customParseFormat);

function DataRangerPicker({handleChange, input, size, disabledDate}){
   
    return( 
 
        <Space direction="vertical" >
             <ConfigProvider locale={ptBR}>

                <RangePicker  
                    value={input && input}
                    size={size ? size : 'large'}
                    name='period'
                    format="DD/MM/YYYY"
                    disabled={disabledDate} 
                    onChange={handleChange}
                    locale={ptBR}

                />
             </ConfigProvider>

        </Space>

    )

};

export default DataRangerPicker;
