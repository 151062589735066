import React from 'react';
import './App.css';
import { AuthProvider } from './contexts/auth';
import RouteApp from './routes/routes';


function App() {

  return (
    <div className="App">

      <AuthProvider>
        <RouteApp />
      </AuthProvider>
      
    </div>
  );
}

export default App;
