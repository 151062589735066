import React, { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import { Avatar, Button, CardContent, Container, Stack, Typography, Paper, Card, ListItem, FormGroup, FormControlLabel, Switch, FormControl } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import userService from "../../service/serviceUser";
import { useNavigate, useParams } from "react-router-dom";
import ResponsiveDialog from "../../component/dialog/dialog";
import CustomizedBreadcrumbs from "../../component/breadcrumbs/breadcrumbs";
import DrawerPrincipal from "../../component/drawer/drawer-principal";


function NewUser(){
    
    const usuario = JSON.parse(localStorage.getItem('user_token'));
    const permission = usuario.roles.filter(item=>item.name == "Usuario")[0];

    const [name, setName] = useState();
    const [nameError, setNameError] = useState();

    const [login, setLogin] = useState();
    const [loginError, setLoginError] = useState();

    const [password, setPassword] = useState();
    const [passwordError, setPasswordError] = useState();
    
    const [openDialog, setOpenDialog] = useState();
    const [messageDialog, setMessageDialog] = useState();
    const [titleDialog, setTitleDialog] = useState();
    const [statusDialog, setStatusDialog] = useState();

    const [idRoleUser, setIdRoleUser] = useState();
    const [checkUserInsert, setCheckUserInsert] = useState(false);
    const [checkUserList, setCheckUserList] = useState(false);
    const [checkUserRemove, setCheckUserRemove] = useState(false);

    const [idRoleClient, setIdRoleClient] = useState();
    const [checkCliInsert, setCheckCliInsert] = useState(false);
    const [checkCliList, setCheckCliList] = useState(false);
    const [checkCliRemove, setCheckCliRemove] = useState(false);

    const [idRoleService, setIdRoleService] = useState();
    const [checkServiceInsert, setCheckServiceInsert] = useState(false);
    const [checkServiceList, setCheckServiceList] = useState(false);
    const [checkServiceRemove, setCheckServiceRemove] = useState(false);

    const [idRolePainel, setIdRolePainel] = useState();
    const [checkPainelInsert, setCheckPainelInsert] = useState(false);
    const [checkPainelList, setCheckPainelList] = useState(false);
    const [checkPainelRemove, setCheckPainelRemove] = useState(false);

    const [idRoleCircuit, setIdRoleCircuit] = useState();
    const [checkCircuitInsert, setCheckCircuitInsert] = useState(false);
    const [checkCircuitList, setCheckCircuitList] = useState(false);
    const [checkCircuitRemove, setCheckCircuitRemove] = useState(false);

    const [idRoleVenda, setIdRoleVenda] = useState();
    const [checkVendaInsert, setCheckVendaInsert] = useState(false);
    const [checkVendaList, setCheckVendaList] = useState(false);
    const [checkVendaRemove, setCheckVendaRemove] = useState(false);

    const [idRoleEnviaVenda, setIdRoleEnviaVenda] = useState();
    const [checkEnviaVendaInsert, setCheckEnviaVendaInsert] = useState(false);
    
    const [idRoleReabrirVenda, setIdRoleReabrirVenda] = useState();
    const [checkReabrirVenda, setCheckReabrirVenda] = useState(false);


    const [idRoleDashboard, setIdRoleDashboard] = useState();
    const [checkDashboard, setCheckDashboard] = useState(false);

    const [idRoleDashboardScp, setIdRoleDashboardScp] = useState();
    const [checkDashboardScp, setCheckDashboardScp] = useState(false);


    const {id} = useParams();

    let navigate = useNavigate();

    useEffect(() => {
        
        if(id)  {
            userService.editUser(id).then(item => {

                setName(item.name)
                setLogin(item.login)
                setPassword(item.password)
    

                item.roles.forEach(element => {
                        
                        if(element.name == 'Usuario'){
                            setIdRoleUser(element.idroles)
                            setCheckUserInsert(element.isinsert)
                            setCheckUserList(element.islist)
                            setCheckUserRemove(element.isremove)
                        }                        

                        if(element.name == 'Cliente'){
                            setIdRoleClient(element.idroles)
                            setCheckCliInsert(element.isinsert)
                            setCheckCliList(element.islist)
                            setCheckCliRemove(element.isremove)
                        }

                        if(element.name == 'Serviço'){
                            setIdRoleService(element.idroles)
                            setCheckServiceInsert(element.isinsert)
                            setCheckServiceList(element.islist)
                            setCheckServiceRemove(element.isremove)
                        }

                        if(element.name == 'Painel'){
                            setIdRolePainel(element.idroles)
                            setCheckPainelInsert(element.isinsert)
                            setCheckPainelList(element.islist)
                            setCheckPainelRemove(element.isremove)
                        }

                        if(element.name == 'Circuito'){
                            setIdRoleCircuit(element.idroles)
                            setCheckCircuitInsert(element.isinsert)
                            setCheckCircuitList(element.islist)
                            setCheckCircuitRemove(element.isremove)
                        }

                        if(element.name == 'Vendas'){
                            setIdRoleVenda(element.idroles)
                            setCheckVendaInsert(element.isinsert)
                            setCheckVendaList(element.islist)
                            setCheckVendaRemove(element.isremove)
                        }
                        if(element.name == 'Enviar Vendas'){
                            
                            setIdRoleEnviaVenda(element.idroles)
                            setCheckEnviaVendaInsert(element.isinsert)
                        }
                        
                        if(element.name == 'Reabrir Vendas'){
                            setIdRoleReabrirVenda(element.idroles)
                            setCheckReabrirVenda(element.isinsert)
                        }

                        if(element.name == 'Dashboard'){
                            setIdRoleDashboard(element.idroles)
                            setCheckDashboard(element.isinsert)
                        }

                        if(element.name == 'Dashboard SCP'){
                            setIdRoleDashboardScp(element.idroles)
                            setCheckDashboardScp(element.isinsert)
                        }


                });

            }).catch(error => {
                console.log(error)
            });
        }

    },[id]);

    function save(){

        if(valid()) {

            var listRoles = [];

            var roleUser = {
                idroles: idRoleUser,
                name: "Usuario",
                isinsert: checkUserInsert,
                islist: checkUserList,
                isremove: checkUserRemove
            }

            listRoles.push(roleUser)

            var roleCli = {
                idroles: idRoleClient,
                name: "Cliente",
                isinsert: checkCliInsert,
                islist: checkCliList,
                isremove: checkCliRemove
            }

            listRoles.push(roleCli)

            var rolePanel = {
                idroles: idRolePainel,
                name: "Painel",
                isinsert: checkPainelInsert,
                islist: checkPainelList,
                isremove: checkPainelRemove
            }

            listRoles.push(rolePanel)

            var roleService = {
                idroles: idRoleService,
                name: "Serviço",
                isinsert: checkServiceInsert,
                islist: checkServiceList,
                isremove: checkServiceRemove
            }

            listRoles.push(roleService)

 
            var roleCircuit = {
                idroles: idRoleCircuit,
                name: "Circuito",
                isinsert: checkCircuitInsert,
                islist: checkCircuitList,
                isremove: checkCircuitRemove
            }

            listRoles.push(roleCircuit)
            
            var roleVenda = {
                idroles: idRoleVenda,
                name: "Vendas",
                isinsert: checkVendaInsert,
                islist: checkVendaList,
                isremove: checkVendaRemove
            }

            listRoles.push(roleVenda)

            var roleEnviarVenda = {
                idroles: idRoleEnviaVenda,
                name: "Enviar Vendas",
                isinsert: checkEnviaVendaInsert,
            }

            listRoles.push(roleEnviarVenda)

            var roleReabrirVenda = {
                idroles: idRoleEnviaVenda,
                name: "Reabrir Vendas",
                isinsert: checkReabrirVenda,
            }

            listRoles.push(roleReabrirVenda)

            var roleDashboard = {
                idroles: idRoleDashboard,
                name: "Dashboard",
                isinsert: checkDashboard,
            }

            listRoles.push(roleDashboard)

            var roleDashboardScp = {
                idroles: idRoleDashboardScp,
                name: "Dashboard SCP",
                isinsert: checkDashboardScp,
            }

            listRoles.push(roleDashboardScp)


            var data = {
                name: name,
                login: login,
                password: password,
                roles: listRoles
            }

            console.log(data)
    
            userService.save(data).then(item => {
                setOpenDialog(true);
                setStatusDialog('success')
                setTitleDialog("Muito bem!")
                setMessageDialog("Usuário cadastrado com sucesso")    
            }).catch(error => {
                setOpenDialog(true);
                setStatusDialog('error')
                setTitleDialog("Ops")
                setMessageDialog(error.response.data)
            });
        }



    }


    function update(){

        

        if(valid()) {
            var listRoles = [];

            var roleUser = {
                idroles: idRoleUser,
                isinsert: checkUserInsert,
                islist: checkUserList,
                isremove: checkUserRemove
            }

            listRoles.push(roleUser)

            var roleCli = {
                idroles: idRoleClient,
                isinsert: checkCliInsert,
                islist: checkCliList,
                isremove: checkCliRemove
            }

            listRoles.push(roleCli)

            var rolePanel = {
                idroles: idRolePainel,
                isinsert: checkPainelInsert,
                islist: checkPainelList,
                isremove: checkPainelRemove
            }

            listRoles.push(rolePanel)

            var roleService = {
                idroles: idRoleService,
                isinsert: checkServiceInsert,
                islist: checkServiceList,
                isremove: checkServiceRemove
            }

            listRoles.push(roleService)

 
            var roleCircuit = {
                idroles: idRoleCircuit,
                isinsert: checkCircuitInsert,
                islist: checkCircuitList,
                isremove: checkCircuitRemove
            }

            listRoles.push(roleCircuit)

            var roleVenda = {
                idroles: idRoleVenda,
                name: "Vendas",
                isinsert: checkVendaInsert,
                islist: checkVendaList,
                isremove: checkVendaRemove
            }
            listRoles.push(roleVenda)

            var roleEnviarVenda = {
                idroles: idRoleEnviaVenda,
                name: "Enviar Vendas",
                isinsert: checkEnviaVendaInsert,
            }

            listRoles.push(roleEnviarVenda)

            var roleReabrirVenda = {
                idroles: idRoleReabrirVenda,
                name: "Reabrir Vendas",
                isinsert: checkReabrirVenda,
            }

            listRoles.push(roleReabrirVenda)
            
            var roleDashboard = {
                idroles: idRoleDashboard,
                name: "Dashboard",
                isinsert: checkDashboard,
            }

            listRoles.push(roleDashboard)

            var roleDashboardScp = {
                idroles: idRoleDashboardScp,
                name: "Dashboard SCP",
                isinsert: checkDashboardScp,
            }

            listRoles.push(roleDashboardScp)


            var data = {
                iduser: id,
                name: name,
                login: login,
                password: password,
                roles: listRoles
            }

            console.log(data)

            userService.update(data).then(item => {
                setOpenDialog(true);
                setStatusDialog('success')
                setTitleDialog("Muito bem!")
                setMessageDialog("Usuário atualizado com sucesso")    
            }).catch(error => {
                setOpenDialog(true);
                setStatusDialog('error')
                setTitleDialog("Ops")
                setMessageDialog(error.response.data)
            });
        }

    }

    function valid(){

        let message = "Este campo é obrigatório";
        let thatsOk = true;


        if(!name){
            setNameError(message)
            thatsOk = false;
        }
        if(!login){
            setLoginError(message)
            thatsOk = false;
        }
        if(!id && !password){
            setPasswordError(message)
            thatsOk = false;
        }

        return thatsOk;

    }



    const handleDialogClose = () => {
        setOpenDialog(false);
        navigate("/user")
      };

    return(

    <>

        <Container maxWidth="lx"  sx={{ bgcolor: '#EBEBEB'}}>
            
            <DrawerPrincipal divOpen={
                <div>
                    <div>
                    <div className="row mb-3">
                        <CustomizedBreadcrumbs title1={"Home"} link1={"/"} 
                            title2={"Lista de Usuários"} link2={"#"} 
                            title3={"Cadastro de Usuário"} link3={"#"} />
                    </div>
                    <ResponsiveDialog 
                        open={openDialog} 
                        handleClose={handleDialogClose}
                        title={titleDialog}
                        message={messageDialog} 
                        status={statusDialog}
                    />

                        <div className="row">

                            <h1 className="h3 mb-3 text-black font-weight-bold" >Cadastro de usuário</h1>

                        </div>

                        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-3">
                                        <Card>
                                            <div className="form-signin mx-auto mb-4 mb-lg-6">
                                                <div className="row">
                                                    <CardContent >
                                                        <div className='row' >
                                                            <Stack direction="row" spacing={2} style={{paddingLeft: 50}}>
                                                                <Avatar alt="Profile" 
                                                                sx={{ width: 150, height: 150}}
                                                                src={"https://developerplus.com.br/wp-content/uploads/2021/10/user.png"}/>
                                                            </Stack>
                                                        </div>
                                                        <div className='row'>
                                                            <Typography variant="h8" sx={{padding: 2}}>
                                                                {name}        
                                                            </Typography>
                                                        </div>
                                                    </CardContent>
                                                </div>
                                            </div>

                                        </Card>

                                    </div>
                                    <div className="col-8">
                                        <Card>
                                            <div className="form-cadastro" style={{padding: 20}}>

                                                    <div className="row">
                                                        <div className="col-md-8 mb-3">
                                                            <TextField
                                                                    fullWidth                                                            
                                                                    label={name ? "Nome do usuário" : "Nome do usuário"}
                                                                    id="outlined-size"
                                                                    focused={name && name}
                                                                    size="small"
                                                                    value={name && name}
                                                                    onChange={(e)=> {
                                                                        setName(e.target.value);
                                                                        setNameError("");
                                                                    }}
                                                                    error={nameError && nameError}
                                                                    helperText={nameError ? nameError : ""}

                                                                />
                                                        </div>

                                                        <div className="row mb-3">
                                                            <div className="col-md-4">
                                                                <TextField
                                                                    fullWidth
                                                                    focused={login && login}
                                                                    label="Login"
                                                                    id="outlined--small"
                                                                    size="small"
                                                                    value={login && login}
                                                                    onChange={(e)=> {
                                                                        setLogin(e.target.value);
                                                                        setLoginError("")
                                                                    }}
                                                                    error={loginError && loginError}
                                                                    helperText={loginError ? loginError : ""}

                                                                />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <TextField
                                                                    fullWidth
                                                                    focused={password && password}
                                                                    label="Nova senha"
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    type="password"
                                                                    value={password && password}
                                                                    onChange={(e)=> {
                                                                        setPassword(e.target.value)
                                                                        setPasswordError("")
                                                                    }}
                                                                    error={passwordError && passwordError}
                                                                    helperText={passwordError ? passwordError : ""}
                                                                />

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </Card>

                                            <div style={{padding: 5}}>
                                                    {/* <Divider /> */}
                                            </div>

                                            <Card >

                                                <div className="container" style={{paddingTop: 20}}>
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <div className="form-signin mx-auto mb-4 ">
                                                                <h5>Permissões</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="form-cadastro" style={{padding: 20}}>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <h6>Usuários &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                                                        </div>
                                                    </div>
                                                    <ListItem
                                                        key={"value1"}
                                                        role="listitem"
                                                        button
                                                        >

                                                            <FormControl component="fieldset" variant="standard">
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                    control={
                                                                        <Switch checked={checkUserInsert ? true : false} onClick={(e) => setCheckUserInsert(e.target.checked)} name="Cadastrar usuários" />
                                                                    }
                                                                    label="Cadastrar usuários"
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>  
                                                        </ListItem>
                                                        <ListItem
                                                        key={"value2"}
                                                        role="listitem"
                                                        button
                                                        >
                                                            <FormControl component="fieldset" variant="standard">
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                    control={
                                                                        <Switch checked={checkUserList ? true : false} onClick={(e) => setCheckUserList(e.target.checked)} name="Listar usuário" />
                                                                    }
                                                                    label="Listar usuário"
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>  
                                                        </ListItem>

                                                        <ListItem
                                                        key={"value3"}
                                                        role="listitem"
                                                        button
                                                        >
                                                            <FormControl component="fieldset" variant="standard">
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                    control={
                                                                        <Switch checked={checkUserRemove ? true : false} onClick={(e) => setCheckUserRemove(e.target.checked)} name="Excluir usuário" />
                                                                    }
                                                                    label="Excluir usuário"
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>    

                                                        </ListItem> 
  
                                                </div>

                                                <div className="form-cadastro" style={{padding: 20}}>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <h6>Cliente &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                                                        </div>
                                                    </div>
                                                    <ListItem
                                                        key={"val1"}
                                                        role="listitem"
                                                        button
                                                        >

                                                            <FormControl component="fieldset" variant="standard">
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                    control={
                                                                        <Switch checked={checkCliInsert ? true : false} onClick={(e) => setCheckCliInsert(e.target.checked)} name="Cadastrar cliente" />
                                                                    }
                                                                    label="Cadastrar cliente"
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>   
                                                            
                                                    </ListItem>
                                                    <ListItem
                                                    key={"val2"}
                                                    role="listitem"
                                                    button
                                                    >
                                                        <FormGroup>
                                                            <FormControlLabel
                                                            control={
                                                                <Switch checked={checkCliList ? true : false} onClick={(e) => setCheckCliList(e.target.checked)} name="Listar Cliente" />
                                                            }
                                                            label="Listar cliente"
                                                            />
                                                        </FormGroup>

                                                    </ListItem>

                                                    <ListItem
                                                    key={"val3"}
                                                    role="listitem"
                                                    button
                                                    >
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                control={
                                                                    <Switch checked={checkCliRemove ? true : false} onClick={(e) => setCheckCliRemove(e.target.checked)} name="Excluir usuário" />
                                                                }
                                                                label="Excluir usuário"
                                                                />
                                                            </FormGroup>

                                                    </ListItem>

                                                  
                                                    
                                                </div>


                                                <div className="form-cadastro" style={{padding: 20}}>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <h6>Serviço &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                                                        </div>
                                                    </div>
                                                    <ListItem
                                                        key={"val4"}
                                                        role="listitem"
                                                        button
                                                        >
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                control={
                                                                    <Switch checked={checkServiceInsert ? true : false} onClick={(e) => setCheckServiceInsert(e.target.checked)} name="Cadastrar serviço" />
                                                                }
                                                                label="Cadastrar serviço"
                                                                />
                                                            </FormGroup>

                                                    </ListItem>
                                                    <ListItem
                                                    key={"val5"}
                                                    role="listitem"
                                                    button
                                                    >
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                control={
                                                                    <Switch checked={checkServiceList  ? true : false} onClick={(e) => setCheckServiceList(e.target.checked)} name="Listar serviço" />
                                                                }
                                                                label="Listar serviço"
                                                                />
                                                            </FormGroup>

                                                    </ListItem>

                                                    <ListItem
                                                    key={"val6"}
                                                    role="listitem"
                                                    button
                                                    >
                                                        <FormGroup>
                                                            <FormControlLabel
                                                            control={
                                                                <Switch checked={checkServiceRemove ? true : false} onClick={(e) => setCheckServiceRemove(e.target.checked)} name="Excluir serviço" />
                                                            }
                                                            label="Excluir serviço"
                                                            />
                                                        </FormGroup>

                                                    </ListItem> 

                                                  
                                                    
                                                </div>



                                                <div className="form-cadastro" style={{padding: 20}}>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <h6>Painel &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                                                        </div>
                                                    </div>
                                                    <ListItem
                                                        key={"val7"}
                                                        role="listitem"
                                                        button
                                                        >

                                                        <FormGroup>
                                                            <FormControlLabel
                                                            control={
                                                                <Switch checked={checkPainelInsert ? true : false} onClick={(e) => setCheckPainelInsert(e.target.checked)} name="Cadastrar serviço" />
                                                            }
                                                            label="Cadastrar serviço"
                                                            />
                                                        </FormGroup>
                                                            
                                                    </ListItem>
                                                    <ListItem
                                                    key={"val8"}
                                                    role="listitem"
                                                    button
                                                    >
                                                        <FormGroup>
                                                            <FormControlLabel
                                                            control={
                                                                <Switch checked={checkPainelList ? true : false} onClick={(e) => setCheckPainelList(e.target.checked)} name="Listar painel" />
                                                            }
                                                            label="Listar painel"
                                                            />
                                                        </FormGroup>

                                                    </ListItem>

                                                    <ListItem
                                                    key={"val9"}
                                                    role="listitem"
                                                    button
                                                    >

                                                        <FormGroup>
                                                            <FormControlLabel
                                                            control={
                                                                <Switch checked={checkPainelRemove ? true : false} onClick={(e) => setCheckPainelRemove(e.target.checked)} name="Excluir painel" />
                                                            }
                                                            label="Excluir painel"
                                                            />
                                                        </FormGroup>
                                                    </ListItem> 

                                                  
                                                    
                                                </div>



                                                <div className="form-cadastro" style={{padding: 20}}>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <h6>Circuito &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                                                        </div>
                                                    </div>
                                                    <ListItem
                                                        key={"val10"}
                                                        role="listitem"
                                                        button
                                                        >

                                                        <FormGroup>
                                                            <FormControlLabel
                                                            control={
                                                                <Switch checked={checkCircuitInsert ? true : false} onClick={(e) => setCheckCircuitInsert(e.target.checked)} name="Cadastrar Circuito" />
                                                            }
                                                            label="Cadastrar Circuito"
                                                            />
                                                        </FormGroup>

                                                    </ListItem>
                                                    <ListItem
                                                    key={"val11"}
                                                    role="listitem"
                                                    button
                                                    >
                                                        <FormGroup>
                                                            <FormControlLabel
                                                            control={
                                                                <Switch checked={checkCircuitList ? true : false} onClick={(e) => setCheckCircuitList(e.target.checked)} name="Listar Circuito" />
                                                            }
                                                            label="Listar Circuito"
                                                            />
                                                        </FormGroup>

                                                    </ListItem>

                                                    <ListItem
                                                    key={"val12"}
                                                    role="listitem"
                                                    button
                                                    >
                                                        <FormGroup>
                                                            <FormControlLabel
                                                            control={
                                                                <Switch checked={checkCircuitRemove ? true : false} onClick={(e) => setCheckCircuitRemove(e.target.checked)} name="Excluir Circuito" />
                                                            }
                                                            label="Excluir Circuito"
                                                            />
                                                        </FormGroup>
                                                        
                                                    </ListItem>                                                   
                                                    
                                                </div>

                                                <div className="form-cadastro" style={{padding: 20}}>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <h6>Vendas &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                                                        </div>
                                                    </div>
                                                    <ListItem
                                                        key={"val13"}
                                                        role="listitem"
                                                        button
                                                        >

                                                        <FormGroup>
                                                            <FormControlLabel
                                                            control={
                                                                <Switch checked={checkVendaInsert ? true : false} onClick={(e) => setCheckVendaInsert(e.target.checked)} name="Cadastrar Venda" />
                                                            }
                                                            label="Cadastrar Venda"
                                                            />
                                                        </FormGroup>

                                                    </ListItem>
                                                    <ListItem
                                                        key={"val14"}
                                                        role="listitem"
                                                        button
                                                        >
                                                        <FormGroup>
                                                            <FormControlLabel
                                                            control={
                                                                <Switch checked={checkVendaList ? true : false} onClick={(e) => setCheckVendaList(e.target.checked)} name="Listar Venda" />
                                                            }
                                                            label="Listar Venda"
                                                            />
                                                        </FormGroup>

                                                    </ListItem>

                                                    <ListItem
                                                        key={"val15"}
                                                        role="listitem"
                                                        button
                                                        >
                                                        <FormGroup>
                                                            <FormControlLabel
                                                            control={
                                                                <Switch checked={checkVendaRemove  ? true : false} onClick={(e)=> setCheckVendaRemove(e.target.checked)} name="Excluir Venda" />
                                                            }
                                                            label="Excluir Venda"
                                                            />
                                                        </FormGroup>
                                                        
                                                    </ListItem>                                                   
                                                    
                                                </div>

                                                <div className="form-cadastro" style={{padding: 20}}>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <h6>Venda &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                                                        </div>
                                                    </div>
                                                    <ListItem
                                                        key={"val13"}
                                                        role="listitem"
                                                        button
                                                        >
                                                           
                                                        <FormGroup>
                                                            <FormControlLabel
                                                            control={
                                                                <Switch checked={checkEnviaVendaInsert ? true : false} onClick={(e) => setCheckEnviaVendaInsert(e.target.checked)} name="Integrar venda" />
                                                            }
                                                            label="Integrar venda"
                                                            />
                                                        </FormGroup>
                                                    </ListItem>
                                                    <ListItem >
                                                        <FormGroup>
                                                            <FormControlLabel
                                                            control={
                                                                <Switch checked={checkReabrirVenda ? true : false} onClick={(e) => setCheckReabrirVenda(e.target.checked)} name="Reabrir venda" />
                                                            }
                                                            label="Reabrir venda" 
                                                            />
                                                        </FormGroup> 
                                                    </ListItem>
                                                    
                                                </div>
                                                <div className="form-cadastro" style={{padding: 20}}>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <h6>Relatório &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                                                        </div>
                                                    </div>
                                                    <ListItem
                                                        key={"val13"}
                                                        role="listitem"
                                                        button
                                                        >
                                                           
                                                        <FormGroup>
                                                            <FormControlLabel
                                                            control={
                                                                <Switch checked={checkDashboard ? true : false} onClick={(e) => setCheckDashboard(e.target.checked)} name="Dashboard" />
                                                            }
                                                            label="Dashboard"
                                                            />
                                                        </FormGroup>
                                                    </ListItem>
                                                    <ListItem >
                                                        <FormGroup>
                                                            <FormControlLabel
                                                            control={
                                                                <Switch checked={checkDashboardScp ? true : false} onClick={(e) => setCheckDashboardScp(e.target.checked)} name="Dashboard SCP" />
                                                            }
                                                            label="Dashboard SCP" 
                                                            />
                                                        </FormGroup> 
                                                    </ListItem>
                                                    
                                                </div>

                                        </Card>


                                    </div>  {/** Fecha segundo lado */}

                                    


                                    <div className='row' style={{padding: 50}}>
                                            <div className="col-md-4">

                                            </div>
                                            <div className="col-md-4">

                                            </div>
                                            <div className="col-md-4">
                                                    
                                                {
                                                    permission.isinsert ? 
                                                    <Button variant="contained" onClick={id ? update : save} sx={{ mr: 1 }}>
                                                        <AddIcon /> {"Salvar" }
                                                    </Button>
                                                    :
                                                    ""
                                                }
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </Paper>
                    </div>
                </div>
            }/>
        </Container>

    </>

    )

}
export default NewUser;