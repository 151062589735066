import React from "react";
import { Container, Paper,
    AppBar, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import Toolbar from '@mui/material/Toolbar';
import saleService from "../../service/saleService";
import CustomizedBreadcrumbs from "../../component/breadcrumbs/breadcrumbs";
import SimpleBackdrop from "../../component/loading/loading";
import NewTableComponent from "../../component/table-sale/table-sale";
import { Button } from "antd";
import DrawerPrincipal from "../../component/drawer/drawer-principal";


function ListSale(){

    const usuario = JSON.parse(localStorage.getItem('user_token'));
    const permission = usuario.roles.filter(item=>item.name == "Vendas")[0];

    const [listSale, setListSale] = useState([]);
    const [loading, setLoading] = useState(1);
    const [open, setOpen] = useState(false)

    useEffect(() => {
        
        (async ()=> {

            setOpen(true)

            var list = [];
                            
               await saleService.list().then(item=> {
        
                   item.forEach(cust=> {
                       list.push(cust);
                   });
                   
                   setListSale(list);
                    setOpen(false)
                })
                .catch(error => {
                   setLoading(0)
                   console.log(error)
                //    if(error.code = "ERR_BAD_REQUEST"){
                //         signout();
                //         navigate('/login')
                //    }
                });


        })();

    },[]);

    const handleClose = () => {
        setOpen(false);
      };

    return (
            <>
                <Container maxWidth="lx" sx={{ bgcolor: '#EBEBEB', height: '100vh' }}>
                    
                    <DrawerPrincipal title={"Venda"} divOpen={
                        <div>
                            <div>
                            <SimpleBackdrop open={open} 
                                handleClose={handleClose} 
                                title={"Carregando informações..."}
                                />
                            <div className="row mb-3">
                                <CustomizedBreadcrumbs title1={"Home"} link1={"/"} 
                                        title2={"Lista de Vendas"} link2={"#"} 
                                        />
                            </div>
                                <Paper sx={{width: '100%', margin: 'auto', overflow: 'hidden' }}>
                                    



                                    <AppBar
                                        position="static"
                                        color="default"
                                        elevation={0}
                                        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
                                        >
                                        <Toolbar>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item style={{width: 200}}>
                                                    <h5>Lista de Vendas</h5>
                                                    
                                                </Grid>
                                                <Grid item xs>
                                                    
                                                </Grid>
                                                <Grid item>
                                                  
                                                </Grid>
                                                <Grid item>
                                                    {
                                                        permission?.isinsert ? 
                                                            <Link to={"/sale"}>
                                                                <Button style={{background: 'black', color: '#FFF'}} sx={{ mr: 1 }}>
                                                                    <AddIcon /> Novo
                                                                </Button>
                                                            </Link>
                                                            :
                                                            ""
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Toolbar>
                                    </AppBar>

                                     <div style={{ width: '100%', padding: 10 }}>
                                        <NewTableComponent data={listSale}/>

                                        {/* <TableSale key={listSale.length} list={listSale}/> */}
                                    </div> 
                            </Paper>
                        </div>
                    </div>
                }/>
            </Container>
        </>
    );
}


export default ListSale;