import { apiClient } from "./api";

// export const URL_CLIENT = "http://localhost:3000/api/";

const saleService = {


    async list(){

        var retorno = await apiClient.get('/sale');

        return retorno.data;
    },

    async maxGenerateNumber(){

        var retorno = await apiClient.get('/sale/generate');

        return retorno.data;
    },

    async maxGenerateNumberCodIntern(){

        var retorno = await apiClient.get('/sale/generate/codintern');

        return retorno.data;
    },

    async listFilter(id, type){

        var retorno = await apiClient.get('/sale/filter/'+id+'/'+type);

        return retorno.data;
    },


    async edit(id){

        var retorno = await apiClient.get("/sale/"+id);
        
        return retorno.data;
    },

    async save(sale){
        console.log(sale)

        var retorno = await apiClient.post("/sale", sale);

        return retorno;

    },

    async update(sale){
        console.log(sale)

        var retorno = await apiClient.put("/sale", sale);

        return retorno;

    },
    async updateDate(sale){
        console.log(sale)

        var retorno = await apiClient.put("/sale/saveSaleAlreadySend", sale);

        return retorno;

    },

    async delete(idsale){
        console.log(idsale)

        var retorno = await apiClient.delete("/sale/"+idsale);

        return retorno;

    },

    async deleteItemCampanha(idItem, idsale){
        console.log(idItem, idsale)

        var retorno = await apiClient.delete("/sale/campanha/" + idItem + "/"+idsale);

        return retorno;

    },

    async deleteItemCommission(idItem, idsale){
        console.log(idItem, idsale)

        var retorno = await apiClient.delete("/sale/comission/"+ idItem + "/" + idsale);

        return retorno;

    },

    async sendSale(sale){
        console.log(sale)

        var retorno = await apiClient.put("/sendSale", sale);

        return retorno;

    },

    async uploadPDF(formData){
        console.log(formData)
        
        var retorno = await apiClient.post("/sale/uploadPdf", formData, 
        {
            headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        );

        return retorno;

    },

    async listUpload(id){
        console.log(id)

        var retorno = await apiClient.get('/download/'+id, {responseType: 'blob' }, {
            headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        console.log(retorno)

        return retorno.data;
    },


}

export default saleService;