import { Card, Checkbox, Typography } from 'antd';
import * as React from 'react';
import MenuDropdown from '../dropdown/dropdown';
import './card.css';



export default function ImgMediaCard({item, days, vlService, qtdInsercao, onChange, isChecked}) {
    const { Text } = Typography;
    
    return (

        <>

            <Card
                key={item.name}
                title={item.name}
                bordered={false}
                headStyle={{background: (item.valid ? '#DDD' : 'red')}}
                style={{
                    width: 280,
                    margin: 10,
                    alignItems: 'self-start',
                    // background: '#f5f5f5'
                }}
                actions={[
                        <Checkbox 
                        checked={isChecked && isChecked} 
                        onChange={(e) => {
                            onChange(e.target.checked, item);
                        }} />,
                        <MenuDropdown list={item.availability}/>          
                ]}
            >
                <div className='row'>
                    <div className='col-12'>
                        <p>{item.address.address + ", " +  item.address.number + " " + item.address.neighborhood + " - " + item.address.city + " " + item.address.state}</p>
                        <p>{item.type}</p>
                    </div>
                    <div className='col-4'>
                        Qtd. Dias: <Text type="success"> {days} </Text>
                    </div>
                    <div className='col-4'>
                        Valor:<Text type="danger"> {vlService} </Text>
                    </div>

                </div>
            </Card>
        </>
    );
}