import React from "react";
import { AppBar, Button, Container, FormControl, Grid, MenuItem, Paper, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';

import Toolbar from '@mui/material/Toolbar';
import SearchIcon from '@mui/icons-material/Search';
import panelService from "../../service/servicePanel";
import TablePanel from "../../component/table-panel/table-panel";
import DrawerPrincipal from "../../component/drawer/drawer-principal";

function ListAvailability(){

    const [listPanel, setListPanel] = useState([]);
    const [loading, setLoading] = useState(1)
    const [search, setSearch] = useState("name");
    const [inputSearch, setInputSearch] = useState('0');


    useEffect(() => {
        
        (async ()=> {
            var list = [];
            
            await panelService.list().then(item=> {
    
               item.forEach(cust=> {
                   list.push(cust);
               });
               
               setListPanel(list);
    
            })
            .catch(error => {
               setLoading(0)
               console.log(error)
            //    if(error.code = "ERR_BAD_REQUEST"){
            //         signout();
            //         navigate('/login')
            //    }
            });

        })();

    },[]);


    async function searchPanel(code){
        var list = [];

        if(!code){

            await panelService.list().then(item=> {
    
                item.forEach(cust=> {
                    list.push(cust);
                });
                
                setListPanel(list);
     
             })
             .catch(error => {
                setLoading(0)
                console.log(error)
             //    if(error.code = "ERR_BAD_REQUEST"){
             //         signout();
             //         navigate('/login')
             //    }
             });

        }else{

            await panelService.listFilter(code)
                .then(item=> {
        
                item.forEach(cust=> {
                    list.push(cust);
                });
                
                setListPanel(list);
        
                })
                .catch(error => {
                setLoading(0)
                console.log(error)
                //    if(error.code = "ERR_BAD_REQUEST"){
                //         signout();
                //         navigate('/login')
                //    }
                });

        }


    }

    return (
        <>
            <Container maxWidth="lx" sx={{ bgcolor: '#EBEBEB', height: '100vh' }}>
                
                <DrawerPrincipal title={"Disponibilidade"} divOpen={
                    <div>
                        <div>
                            <Paper sx={{width: '100%', margin: 'auto', overflow: 'hidden' }}>

                                <AppBar
                                    position="static"
                                    color="default"
                                    elevation={0}
                                    sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
                                    >
                                    <Toolbar>
                                        <Grid container spacing={2} alignItems="center">
                                        <Grid item style={{width: 200}}>
                                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                                <Select
                                                fullWidth
                                                size="small"
                                                value={search && search}
                                                onChange={(e)=> {setSearch(e.target.value)}}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem value={"name"}>Nome</MenuItem>
                                                </Select>
                                            </FormControl>
                                            </Grid>
                                            <Grid item xs>
                                            <TextField
                                                fullWidth
                                                placeholder="Pesquisar por nome"
                                                onChange={(e)=> setInputSearch(e.target.value)}
                                                InputProps={{
                                                disableUnderline: true,
                                                sx: { fontSize: '100' },
                                                }}
                                                variant="standard"
                                            />
                                            </Grid>
                                            <Grid item>
                                                <Button variant="outlined" onClick={(e) => {searchPanel(inputSearch)}} startIcon={<SearchIcon />}>
                                                    Pesquisar
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Link to={"/panel"}>
                                                    <Button variant="contained" sx={{ mr: 1 }}>
                                                        <AddIcon /> Novo
                                                    </Button>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </Toolbar>
                                </AppBar>

                                <div style={{ width: '100%', padding: 10 }}>
                                    <TablePanel key={listPanel.length} list={listPanel}/>
                                </div>
                            </Paper>
                        </div>
                    </div>
                }/>
            </Container>
        </>
    );
}


export default ListAvailability;