import React from 'react';
import { Box, Button, Modal, Stack } from '@mui/material';
import { useState } from 'react';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';


function UploadFile({open, handleUpload, handleClose, isDownload, listUpload, file}){

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        heigth: '70%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      };

    const [selectedFile, setSelectedFile] = useState();
	const [isSelected, setIsSelected] = useState(false);

	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsSelected(true);
        handleUpload(event.target.files[0])
	};

    return( 
 
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                     <>
                        {/* <div class="container"> */}

                            <div className='row'>
                                <div className='col-6' >
                                <Box
                                    sx={{
                                        width: '30em',
                                        height: '30em',
                                        p: 2, 
                                        border: '1px dashed grey',
                                        borderRadius: '5px'  ,
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                    >

                                    <Stack  alignItems="center" spacing={2} style={{textAlign: 'center'}}>

                                        <Stack  alignItems="center" spacing={2} >
                                            <CloudUploadOutlinedIcon color='primary' sx={{fontSize: 100}}/>
                                        </Stack>

                                        <Stack  alignItems="center"  spacing={2} className="mb-4 mt-2">
                                            <Button variant="contained" component="label">
                                                {isDownload ? 'Editar' : 'Escolha o '} arquivo
                                                <input hidden accept=".pdf" multiple type="file" onChange={changeHandler}/>
                                            </Button>
                                        </Stack>

                                        {isSelected ? (
                                            <div>
                                                <Stack  alignItems="center" spacing={2} className="mt-2" >
                                                    <p>{selectedFile.name}</p>

                                                </Stack>
                                            </div>
                                            ) : (
                                                
                                                isDownload ? 
                                                    <Stack  alignItems="center" spacing={2} className="mb-9">
                                                        <p>Você pode subistituir o arquivo ao lado escolhendo outro e salvando a venda. </p>
                                                    </Stack>
                                                    : 
                                                    <Stack  alignItems="center" spacing={2} className="mb-9">
                                                        <p>Escolha o arquivo PDF para importar ao salvar uma venda </p>
                                                    </Stack>
                                            
                                            )
                                    
                                        }
                                    </Stack>
                                </Box>

                                </div>
                                <div className='col-6' >

                                <Box
                                    sx={{
                                        width: '30em',
                                        height: '30em',
                                        p: 2, 
                                        border: '1px dashed grey',
                                        borderRadius: '5px' ,
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                    >
                                    <Stack  alignItems="center" spacing={2} style={{textAlign: 'center'}}>

                                        <Stack  alignItems="center" spacing={2} style={{textAlign: 'center'}}>
                                            <CloudDownloadOutlinedIcon color='secondary' sx={{fontSize: 100}}/>
                                        </Stack>
                                        {
                                            isDownload ? 

                                            <>
                                                
                                                <Stack  alignItems="center" spacing={2} className="mb-9">
                                                
                                                    <Button variant="contained" color="secondary" onClick={(e)=>{listUpload(file.filename)}} sx={{ mr: 1 }} >
                                                        <PictureAsPdfOutlinedIcon />  Download P.I
                                                    </Button> 
                                                </Stack>

                                                <Stack  alignItems="center" spacing={2} className="mb-9 mt-4">

                                                    <p>{file.filename} </p>

                                                </Stack>

                                            </>
                                            : 
                                            <p>Ainda não há P.I para download</p>
                                        }
                                    </Stack>
                                </Box>
                                </div>

                            </div>

                            

                            <div className='row'>
                                <Stack  alignItems="center" spacing={2} className="mt-4">
                                    <Button variant="contained" color='success' component="label" onClick={handleClose}>
                                        Voltar para venda e salvar
                                    </Button>
                                </Stack>
                            </div>
                            


                            {/* <div class="box">
                                
                                <Stack  alignItems="center" spacing={2} style={{textAlign: 'center'}}>
                                    <CloudUploadOutlinedIcon color='primary' sx={{fontSize: 100}}/>
                                </Stack>

                                <Stack  alignItems="center"  spacing={2} className="mb-4 mt-2">
                                    <Button variant="contained" component="label">
                                        {isDownload ? 'Editar' : 'Escolha o '} arquivo
                                        <input hidden accept=".pdf" multiple type="file" onChange={changeHandler}/>
                                    </Button>
                                </Stack>

                                {isSelected ? (
                                    <div>
                                        <Stack  alignItems="flex-start" spacing={2} className="mt-2">
                                            <p>{selectedFile.name}</p>

                                        </Stack>
                                    </div>
                                    ) : (
                                        
                                        isDownload ? 
                                            <Stack  alignItems="center" spacing={2} className="mb-9">
                                                <p>Escolha o arquivo PDF para importar ao salvar uma venda </p>
                                            </Stack>
                                            : 
                                            <Stack  alignItems="center" spacing={2} className="mb-9">
                                                <p>Escolha o arquivo PDF para importar ao salvar uma venda </p>
                                            </Stack>
                                    
                                    )
                                }
                                {
                                    isDownload ? 

                                    <>
                                        
                                        <Stack  alignItems="center" spacing={2} className="mb-9">

                                            <Button variant="contained" color="secondary" onClick={listUpload} sx={{ mr: 1 }} >
                                                <PictureAsPdfOutlinedIcon />  Download P.I
                                            </Button> 
                                        </Stack>

                                        <Stack  alignItems="center" spacing={2} className="mb-9 mt-4">

                                            <p>{file.filename} </p>

                                        </Stack>
                                    </>
                                    : 
                                    ""
                                }

                                <Stack  alignItems="center" spacing={2} className="mt-4">
                                    <Button variant="contained" color='success' component="label" onClick={handleClose}>
                                        Ok
                                    </Button>
                                </Stack>
                            </div> */}
                        {/* </div> */}
                </>
             </Box>
                   
            </Modal>
        </div>

    )

};

export default UploadFile;