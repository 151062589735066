import React from 'react';
import { CurrencyInput } from 'react-currency-mask';
import { TextField } from '@mui/material';

const MyComponent = ({ handleInput, labelInput, labelError, value, isDisable}) => {
  return (
    <CurrencyInput
      onChangeValue={(event, originalValue, maskedValue) => {
        handleInput(originalValue)
      }}
      value={value && value}
      InputElement={
        <TextField 
          defaultValue={0}
          label={labelInput} 
          value={value} 
          size="small" 
          error={labelError && labelError}
          helperText={labelError ? labelError : ""}
          disabled={isDisable ? true : false}
        />
      }
    />
  );
};

export default MyComponent;