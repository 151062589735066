import React, { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import { Button, CardContent, Container, Typography, Paper, Card, Grid, ListItem, ListItemIcon, Checkbox, ListItemText, List, FormGroup, FormControlLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from "react-router-dom";
import ResponsiveDialog from "../../component/dialog/dialog";
import circuitService from "../../service/serviceCircuit";
import panelService from "../../service/servicePanel";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CustomizedBreadcrumbs from "../../component/breadcrumbs/breadcrumbs";
import DrawerPrincipal from "../../component/drawer/drawer-principal";

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }
  
  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

function NewCircuit(){

    const usuario = JSON.parse(localStorage.getItem('user_token'));
    const permission = usuario.roles.filter(item=>item.name == "Circuito")[0];

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");

    const [listPanel, setListPanel] = useState([]);
    const [listPanelDB, setListPanelDB] = useState([]);

    const [isActive, setIsActive] = useState([]);

    const [user, setUser] = useState("");

    const [openDialog, setOpenDialog] = useState();
    const [messageDialog, setMessageDialog] = useState();
    const [titleDialog, setTitleDialog] = useState();
    const [statusDialog, setStatusDialog] = useState();


    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);



    const {id} = useParams();

    let navigate = useNavigate();

    function removerObjetosIguais(lista1, lista2) {

        // Filtrar a lista1 removendo os objetos que estão presentes na lista2
        var listaFiltrada = lista1.filter(function(objeto1) {
          // Verificar se o objeto1 está presente na lista2
          var encontrado = lista2.some(function(objeto2) {
            return objeto1.idpanel === objeto2.idpanel && objeto1.name === objeto2.name;
          });
          return !encontrado;
        });
      
        return listaFiltrada;
    }


    useEffect(() => {

        (async ()=> {
            if(id)  {


                var listaRight = [];
                await circuitService.edit(id)
                    .then(item => {

                        setName(item?.name)
                        setUser(item?.user)
                        setIsActive(item?.isActive)
                        setListPanelDB(item?.panels)
                        listaRight.push(item.panels)
                        
                        setRight(item.panels);
    
                    }).catch(error => {
                        console.log(error)
                    });
    
                    await panelService.list().then(itensLeft => {
                        
                        var listaFiltrada = removerObjetosIguais(itensLeft, listaRight[0]);                        
                        setLeft(listaFiltrada);

                    }).catch(error => {
                        console.log(error)
                    });                    

                    
            }else{

                var listBanco = [];

                await panelService.list().then(item => {
                    item.forEach(panel=> {
                        listBanco.push(panel)
                    });
                    
                    setListPanel(listBanco)
                    setLeft(listBanco);
                }).catch(error => {
                    console.log(error)
                });

            }
        })();

    },[]);

    function save(){

        console.log("Salvando... ")

        if(valid()) {

            var data = {
                name: name,
                panels: right,
                isActive: isActive
            }

            console.log(data)

            circuitService.save(data).then(item => {
                console.log(item.status)

                if(item.status === 201){ 
                    setOpenDialog(true);
                    setStatusDialog('success')
                    setTitleDialog("Muito bem!")
                    setMessageDialog("Circuito cadastrado com sucesso")
                }
            }).catch(error => {
                handleDialogOpen()
                setStatusDialog('error')
                setTitleDialog("Ops")
                setMessageDialog(error.response.data)
            });
        }
    }



    async function update(){

        if(valid()) {

            var data = {
                idcircuit: id,
                name: name,
                panels: right,
                isActive: isActive
            }

    
            await circuitService.update(data).then( async item => {

                if(item.status === 200){
                    setOpenDialog(true);
                    setStatusDialog('success')
                    setTitleDialog("Muito bem!")
                    setMessageDialog("Circuito atualizado com sucesso")
                }
                
    
            }).catch(error => {
                setOpenDialog(true);
                setStatusDialog('error')
                setTitleDialog("Ops")
                setMessageDialog(error.response.data)
            });
        }

    }

    function valid(){

        
        let message = "Este campo é obrigatório";
        let thatsOk = true;


        if(!name){
            setNameError(message)
            thatsOk = false;
        }

        return thatsOk;

    }


    const handleDialogOpen = () => {
        setOpenDialog(true);
    };
    
    const handleDialogClose = () => {
        setOpenDialog(false);
        navigate("/listCircuit");
    };
    
    const cancel = () => {
        navigate("/listCircuit");
    }

  const handleToggle = (value) => () => {

    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);

  };

  const handleAllRight = () => {
   
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const handleCheckd = (value) => {
    setIsActive(value)
  }


  const customList = (items) => (
    <Paper sx={{ width: 400, height: 400, overflow: 'auto' }}>
      <List dense component="div" role="list">
        {items.map((value) => {
        
          const labelId = `transfer-list-item-${value.name}-label`;

          return (
            <ListItem
              key={value.idpanel}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.name }`} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

    return(

    <>

        <Container maxWidth="lx" sx={{ bgcolor: '#EBEBEB'}}>
            
            <DrawerPrincipal divOpen={
                <div>
                    <div>
                    <div className="row mb-3">
                        <CustomizedBreadcrumbs title1={"Home"} link1={"/"} 
                                title2={"Lista de Circuitos"} link2={"#"} 
                                title3={"Cadastro de Circuito"} link3={"#"}/>
                    </div>
                     <ResponsiveDialog 
                        open={openDialog} 
                        handleClose={handleDialogClose}
                        title={titleDialog}
                        message={messageDialog} 
                        status={statusDialog}
                        />
                    
                        <div className="row">

                            <h1 className="h3 mb-3 text-black font-weight-bold"> Cadastro de Circuito </h1>

                        </div>

                        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
                            <div className="container">
                                <div className="row">     
                                    <div className="col-12">
                                            <Card>
                                                <div className="form-cadastro" style={{padding: 20}}>
                                                    <Typography sx={{ width: 14 }} className="mb-3" gutterBottom variant="h7" component="div">
                                                        Circuitos
                                                    </Typography>

                                                    <div className="row">
                                                        <div className="col-md-8 mb-3">
                                                            <TextField
                                                                fullWidth                                                            
                                                                label={name ? "Nome do Circuito" : "Nome do Circuito"}
                                                                id="outlined-size-small"
                                                                focused={name && name}
                                                                size="small"
                                                                value={name && name}
                                                                onChange={(e)=> {
                                                                    setName(e.target.value);
                                                                    setNameError("")
                                                                }}
                                                                error={nameError && nameError}
                                                                helperText={nameError ? nameError : ""}


                                                            />
                                                        </div>

                                                        <div className="col-md-3 mb-3">
                                                            <FormGroup>
                                                                <FormControlLabel control={<Checkbox checked={isActive && isActive} onClick={(e) => handleCheckd(e.target.checked)} />} label="Ativo" />
                                                            </FormGroup>
                                                        </div>

 
                                                    </div>

                                                    <div className="row">


                                                        <div className="col-md-3">
                                                            
                                                        </div>

                                                        <div className="col-md-3 mb-3">

                                                        </div>

                                                        <div className="col-md-2">
                                                        </div>

                                                    </div>

                                                </div>
                                            </Card>     

                                            <div style={{padding: 5}}>
                                            </div>
                                            <Card>
                                                <CardContent>
                                                    <Typography sx={{ width: 14 }} className="mb-3" gutterBottom variant="h7" component="div">
                                                        Painéis
                                                    </Typography>
                                                        <div className="row">


                                                            <Grid container spacing={2} justifyContent="center" alignItems="center">
                                                                <Grid item>{customList(left)}</Grid>
                                                                <Grid item>
                                                                    <Grid container direction="column" alignItems="center">
                                                                    <Button
                                                                        sx={{ my: 0.5 }}
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={handleAllRight}
                                                                        disabled={left.length === 0}
                                                                        aria-label="move all right"
                                                                    >
                                                                        ≫
                                                                    </Button>
                                                                    <Button
                                                                        sx={{ my: 0.5 }}
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={handleCheckedRight}
                                                                        disabled={leftChecked.length === 0}
                                                                        aria-label="move selected right"
                                                                    >
                                                                        &gt;
                                                                    </Button>
                                                                    <Button
                                                                        sx={{ my: 0.5 }}
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={handleCheckedLeft}
                                                                        disabled={rightChecked.length === 0}
                                                                        aria-label="move selected left"
                                                                    >
                                                                        &lt;
                                                                    </Button>
                                                                    <Button
                                                                        sx={{ my: 0.5 }}
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={handleAllLeft}
                                                                        disabled={right.length === 0}
                                                                        aria-label="move all left"
                                                                    >
                                                                        ≪
                                                                    </Button>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>{customList(right)}</Grid>

                                                            </Grid>

                                                            <div className="col-md-3 mb-3">
                                                                
                                                            </div>
                                                            <div className="col-md-2 mb-3">
                                                               
                                                            </div>
                                                            <div className="col-md-4 mb-3">
                                                                
                                                            </div>
                                                        </div>
                                                </CardContent>

                                            </Card>                                       

                                    </div>  {/** Fecha segundo lado */}

                                    <div className='row' style={{padding: 50}}>
                                            <div className="col-md-8">

                                            </div>
                                            <div className="col-md-2">
                                                <Button variant="contained" color="warning" onClick={cancel} sx={{ mr: 1 }}>
                                                    <HighlightOffIcon />{"Cancelar"}
                                                </Button>
                                            </div>
                                            <div className="col-md-2">
                                                {
                                                    permission?.isinsert ? 
                                                        <Button variant="contained" onClick={id ? update : save} sx={{ mr: 1 }}>
                                                            <AddIcon /> Salvar
                                                        </Button>
                                                    :
                                                    ""
                                                }
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </Paper>
                    </div>
                </div>
            }/>
        </Container>

    </>

    )

}
export default NewCircuit;