import React from "react";
import { Card } from "@mui/material";
import DrawerPrincipal from "../../component/drawer/drawer-principal";
import './dashboard-scp.css'

function DashboardScp(){

    return (
        <>
            {/* <Container maxWidth="xl" style={{width: '100%', height: '100%', bgcolor: '#FFFFF'}}> */}
                <DrawerPrincipal title={"Dashboard SCP"} divOpen={
                    
                    <div className="iframe-container">
                        <Card>
                            <div className="iframe-wrapper">
                                <iframe
                                    className="responsive-iframe"
                                    src="https://analytics.zoho.com/open-view/1907568000008674584/ed4968d1ef588cedfb0ec54fd142c670"
                                    allowFullScreen
                                />
                            </div>
                        </Card>
                    </div>
                }/>
            {/* </Container> */}
        </>
    );
}


export default DashboardScp;