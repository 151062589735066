import React, { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import { Button, Container, Typography, Paper, Card, FormControl, InputLabel, Select, MenuItem, FormHelperText, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from "react-router-dom";
import ResponsiveDialog from "../../component/dialog/dialog";
import serviceService from "../../service/serviceService";
import MyComponent from "../../component/input-mask/input-mask";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CustomizedBreadcrumbs from "../../component/breadcrumbs/breadcrumbs";
import DrawerPrincipal from "../../component/drawer/drawer-principal";


function NewService(){

    const usuario = JSON.parse(localStorage.getItem('user_token'));
    const permission = usuario.roles.filter(item=>item.name === "Serviço")[0];

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");

    const [type, setType] = useState("");
    const [typeError, setTypeError] = useState("");

    const [qtdInsert, setQtdInsert] = useState();

    const [vlSale, setVlSale] = useState("");
    const [vlSaleError, setVlSaleError] = useState("");

    const [isActive, setIsActive] = useState(false);


    const [user, setUser] = useState("");
    const [dtInsert, setDtInsert] = useState("");
    const [dtUpdate, setDtUpdate] = useState("");

    const [openDialog, setOpenDialog] = useState();
    const [messageDialog, setMessageDialog] = useState();
    const [titleDialog, setTitleDialog] = useState();
    const [statusDialog, setStatusDialog] = useState();

    const {id} = useParams();

    let navigate = useNavigate();

    useEffect(() => {

        if(id)  {
            serviceService.edit(id)
            .then(item => {

                setName(item?.name)
                setType(item?.type)
                setQtdInsert(item?.qtdInsert)
                setVlSale(item?.vlSale)
                setDtInsert(item?.dtInsert)
                setDtUpdate(item?.dtUpdate)
                setUser(item?.user)
                setIsActive(item?.isActive)

            }).catch(error => {
                console.log(error)
            });
        }

    },[id]);

    function save(){

        if(valid()) {

            var data = {
                name: name,
                type: type,
                qtdInsert: qtdInsert,
                vlSale: vlSale,
                user: user,
                isActive: isActive
            }


            console.log(data)
    
            serviceService.save(data).then(item => {

                setOpenDialog(true);
                setStatusDialog('success')
                setTitleDialog("Muito bem!")
                setMessageDialog("Serviço cadastrado com sucesso")
                
            }).catch(error => {
                handleDialogOpen()
                setTitleDialog("Ops")
                setMessageDialog(error.response.data)
            });
        }



    }

    const cancel = () => {
        navigate("/listService")
    }


    function update(){

        if(valid()) {

            var data = {
                idservice: id,
                name: name,
                type: type,
                qtdInsert: qtdInsert,
                vlSale: vlSale,
                user: user,
                isActive: isActive
            }

    
            serviceService.update(data).then(item => {
                setOpenDialog(true);
                setStatusDialog('success')
                setTitleDialog("Muito bem!")
                setMessageDialog("Serviço atualizado com sucesso")
            }).catch(error => {
                setOpenDialog(true);
                setStatusDialog('error')
                setTitleDialog("Ops")
                setMessageDialog(error.response.data)
            });
        }

    }

    function valid(){

        let thatsOk = true;

        if(!name){
            setNameError("O nome precisa ser preenchido")
            thatsOk = false;
        }
        if(!type){
            setTypeError("O tipo precisa ser preenchido")
            thatsOk = false;
        }
        // if(!qtdInsert){
        //     setQtdInsertError("A quantidade precisa ser preenchida")
        //     thatsOk = false;
        // }
        if(!vlSale){
            setVlSaleError("O valor da venda precisa ser preenchido")
            thatsOk = false;
        }
        return thatsOk;

    }


    const handleDialogOpen = () => {
        setOpenDialog(true);
    };
    
    const handleDialogClose = () => {
        setOpenDialog(false);
        navigate("/listService");
    };
      
    const handleInput = (inputValue) => {
        setVlSale(inputValue)
    }

    const handleCheckd = (value) => {
        setIsActive(value)
    }

    
    return(

    <>

        <Container maxWidth="lx" sx={{ bgcolor: '#EBEBEB', height: '100vh' }}>
            
            <DrawerPrincipal divOpen={
                <div>
                    <div>

                    <div className="row mb-3">
                        <CustomizedBreadcrumbs title1={"Home"} link1={"/"} 
                                title2={"Lista de Serviço"} link2={"#"} 
                                title3={"Cadastro de Serviço"} link3={"#"}/>
                    </div>
                    <ResponsiveDialog 
                        open={openDialog} 
                        handleClose={handleDialogClose}
                        title={titleDialog}
                        message={messageDialog} 
                        status={statusDialog}
                        />

                        <div className="row">

                            <h1 className="h3 mb-3 text-black font-weight-bold" >Cadastro de Serviço</h1>

                        </div>

                        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
                            <div className="container">
                                <div className="row">     
                                    <div className="col-12">
                                            <Card>
                                                <div className="form-cadastro" style={{padding: 20}}>
                                                    <Typography sx={{ width: 100 }} className="mb-3" gutterBottom variant="h7" component="div">
                                                        Dados gerais
                                                    </Typography>

                                                    <div className="row">
                                                       

                                                        <div className="col-md-3 mb-3">
                                                            <TextField
                                                                fullWidth                                                            
                                                                label={name ? "Nome do serviço" : "Nome do serviço"}
                                                                id="outlined-size-small"
                                                                focused={name && name}
                                                                size="small"
                                                                value={name && name}
                                                                onChange={(e)=> {
                                                                    setName(e.target.value);
                                                                    setNameError("")
                                                                }} 
                                                                error={nameError && nameError}
                                                                helperText={nameError ? nameError : ""}
                                                                />
                                                            
                                                        </div>
                                                        <div className="col-md-3 mb-3">
                                                                <FormControl fullWidth size="small" error={typeError && typeError}>
                                                                    <InputLabel id="demo-select-small">Tipo de serviço</InputLabel>
                                                                    <Select
                                                                        labelId="demo-select-small"
                                                                        id="demo-select-small"
                                                                        value={type && type}
                                                                        focused={type && type}
                                                                        label="Tipo de Serviço"
                                                                        onChange={(e) => {
                                                                            setType(e.target.value);
                                                                            setTypeError("")
                                                                        }}                                                                        
                                                                    >
                                                                        <MenuItem value={"Sao Paulo"}>São Paulo</MenuItem>
                                                                        <MenuItem value={"Contas Publicas"}>Contas Publicas</MenuItem>
                                                                        <MenuItem value={"Regional Recife"}>Regional Recife</MenuItem>
                                                                        <MenuItem value={"Regional Caruaru"}>Regional Caruaru</MenuItem>
                                                                    </Select>
                                                                    <FormHelperText >{typeError ? typeError : ""}</FormHelperText>
                                                                </FormControl>
                                                        </div> 
                                                        <div className="col-md-6">
                                                            
                                                        </div> 
 
                                                    </div>

                                                    <div className="row">


                                                        <div className="col-md-2 mb-3">

                                                            <MyComponent 
                                                                handleInput={(e)=> {
                                                                handleInput(e);
                                                                setVlSaleError("")
                                                                }} 
                                                            value={vlSale && vlSale}
                                                            labelError={vlSaleError ? vlSaleError : ""}
                                                            labelInput="Valor da venda"/>
                                                        
                                                            {/* <TextField
                                                                fullWidth                                                            
                                                                label={qtdInsert ? "Quantidade inserção" : "Quantidade inserção"}
                                                                id="outlined-size-small"
                                                                focused={qtdInsert && qtdInsert}
                                                                type="number"
                                                                size="small"
                                                                value={qtdInsert && qtdInsert}
                                                                onChange={(e)=> {
                                                                    setQtdInsert(e.target.value);
                                                                    setQtdInsertError("")
                                                                }} 
                                                                error={qtdInsertError && qtdInsertError}
                                                                helperText={qtdInsertError ? qtdInsertError : ""}
                                                                /> */}
                                                            
                                                        </div>

                                                        <div className="col-md-3 mb-3">
                                                            <FormGroup>
                                                                <FormControlLabel control={<Checkbox checked={isActive && isActive} onClick={(e) => handleCheckd(e.target.checked)} />} label="Ativo" />
                                                            </FormGroup>
                                                        </div>

                                                        <div className="col-md-2">

                                                           
                                                        </div>

                                                    </div>

                                                </div>
                                            </Card>
                                           

                                    </div>  {/** Fecha segundo lado */}

                                    <div className='row' style={{padding: 50}}>
                                            <div className="col-md-8">

                                            </div>
                                            <div className="col-md-2">
                                                <Button variant="contained" color="warning" onClick={cancel} sx={{ mr: 1 }}>
                                                    <HighlightOffIcon />{"Cancelar"}
                                                </Button>
                                            </div>
                                            <div className="col-md-2">
                                                {
                                                    permission.isinsert ?
                                                    <Button variant="contained" onClick={id ? update : save} sx={{ mr: 1 }}>
                                                        <AddIcon /> Salvar
                                                    </Button>
                                                    :
                                                    ""
                                                }
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </Paper>
                    </div>
                </div>
            }/>
        </Container>

    </>

    )

}
export default NewService;