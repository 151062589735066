import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import GridViewIcon from '@mui/icons-material/GridView';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import BorderAllOutlinedIcon from '@mui/icons-material/BorderAllOutlined';
import ConnectedTvOutlinedIcon from '@mui/icons-material/ConnectedTvOutlined';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LogoutIcon from '@mui/icons-material/Logout';
import logo from '../../assets/img/nova-logo-branca.png'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Content } from 'antd/es/layout/layout';
import { Layout, Tag } from 'antd';
import Sider from 'antd/es/layout/Sider';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import packageJson from '../../../package.json';

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  
  var div = props.divOpen.props.children.props.children;

  const { window } = props;

  
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const usuario = JSON.parse(localStorage.getItem('user_token'));
  const permSale = usuario.roles.filter(item=>item.name === "Vendas")[0];
  const permCircuit = usuario.roles.filter(item=>item.name === "Circuito")[0];
  const permClient = usuario.roles.filter(item=>item.name === "Cliente")[0];
  const permPanel = usuario.roles.filter(item=>item.name === "Painel")[0];
  const permService = usuario.roles.filter(item=>item.name === "Serviço")[0];
  const permUser = usuario.roles.filter(item=>item.name === "Usuario")[0];
  const permDashScp = usuario.roles.filter(item=>item.name === "Dashboard SCP")[0];
  const permDash = usuario.roles.filter(item=>item.name === "Dashboard")[0];


  const { signout } = useAuth();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar style={{background: '#000'}}>
          <img src={logo} width={"200"} height={"40"}/>        
      </Toolbar>
      <Divider />
        <List>

              {
                  permDash.isinsert ? 
                <Link to={"/"} style={{textDecorationLine: "none", color: "#707070" }}>
                  <ListItem key={""} disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <GridViewIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Dashboard"} />
                      </ListItemButton>
                  </ListItem>
                </Link>
                : 
                "" 
              } 

              {
                permDashScp.isinsert ? 
                <Link to={"/dashboardscp"} style={{textDecorationLine: "none", color: "#707070" }}>
                  <ListItem key={""} disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <DashboardCustomizeOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Dashboard SCP"} />
                      </ListItemButton>
                  </ListItem>
                </Link>
              : 
                "" 
              } 

              {
                permClient.islist ? 
                  <Link to={"/listCustomer"} style={{textDecorationLine: "none", color: "#707070" }}>
                    <ListItem key={"Cliente"} disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <CoPresentIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Cliente"} />
                        </ListItemButton>
                    </ListItem>
                  </Link>
                  : 
                  ""
              }
              {
                permService.islist ? 
                  <Link to={"/listService"} style={{textDecorationLine: "none", color: "#707070" }}>
                    <ListItem key={""} disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <ManageAccountsOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Serviços"} />
                        </ListItemButton>
                    </ListItem>
                  </Link>
                : 
                ""
              }
              {
              permPanel.islist ? 
                <Link to={"/listPanel"} style={{textDecorationLine: "none", color: "#707070" }}>
                  <ListItem key={""} disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                        <ConnectedTvOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Painel"} />
                      </ListItemButton>
                  </ListItem>
                </Link>
                :
                ""
            }
            {
              permCircuit.islist ? 
                <Link to={"/listCircuit"} style={{textDecorationLine: "none", color: "#707070" }}>
                  <ListItem key={""} disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                        <BorderAllOutlinedIcon /> 
                        </ListItemIcon>
                        <ListItemText primary={"Circuito"} />
                      </ListItemButton>
                  </ListItem>
                </Link>
              : 
              ""
            }
        </List>
        <Divider />
        <List>
              <Link to={"/availability"} style={{textDecorationLine: "none", color: "#707070" }}>
                <ListItem key={"Disponibilidade"} disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <EventAvailableIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Disponibilidade"} />
                      </ListItemButton>
                </ListItem>
              </Link>
              {/* <Link to={"/timeline"} style={{textDecorationLine: "none", color: "#707070" }}>
                <ListItem key={"Timeline"} disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <EventAvailableIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Timeline"} />
                      </ListItemButton>
                </ListItem>
              </Link> */}
       
       {
        permSale.islist ? 
              <Link to={"/listSale"} style={{textDecorationLine: "none", color: "#707070" }}>
                <ListItem key={"Venda"} disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <AttachMoneyIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Venda"} />
                      </ListItemButton>
                </ListItem>
              </Link>
              : 

              " "
       }
        </List>
        <Divider />
        <List>
            {
              permUser.islist ? 
                <Link to={"/user"} style={{textDecorationLine: "none", color: "#707070" }}>
                  <ListItem key={"Usuário"} disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <AccountBoxIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Usuário"} />
                        </ListItemButton>
                  </ListItem>
                </Link>
              : 
              ""
            }
              <Link to={"#"} style={{textDecorationLine: "none", color: "#707070" }}>
                <ListItem key={"Sair"} disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText onClick={() => [signout(), navigate("/")]} primary={"Sair"} />
                      </ListItemButton>
                </ListItem>
              </Link>

        </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          bgcolor: '#000000'

        }}
      >
       
        <Toolbar >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
            <Typography variant="h6" >
              {usuario.name}
            </Typography>
          
            <Layout>
              <Sider style={{background: '#000000'}}></Sider>
              <Content style={{background: '#000000'}}></Content>
              <Sider style={{background: '#000000'}}>
                <Tag color={packageJson.ambiente === 'Homologação' ? "orange" : '#000'}>
                  {packageJson.ambiente}: {packageJson.version}
                </Tag>
              </Sider>
            </Layout>
        </Toolbar>

      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1,  width: { sm: `calc(100% - ${drawerWidth}px)` } ,  bgcolor: '#EBEBEB'}}
        
      >
        <Toolbar />
          {div}
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;