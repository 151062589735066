import React from "react";
import { Container, Paper, 
    AppBar, Grid } from "@mui/material";
import customerService from "../../service/serviceCustomer";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import Toolbar from '@mui/material/Toolbar';
import CustomizedBreadcrumbs from "../../component/breadcrumbs/breadcrumbs";
import { Button } from "antd";
import TableCustomer from "../../component/table-customer/table-customer";
import DrawerPrincipal from "../../component/drawer/drawer-principal";

function ListCustomer(){

    const usuario = JSON.parse(localStorage.getItem('user_token'));
    const permission = usuario.roles.filter(item=>item.name == "Cliente")[0];

    const [listCustomer, setListCustomer] = useState([]);
    const [loading, setLoading] = useState(1);

    useEffect(() => {
        
        (async ()=> {
            var list = [];
            
            await customerService.listCustomer().then(item=> {
    
               item.forEach(cust=> {
                   list.push(cust);
               });
               
               setListCustomer(list);
    
            })
            .catch(error => {
               setLoading(0)
               console.log(error)
            //    if(error.code = "ERR_BAD_REQUEST"){
            //         signout();
            //         navigate('/login')
            //    }
            });

        })();

    },[]);


    return (
            <>
                <Container maxWidth="lx" sx={{ bgcolor: '#EBEBEB', height: '100vh' }}>
                    
                    <DrawerPrincipal title={"Cliente"} divOpen={
                        <div>
                            <div>

                                <div className="row mb-3">
                                    <CustomizedBreadcrumbs title1={"Home"} link1={"/"} title2={"Lista de Clientes"} link2={"#"}/>
                                </div>

                                <Paper sx={{width: '100%', margin: 'auto', overflow: 'hidden' }}>

                                    <AppBar
                                        position="static"
                                        color="default"
                                        elevation={0}
                                        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
                                        >
                                        <Toolbar>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item style={{width: 200}}>
                                                <h5>Lista de Clientes</h5>
                                                {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
                                                    <Select
                                                    fullWidth
                                                    size="small"
                                                    value={search && search}
                                                    onChange={(e)=> {setSearch(e.target.value)}}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        <MenuItem value={"name"}>Nome</MenuItem>
                                                    </Select>
                                                </FormControl> */}
                                                </Grid>
                                                <Grid item xs>
                                                    {/* <TextField
                                                        fullWidth
                                                        placeholder="Pesquisar por nome"
                                                        onChange={(e)=> setInputSearch(e.target.value)}
                                                        InputProps={{
                                                        disableUnderline: true,
                                                        sx: { fontSize: '100' },
                                                        }}
                                                        variant="standard"
                                                    /> */}
                                                </Grid>
                                                <Grid item>
                                                    {/* <Button variant="outlined" onClick={(e) => {searchCustomer(inputSearch)}} startIcon={<SearchIcon />}>
                                                        Pesquisar
                                                    </Button> */}
                                                    {/* <SearchIcon color="inherit" sx={{ display: 'block' }} /> */}
                                                </Grid>
                                                <Grid item>
                                                        {
                                                            permission?.isinsert ? 
                                                            <Link to={"/customer"}>
                                                                <Button style={{background: 'black', color: '#FFF'}} sx={{ mr: 1 }}>
                                                                    <AddIcon /> Novo
                                                                </Button>
                                                            </Link>
                                                            : 
                                                            ""
                                                        }
                                                </Grid>
                                            </Grid>
                                        </Toolbar>
                                    </AppBar>

                                    <div style={{ width: '100%', padding: 10 }}>
                                        <TableCustomer data={listCustomer}/>
                                        {/* <TableCustomer key={listCustomer.length} list={listCustomer}/> */}
                                    </div>
                            </Paper>
                        </div>
                    </div>
                }/>
            </Container>
        </>
    );
}


export default ListCustomer;