import React from "react";
import { createContext, useEffect, useState } from "react"
import { apiClient, createSession } from "../service/api";


export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {

    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const userToken = localStorage.getItem("user_token");

        if(userToken){
          setUser(JSON.parse(userToken));
          apiClient.defaults.headers.Authorization = `Bearer ${userToken.token}`;
        }

        setLoading(false)
        
    }, []);


    const signin = async (data) => {
      
      const response = await createSession(data);
      var user = response.data;
      var token = user.token;

      localStorage.setItem("user_token", JSON.stringify(user));

      apiClient.defaults.headers.Authorization = `Bearer ${token}`;

      setUser(user);

    };
    

    // const signup = (login, password) => {
    //     const usersStorage = JSON.parse(localStorage.getItem("users_bd"));
    
    //     const hasUser = usersStorage?.filter((user) => user.login === login);
    
    //     if (hasUser?.length) {
    //       return "Já tem uma conta com esse E-mail";
    //     }
    
    //     let newUser;
    
    //     if (usersStorage) {
    //       newUser = [...usersStorage, { login, password }];
    //     } else {
    //       newUser = [{ login, password }];
    //     }
    
    //     localStorage.setItem("users_bd", JSON.stringify(newUser));
    
    //     return;
    //   };

      const signout = () => {
        setUser(null);
        localStorage.removeItem("user_token");
      };

      return (
        <AuthContext.Provider
          value={{ user, signed: !!user, loading, signin, signout }}
        >
          {children}
        </AuthContext.Provider>
      );

};



