import React, { useEffect, useState } from "react";
import { Container, Typography, Paper, Card, FormControl, MenuItem, InputLabel, Select, Autocomplete, TextField, ToggleButtonGroup, ToggleButton, InputAdornment } from '@mui/material';
import ResponsiveDialog from "../../component/dialog/dialog";
import panelService from "../../service/servicePanel";
import serviceService from "../../service/serviceService";
import SearchIcon from '@mui/icons-material/Search';
import DateRangePicker from "../../component/datapicker/data-ranger-picker";
import availabilityService from "../../service/serviceAvailable";
import ImgMediaCard from "../../component/card-visible/card-visible";
import { Button, Col, Divider, Layout, Row, Statistic, Tooltip } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, DislikeOutlined, PlusOutlined } from "@ant-design/icons";
import { Content, Footer, Header } from "antd/es/layout/layout";
import SelectedTag from "../../component/select-tag/selecttag";
import dayjs from "dayjs";
import moment from "moment";
import MyComponent from "../../component/input-mask/input-mask";
import { useNavigate } from "react-router-dom";
import DrawerPrincipal from "../../component/drawer/drawer-principal";


function NewAvailability(){

    const navigate = useNavigate();

    const [service, setService] = useState("");
    const [panel, setPanel] = useState([]);

    const [listPanel, setListPanel] = useState([]);
    const [listService, setListService] = useState([]);

    const [listAvailable, setListAvailable] = useState([]);

    const [dtIni, setDtIni] = useState();
    const [dtFim, setDtFim] = useState();
    const [days, setDays] = useState(0);
    const [vlService, setVlService] = useState(0);
    const [qtdInsercao, setQtdInsercao] = useState();
    const [valueDesconto, setValueDesconto] = useState(0);

    const [totalItens, setTotalItens] = useState("0,00");
    const [itensDaVenda, setItensDaVenda] = useState("0,00");
    const [desconto, setDesconto] = useState("0,00");
    const [total, setTotal] = useState("0,00");


    const [openDialog, setOpenDialog] = useState();
    const [messageDialog, setMessageDialog] = useState();
    const [titleDialog, setTitleDialog] = useState();

    const [listInserts, setListInserts] = useState([]);


    const [typeCalc, setTypeCalc] = useState('value');


    const config = true ? {style: 'currency', currency: 'BRL'} : {minimumFractionDigits: 2};


    useEffect(() => {

        (async ()=> {
            await panelService.listActive().then(item => {
                var dadoPanel = [];

                item.forEach(element => {
                    dadoPanel.push(element)    
                });

                setListPanel(dadoPanel);
            });


            await serviceService.listActive().then(item => {
                var dadoService = [];
                item.forEach(element=> {
                    dadoService.push(element);
                });

                setListService(dadoService);
            })


            var dadoService = [];
            dadoService.push()

            var listQtdInserts = [];
            serviceService.listQtdInsert().then(item => {
    
                item.forEach(element => {
                    listQtdInserts.push(element);
                });
    
                setListInserts(listQtdInserts)
                
            }).catch(error => {
                console.log(error)
            });

        })()

    },[]);


    const handleDialogClose = () => {
        setOpenDialog(false);
    };


    const calculateDay = (dtStart, dtEnd) => {

         //Calculando a diferença entre as duas datas somando mais 1 para dar o dia cheio
         var diff = dayjs(dtEnd, 'DD/MM/YYYY').diff(dayjs(dtStart, 'DD/MM/YYYY'));
         var diffDays = (moment.duration(diff).asDays() + 1);
 
         setDays(diffDays);

        // console.log(diffDays)

        //  calculateTotal(qtdInsercao, diffDays, vlService)
    }

    const handleChangeDays = (dates, dateStrings) => {
        // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);

        setDtIni(dateStrings[0]);
        setDtFim(dateStrings[1]);

        calculateDay(dateStrings[0], dateStrings[1]);        
    }

    const handleChangePanel = (value) => {
        // console.log(`selected w ${value}`);

        setPanel(value)
    } 

    const handleChangeService = (event) => {
        // console.log(event)
        setVlService(event.vlSale)
        setService(event)
        // calculateTotal(qtdInsercao, days, event.vlSale)
    }

    const handleChangeQtdService = (index, qtdInsert) => {
        // console.log(qtdInsert)
        setQtdInsercao(qtdInsert)
        // calculateTotal(qtdInsert, days, vlService)
    }

    const totalComDesconto = (desconto, type) => {

        console.log(type)
        var total = (parseFloat(vlService) * parseInt(qtdInsercao) * parseInt(days)) ;

        var filtrado = panelFields.filter((item) => item.isChecked == true);

        // var totalGeral = (parseFloat(total) * filtrado.length) - parseFloat(desconto);
        var totalGeral = (parseFloat(total) * filtrado.length);
        
        if(type == 'percent') {
            totalGeral = totalGeral - (parseFloat(totalGeral) * parseFloat(desconto) / 100);
        }else if(type == 'value'){
            totalGeral = totalGeral - parseFloat(desconto);
        }


        setTotal(totalGeral)
    }
    
    const calculateDiscount = (total, discount, isChecked) => {


        var totalGeral = 0;

        if(isChecked){
            totalGeral = total - parseFloat(discount);
        }else{
            totalGeral = total + parseFloat(discount);
        }

        if(typeCalc == 'percent') {
            totalGeral = (totalGeral * parseFloat(desconto) / 100)
        }else{
            totalGeral = totalGeral - parseFloat(desconto);
        }

    }

    const calculateChecked = (isChecked, vlService) => {
        var totalGeral = 0;
        var valorCalc = 0;

        if(isChecked){
            valorCalc = ((parseFloat(vlService) * parseInt(qtdInsercao) * parseInt(days)));
            totalGeral = (parseFloat(total) + valorCalc)
        }else{
            valorCalc = ((parseFloat(vlService) * parseInt(qtdInsercao) * parseInt(days)));
            totalGeral = (parseFloat(total) - valorCalc)
        }

        var vlTotal = calculateDiscount(totalGeral, desconto, isChecked);

        setTotal(totalGeral);
    }


    const calculateTotal = async (item, qtdInsert, qtdDays, vlService) => {

        var total = (parseFloat(vlService) * parseInt(qtdInsert) * parseInt(qtdDays)) ;
        
        var filtrado = item.filter((item) => item.isChecked == true);

        var geral = (total * filtrado.length) - parseFloat(desconto)

        setTotal(geral)
        setItensDaVenda(geral)

    }

    const handleFind = async () => {

        var listPanel = [];

        await availabilityService.findPanels(panel, dtIni, dtFim, qtdInsercao).then(item => {
            item.forEach(disp => {
                disp.isChecked = true;
                listPanel.push(disp);
            });

        });

        calculateTotal(listPanel, qtdInsercao, days, vlService);
        
        setPanelFields(listPanel);


    }
    

    const onChangeCheckbox = (event, index, item) => {

        console.log(item)

        let data = [...panelFields];

        data[index]['isChecked'] = event;

        setPanelFields(data);

        calculateChecked(event, vlService);

    }

    const preperData = () => {
        let data = [...panelFields];

        var list = [];

        data.forEach(item=> {
            if(item.isChecked){
                list.push(item);
            }

        })

        var prepareData = { 
            list: list,
            qtd_insercao: qtdInsercao,
            days: days,
            dtIni: dtIni,
            dtEnd: dtFim,
            service: service,
            discount: desconto
        }
        
        console.log("Preparando para passar")
        // console.log(list)
        // Navegar para a próxima tela passando os parâmetros
        navigate('/saleParams/', { state: {prepareData} });


        return list;

    }


    const [address, setAddress] = useState({
        address: '',
        cep: '',
        city: '',
        complement: '',
        country: '',
        idAddress: '',
        neighborhood: '',
        number: '',
        state: ''
    })

    const [panelFields, setPanelFields] = useState([
        {
            idcustomer: '',
            idpanel: '',
            isActive: false, 
            name: '',
            qtdInsercao: '',
            type: '',
            dtinsert: '',
            dtupdate: '',
            isChecked: false,
            address: address
        }
    ]);



    const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            },
        },
    };



    const headerStyle = {
        textAlign: 'center',
        paddingTop: 10,
        height: 94,
        paddingInline: 50,
        lineHeight: '64px',
        backgroundColor: '#FFF'
      };

      const contentStyle = {
        textAlign: 'center',
        minHeight: 120,
        lineHeight: '120px',
        color: '#fff',
        backgroundColor: '#FFF'
      };

      const footerStyle = {
        textAlign: 'center',
        backgroundColor: '#FFF',
      };

    return(

    <>

    <Container maxWidth="lx" sx={{ height: '100vh' }}>
        
        <DrawerPrincipal divOpen={
            <div>
                <div>

                <ResponsiveDialog 
                    open={openDialog} 
                    handleClose={handleDialogClose}
                    title={titleDialog}
                    message={messageDialog} 
                    />

                    {/* <div className="row">
                        <h1 className="h3 mb-3 text-black font-weight-bold"> Disponibilidade </h1>
                    </div> */}

                    <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
                        <div className="container">
                            <div className="row">     
                                <div className="col-12">
                                    {/* <Card> */}
                                        <div className="form-cadastro" style={{padding: 5}}>
                                            <Typography sx={{ width: 14 }} className="mb-3" gutterBottom variant="h7" component="div">
                                                
                                            </Typography>

                                            <div className="row">
                                                <div className="col-md-3 mb-3">


                                                    <FormControl fullWidth>
                                                        <SelectedTag data={listPanel} onChange={handleChangePanel}/>
                                                    </FormControl>


                                                </div>

                                                <div className="col-md-3 mb-3">

                                                    <FormControl fullWidth size="large">
                                                        <DateRangePicker handleChange={handleChangeDays}/>
                                                    </FormControl>
                                                </div>
                                                <div className="col-md-2 mb-3">

                                                    <FormControl fullWidth size="small">
                                                        <InputLabel id="demo-select-small">Serviço</InputLabel>
                                                        <Select
                                                            labelId="demo-select-small"
                                                            id="demo-select-small"
                                                            value={service && service}
                                                            focused={service && service}
                                                            label="Serviço"
                                                                onChange={(e) => 
                                                                    handleChangeService(e.target.value)
                                                                }
                                                            >
                                                            {
                                                                listService.map(item=>{
                                                                    return (
                                                                        <MenuItem value={item}>{item.name}</MenuItem>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div className="col-md-2">
                                                        <Autocomplete 
                                                            disablePortal 
                                                            size='small' 
                                                            id="combo-box-demo" 
                                                            name="qtdInsert" 
                                                            options={listInserts}
                                                            sx={{ width: '100%' }}
                                                            getOptionLabel={(listInserts) => listInserts ? `${listInserts}` : ""}
                                                            isOptionEqualToValue={(option, value) => option === value}
                                                            onChange={(event, newValue) => {
                                                                handleChangeQtdService(0, newValue);
                                                            }}
                                                            noOptionsText={"Nenhum inserção cadastrada no momento com esse valor"}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                {...params}
                                                                label="Qtd inserção"
                                                                />
                                                            )}
                                                        />
                                                        
                                                </div>
                                                <div className="col-md-2">
                                                    <Button variant="contained" size="large" onClick={handleFind} lg={{ mr: 1 }} 
                                                        style={{backgroundColor: '#000', color: '#FFF'}}>
                                                        <SearchIcon /> Pesquisar
                                                    </Button>
                                                </div>


                                            </div>

                                            <div className="row">
                                                <div className="col-md-3">
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                </div>
                                                <div className="col-md-2">
                                                </div>
                                            </div>
                                        </div>
                                    {/* </Card>      */}

                                    <div style={{padding: 5}}>
                                    </div>


                                    <Layout>
                                        <Header style={headerStyle}>
                                            
                                            <Row>
                                                <Col xs={{span: 5, offset: 1 }} lg={{ span: 5, offset: 2, }} >

                                                        <Card bordered={true}>
                                                            <Statistic
                                                                title="Total Painéis"
                                                                value={panelFields.filter((item) => item.idpanel > 0).length}
                                                                precision={0}
                                                                valueStyle={{
                                                                    color: '#3f8600',
                                                                    paddingTop: 5,
                                                                    height: 50
                                                                }}
                                                                prefix={<ArrowUpOutlined />}
                                                            // suffix="%"
                                                            />
                                                        </Card>
                                                </Col>
                                                <Col xs={{ span: 11, offset: 1, }} lg={{ span: 6, offset: 2, }} >

                                                        <Card bordered={true}>
                                                            <Statistic
                                                                title="Selecionados"
                                                                value={panelFields.filter((item) => item.isChecked == true).length}
                                                                precision={2}
                                                                valueStyle={{
                                                                    color: '#D9D919',
                                                                    paddingTop: 5,
                                                                    height: 50
                                                                }}
                                                                prefix={<ArrowUpOutlined />}
                                                            // suffix="%"
                                                            />
                                                        </Card>
                                                </Col>
                                                <Col xs={{span: 5, offset: 1, }} lg={{span: 6, offset: 2,}}>
                                                    <Card bordered={true}>
                                                        <Statistic
                                                            title="Indisponíveis"
                                                            value={panelFields.filter((item) => item.idpanel > 0 && item.isActive == false).length}
                                                            precision={2}
                                                            valueStyle={{
                                                                color: '#cf1322',
                                                                paddingTop: 5,
                                                                height: 50
                                                            }}
                                                            prefix={<ArrowDownOutlined />}
                                                        // suffix="%"
                                                        />
                                                    </Card>
                                                </Col>
                                            </Row>


                                        </Header>
                                        <Content style={contentStyle}>
                                            <Divider/>
                                                <div className="row">
                                                {
                                                    panelFields.map((item, index) => {
                                                        if(item.idpanel){
                                                            return(
                                                                <>
                                                                    <ImgMediaCard 
                                                                        item={item} 
                                                                        isChecked={item.isChecked} 
                                                                        days={days} 
                                                                        qtdInsercao={qtdInsercao}
                                                                        vlService={vlService} 
                                                                        onChange={(e) => {onChangeCheckbox(e, index, item)}}/>
                                                                </>
                                                            )
                                                        }
                                                    })
                                                }
                                                </div>
                                            <Divider/>
                                        </Content>
                                        <Footer style={footerStyle}>

                                            <div className="row">
                                                <div className="row mt-3">
                                                    <div className="col-md-1">
                                                            <ToggleButtonGroup
                                                                value={typeCalc}
                                                                exclusive
                                                                onChange={(e)=> {
                                                                    setTypeCalc(e.target.value)
                                                                    totalComDesconto(desconto, e.target.value);
                                                                }}
                                                                size="small"
                                                                color="primary"
                                                                aria-label="text alignment"
                                                                >
                                                                    <ToggleButton value="value" aria-label="left aligned">
                                                                        R$
                                                                    </ToggleButton>
                                                                    <ToggleButton value="percent" aria-label="centered">
                                                                        %
                                                                    </ToggleButton>
                                                            </ToggleButtonGroup>
                                                            
                                                    </div>
                                                    <div className="col-2">
                                                        {
                                                            typeCalc == 'value'? 
                                                            <MyComponent  
                                                                handleInput={(e)=> {
                                                                    setDesconto(e);
                                                                    totalComDesconto(e, typeCalc);
                                                                }}
                                                                value={desconto && desconto}
                                                                labelInput="Desconto"/>
                                                            :
                                                            <TextField
                                                                label="Desconto"
                                                                id="Desconto"
                                                                size="small"
                                                                value={desconto && desconto}
                                                                onChange={(e)=> {
                                                                    setDesconto(e.target.value);
                                                                    totalComDesconto(e.target.value, typeCalc);
                                                                }} 
                                                                InputProps={{ 
                                                                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                                }}
                                                            />
                                                        }
                                                    </div>
                                                    
                                                    <div className="col-1">
                                                    </div>
                                                     
                                                    <div className="col-md-2 ">
                                                        <p style={{fontSize: '13px'}}>Itens da venda  <p style={{color: 'blue'}}>
                                                            {
                                                                itensDaVenda.toLocaleString('pt-BR', config)
                                                            } 
                                                            </p></p> 
                                                    </div> 
                                                    <div className="col-md-2">
                                                        <p style={{fontSize: '13px'}}> Desconto  <p style={{color: 'red'}}>{

                                                        typeCalc == 'value' ? 
                                                            desconto.toLocaleString('pt-BR', config)
                                                            :
                                                            desconto.toLocaleString('pt-BR', config) + '%'
                                                        } 
                                                        </p></p> 
                                                    </div> 
                                                    <div className="col-md-1">
                                                        <p style={{fontSize: '13px'}}> Total
                                                            <p style={{color: 'green'}} id="totalGeral">
                                                                {total.toLocaleString('pt-BR', config)} 
                                                            </p>
                                                        </p>
                                                    </div> 
                                                    <div className="col-md-2">
                                                        <Tooltip title="Criar venda">

                                                            <Button
                                                                onClick={preperData} 
                                                                type="primary" 
                                                                icon={<PlusOutlined />}
                                                                size="middle"
                                                                >
                                                                Criar venda
                                                            </Button>

                                                        </Tooltip>
                                                    </div>

                                                </div>
                                            </div>

                                        </Footer>
                                    </Layout>

                                    

                                </div>  {/** Fecha segundo lado */}

                            </div> 
                        </div>

                    </Paper>
                </div>
            </div>
        }/>
    </Container>

    </>

    )

}
export default NewAvailability;




// alter table customer add column is_active boolean default false

// alter table panel add column is_active boolean default false

// alter table service add column is_active boolean default false

// alter table circuit add column is_active boolean default false



// select
// 	generate_series('2023-03-13'::date, '2023-03-14'::date, '1 day'::interval) AS dia,
// 	pl.qtdinsercao quantidadeDiaria, 
// 	it.qtd_inserts qtdUtilizada,
// 	it.qtd_inserts - pl.qtdinsercao disponivel,
// 	*
// 	from items_sale it 
// 		left join panel pl on (it.idpanel = pl.idpanel)
// 	where '2023-03-14'::date <= dt_end AND '2023-03-13'::date >= dt_start;
	


// select * from customer where name ilike '%OMN%'



// 00394460005887


// select qtdinsercao,* from panel 

// select * from qtd_inserts 

// select
// 	generate_series('2023-03-13'::date, '2023-03-17'::date, '1 day'::interval) AS dia,
// 	pl.qtdinsercao quantidadeDiaria, 
// 	it.qtd_inserts qtdUtilizada,
// 	it.qtd_inserts - pl.qtdinsercao disponivel,
// 	*
// 	from items_sale it 
// 		left join panel pl on (it.idpanel = pl.idpanel)
// 	where '2023-03-13'::date <= dt_end AND '2023-03-17'::date >= dt_start;
	





// select qtdinsert from service 
	
// 	where dt_start >= '2023-03-14' and dt_start <= '2023-03-14' 
// 	  and dt_end >= '2023-05-17' and dt_start <= '2023-05-17' 


// select * from items_sale 



