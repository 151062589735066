import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, Result } from 'antd';

export default function ResponsiveDialog({open, handleClose, title, message, status}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <div>

      <Dialog
          maxWidth={"sm"}
          fullWidth={true}        
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          aria-describedby='resposive-dialog-describe'
        >
          <Result
              status={status}
              title={title}
              subTitle={message}
              extra={[
                <Button size='large' style={{background: '#000', color: '#FFF', width: '50%' }}
                  onClick={handleClose} autoFocus
                  >OK, entendi
                </Button>
              ]}
            />


        </Dialog>

      {/* <Dialog
        maxWidth={"xs"}
        fullWidth={true}        
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        aria-describedby='resposive-dialog-describe'
      >

        <DialogContent align={"center"} style={{marginRight: 30, marginBottom: 30, marginLeft: 30, alignItems: "center"}}>
            <DialogTitle id="responsive-dialog-title"  >
                <p>
                  {
                    status == 'success' ? 
                    <CheckCircleOutlineIcon color='success' style={{fontSize: 100}}/>
                    : status == 'error' ? 
                      <ErrorOutlineIcon color='error' style={{fontSize: 100}} />
                        : <WarningAmberIcon color='warning' style={{fontSize: 100}} />
                  }
                </p>

                <Typography variant="h3" gutterBottom>
                  {title}
                </Typography>
            </DialogTitle>

            <DialogContentText id="alert" align="center" style={{color: "#6F89A4"}} className="mt-2" >
                {message}
            </DialogContentText>
        </DialogContent>
        
        <Button variant="contained" 
        style={{ height: 40, marginRight: 30, marginBottom: 30, marginLeft: 30, alignItems: "center"}} 
        onClick={handleClose} autoFocus
        >OK, entendi</Button>

      </Dialog> */}

    </div>
  );
}