import { DeleteTwoTone, EditOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import React, { useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Button, Input, Space, Table } from 'antd';
import { Link } from 'react-router-dom';
import ResponsiveDialog from '../dialog/dialog';
import { useNavigate } from 'react-router-dom';
import panelService from '../../service/servicePanel';

const TableService = ({data}) => {

  const usuario = JSON.parse(localStorage.getItem('user_token'));
  const permission = usuario.roles.filter(item=>item.name === "Painel")[0];

  let navigate = useNavigate();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const [openDialog, setOpenDialog] = useState();
  const [messageDialog, setMessageDialog] = useState();
  const [titleDialog, setTitleDialog] = useState();
  const [statusDialog, setStatusDialog] = useState();


  function refreshPage() {
    window.location.reload(false);
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Pesquisar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Procurar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Limpar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Fechar
          </Button>
        </Space>
      </div>
    ),

    filterIcon: (filtered) => (

      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />

    ),

    onFilter: (value, record) =>{

      if(typeof value === 'string'){
        return record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase())
      }else{
        return record[dataIndex] === value;
      }

    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });



  const deletePanel = (id) => {

    panelService.delete(id).then(item=> {
      setOpenDialog(true);
      setStatusDialog('success')
      setTitleDialog("Muito bem!")
      setMessageDialog("Painel deletado com sucesso")

    }).catch(error=>{
        setOpenDialog(true);
        setStatusDialog('error')
        setTitleDialog("Ops!")
        setMessageDialog("Erro ao excluir o painel " + id + ", verifique se o mesmo encontra-se inserido em alguma Venda. ")
    })

  }


  const columns = [
    {
        title: 'Código',
        dataIndex: 'idpanel',
        width: '13%',
        key: 'idpanel',
        // ...getColumnSearchProps('idcustomer'),
        sorter: (a, b) => a.idpanel - b.idpanel,
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
        width: '55%',
        ...getColumnSearchProps('name'),
        sorter: (a, b) => a.name?.length - b.name?.length,
        sortDirections: ['descend', 'ascend'],
    },
    
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      width: '55%',
      ...getColumnSearchProps('type'),
      sorter: (a, b) => a.type?.length - b.type?.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Qtd Inserção',
      dataIndex: 'qtdInsercao',
      width: '13%',
      key: 'qtdInsercao',
      // ...getColumnSearchProps('idcustomer'),
      sorter: (a, b) => a.qtdInsercao - b.qtdInsercao,
      sortDirections: ['descend', 'ascend'],
    },

    {
        title: 'Ações',
        // dataIndex: 'data',
        key: 'action',
            render: (_, record) => (

              
                <Space size="middle">
               
                  <Link to={'/panel/' + record.idpanel}>
                      { permission?.isinsert ? <EditOutlined style={{
                                    fontSize: '20px',
                                    color: '#4169E1'
                                  }} />
                                   :
                                   <EyeOutlined style={{
                                    fontSize: '20px',
                                    color: '#4169E1'
                                  }}  />}
                  </Link>
                  {
                      permission?.isremove ?
                          <Link to={'#'}>
                               <DeleteTwoTone 
                                  twoToneColor="#DC143C"
                                  style={{
                                    fontSize: '20px',
                                  }}  
                                  onClick={(e) => deletePanel(record.idpanel)} 
                                  />
                          </Link>
                      : 
                      ""
                  }
                </Space>
            ),
    },
  ];

  const handleDialogClose = () => {
    setOpenDialog(false);
    navigate("/listPanel");
    refreshPage();
  }

  return (

    <>

      <ResponsiveDialog 
            open={openDialog} 
            handleClose={handleDialogClose}
            title={titleDialog}
            message={messageDialog} 
            status={statusDialog}
      />

      <Table
        columns={columns} 
        dataSource={data} />
    </>
     
  )
  

};

export default TableService;
