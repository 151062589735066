import { apiClient } from "./api";


const circuitService = {


    async list(){

        var retorno = await apiClient.get('/circuit');

        return retorno.data;
    },

    async listActive(){

        var retorno = await apiClient.get('/circuit/active');

        return retorno.data;
    },

    async listFilter(id){
        console.log(id)

        var retorno = await apiClient.get('/circuit/filter/'+id);

        return retorno.data;
    },


    async edit(id){

        var retorno = await apiClient.get("/circuit/"+id);
        console.log(retorno.data)
        return retorno.data;
    },

    async save(customer){
        console.log(customer)

        var retorno = await apiClient.post("/circuit", customer);

        return retorno;

    },

    async update(circuit){
        console.log(circuit)

        var retorno = await apiClient.put("/circuit", circuit);

        return retorno;

    },

    async delete(idcircuit){
        console.log(idcircuit)

        var retorno = await apiClient.delete("/circuit/"+idcircuit);

        return retorno;

    }

}

export default circuitService;