import React, { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import { Button, CardContent, Container, Typography, Paper, Card, FormControl, InputLabel, Select, MenuItem, FormHelperText, InputAdornment, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from "react-router-dom";
import ResponsiveDialog from "../../component/dialog/dialog";
import customerService from "../../service/serviceCustomer";
import InputMask from 'react-input-mask'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CustomizedBreadcrumbs from "../../component/breadcrumbs/breadcrumbs";
import DrawerPrincipal from "../../component/drawer/drawer-principal";

function NewCustomer(){

    const usuario = JSON.parse(localStorage.getItem('user_token'));
    const permission = usuario.roles.filter(item=>item.name === "Cliente")[0];


    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");

    const [nameFantasy, setNameFantasy] = useState("")

    const [typePerson, setTypePerson] = useState("Pessoa Jurídica");
    const [typePersonError, setTypePersonError] = useState();

    const [cpfCnpj, setCpfCnpj] = useState("");
    const [cpfCnpjError, setCpfCnpjError] = useState();

    
    const [typeClient, setTypeClient] = useState("Agencia");
    const [typeClientError, setTypeClientError] = useState("");

    const [observation, setObservation] = useState("");
    const [observationError, setObservationError] = useState("");

    const [commission, setCommission] = useState(0);

    const [user, setUser] = useState("");
    const [idContact, setIdContact] = useState();

    const [email, setEmail] = useState("");

    const [phone, setPhone] = useState("");

    const [nameContact, setNameContact] = useState("");

    const [idAddress, setIdAddress] = useState();
    
    const [cep, setCep] = useState("");
    const [cepError, setCepError] = useState("");

    const [address, setAddress] = useState("");
    const [addressError, setAddressError] = useState("");

    const [number, setNumber] = useState();
    const [numberError, setNumberError] = useState();

    const [neighborhood, setNeighborhood] = useState("");
    const [neighborhoodError, setNeighborhoodError] = useState("");

    const [city, setCity] = useState("");
    const [cityError, setCityError] = useState("");

    const [complement, setComplement] = useState("");

    const [state, setState] = useState("");
    const [stateError, setStateError] = useState("");

    const [country, setCountry] = useState("Brasil");
    const [countryError, setCountryError] = useState("");

    const [isActive, setIsActive] = useState(true);

    const [openDialog, setOpenDialog] = useState();
    const [messageDialog, setMessageDialog] = useState();
    const [titleDialog, setTitleDialog] = useState();
    const [statusDialog, setStatusDialog] = useState();


    const {id} = useParams();

    let navigate = useNavigate();

    useEffect(() => {

        if(id)  {
            customerService.editCustomer(id)
            .then(item => {

                setName(item?.name)
                setNameFantasy(item?.nameFantasy)
                setTypePerson(item?.typePerson)
                setTypeClient(item?.typeClient)
                setCpfCnpj(item?.cpfCnpj)
                setObservation(item?.observation)

                setIsActive(item?.isActive)

                setCommission(item?.commission)

                setUser(item?.user)

                setIdContact(item?.contact?.idContact)
                setNameContact(item?.contact?.name)
                setEmail(item?.contact?.email)
                setPhone(item?.contact?.phone)

                setIdAddress(item?.address?.idAddress)
                setAddress(item?.address?.address)
                setNumber(item?.address?.number)
                setNeighborhood(item?.address?.neighborhood)
                setCity(item?.address?.city)
                setComplement(item?.address?.complement)
                setState(item?.address?.state)
                setCountry(item?.address?.country)
                setCep(item?.address?.cep)

            }).catch(error => {
                console.log(error)
            });
        }

    },[id]);

    function save(){

        if(valid()) {


            var contact = {
                name: nameContact,
                phone: phone.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""),
                email: email
            }

            var addressData = {
                cep: cep.replace("-",""),
                address: address,
                number: number,
                neighborhood: neighborhood,
                city: city,
                complement: complement,
                state: state,
                country: country
            }

            var data = {
                name: name,
                nameFantasy: nameFantasy,
                typeClient: typeClient,
                typePerson: typePerson,
                cpfCnpj: cpfCnpj,
                commission: commission,
                observation: observation,
                isActive: isActive,
                user: user,
                contact: contact,
                address: addressData
            }


            console.log(data)
    
            customerService.save(data).then(item => {
                
                if(item.status === 201){
                    setOpenDialog(true);
                    setStatusDialog('success')
                    setTitleDialog("Muito bem!")
                    setMessageDialog("Cliente cadastrado com sucesso")
                }
                
            }).catch(error => {
                handleDialogOpen()
                setTitleDialog("Ops")
                setMessageDialog(error.response.data)
            });
        }



    }


    function update(){

        if(valid()) {

            var contact = {
                idContact: idContact,
                name: nameContact,
                phone: phone.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""),
                email: email
            }

            var addressData = {
                idAddress: idAddress,
                cep: cep,
                address: address,
                number: number,
                neighborhood: neighborhood,
                city: city,
                complement: complement,
                state: state,
                country: country
            }

            var data = {
                idcustomer: id,
                name: name,
                nameFantasy: nameFantasy,
                typeClient: typeClient,
                typePerson: typePerson,
                cpfCnpj: cpfCnpj,
                commission: commission,
                observation: observation,
                isActive: isActive,
                user: user,
                contact: contact,
                address: addressData
            }

            console.log(data)

            customerService.update(data).then(item => {

                if(item.status === 200){
                    setOpenDialog(true);
                    setStatusDialog('success')
                    setTitleDialog("Muito bem!")
                    setMessageDialog("Cliente atualizado com sucesso")
                }
                
    
            }).catch(error => {
                setOpenDialog(true);
                setStatusDialog('error')
                setTitleDialog("Ops")
                setMessageDialog(error.response.data)
            });
        }

    }

    function valid(){

        let message = "Este campo é obrigatório";
        let thatsOk = true;


        if(!name){
            setNameError(message)
            thatsOk = false;
        }
        if(!typeClient){
            setTypeClientError(message)
            thatsOk = false;
        }
        
        if(!cpfCnpj && typePerson != 'Estrangeiro'){
            setCpfCnpjError(message)
            thatsOk = false;
        }
        if(!typePerson){
            setTypePersonError(message)
            thatsOk = false;
        }

        if(!cep){
            setCepError(message)
            thatsOk = false;
        }
        if(!address){
            setAddressError(message);
            thatsOk = false;
        }
        if(!number){
            setNumberError(message);
            thatsOk = false;
        }
        if(!neighborhood){
            setNeighborhoodError(message);
            thatsOk = false;
        }
        if(!city){
            setCityError(message)
            thatsOk = false;
        }
        if(!state){
            setStateError(message)
            thatsOk = false;
        }
        if(!country){
            setCountryError(message)
            thatsOk = false;
        }

        return thatsOk;

    }


    const handleDialogOpen = () => {
        setOpenDialog(true);
      };
    
    const handleDialogClose = () => {
        setOpenDialog(false);
        navigate("/listCustomer");
      };

    
    const showJuridica = (typePessoa) => {

        if(typePessoa == "Pessoa Jurídica" || typePessoa == "Estrangeiro"){
            return  <TextField
                    fullWidth
                    focused={nameFantasy && nameFantasy}
                    label="Nome Fantasia"
                    id="outlined-size-small"
                    size="small"
                    value={nameFantasy && nameFantasy}
                    onChange={(e)=> setNameFantasy(e.target.value)}

                />
        }

        return;

    }

    const handleCheckd = (value) => {
        setIsActive(value)

    }


    const showCpfCnpj = (typePessoa) => {

        if(typePessoa == "Pessoa Jurídica"){
           
            return(
                <>
                    <InputMask mask="99.999.999/9999-99" 
                            fullWidth
                            className="form-control" 
                            focused={cpfCnpj && cpfCnpj}
                            maskChar={""}
                            onChange={(e)=> {
                                buscarCNPJ(e.target.value);
                                setCpfCnpj(e.target.value);
                                setCpfCnpjError("")
                            }} 
                            value={cpfCnpj && cpfCnpj} 
                            placeholder="CNPJ"
                            required={cpfCnpj && cpfCnpj} 
                            style={{borderColor: (cpfCnpjError ? "#dc3545" : "")}}
                            
                        />
                            <span style={{fontSize: 13, color: '#dc3545'}}>{cpfCnpjError ? cpfCnpjError : ""}</span>
                </>
            )  
             
        } else  if(typePessoa == "Estrangeiro"){
            return (
                <>
                    <InputMask  
                        fullWidth
                        maskChar={""}
                        className="form-control" 
                        focused={cpfCnpj && cpfCnpj}
                        onChange={(e)=> {
                            setCpfCnpj(e.target.value);  
                            setCpfCnpjError("")                                                                  
                        }} 
                        disabled={true}
                        value={cpfCnpj && cpfCnpj} 
                        placeholder="xx.xxx.xxx.xx"
                        style={{borderColor: (cpfCnpjError ? "#dc3545" : "")}}
                    />
                
                </>
            )
        } else{

            return (
                <>
                    <InputMask mask="999.999.999-99" 
                        fullWidth
                        maskChar={""}
                        className="form-control" 
                        focused={cpfCnpj && cpfCnpj}
                        onChange={(e)=> {
                            setCpfCnpj(e.target.value);  
                            setCpfCnpjError("")                                                                  
                        }} 
                        value={cpfCnpj && cpfCnpj} 
                        placeholder="CPF"
                        style={{borderColor: (cpfCnpjError ? "#dc3545" : "")}}
                    />
                    <span style={{fontSize: 13, color: '#dc3545'}}>{cpfCnpjError ? cpfCnpjError : ""}</span>
                </>
            )
            

        }

    }


    //Função para consultar CEP
    function buscarCepClient(input) {
    
        if(input.length < 8) { 
            return;
        } else {
            
                // fetch('https://brasilapi.com.br/api/cep/v1/'+input, {mode: 'cors'})
                //Mudando api para busca de cep por que brasilapi passou a não funcionar
                fetch('https://viacep.com.br/ws/' +input + '/json/', {mode: 'cors'})
                .then((res) => res.json())
                .then((data) => {

                    if(data?.type == "service_error"){
                        setCepError("O " + cep + " não foi encontrado em nenhum serviço de consulta")
                        setAddress("")
                        setCity("")
                        setNeighborhood("")
                        setNumber("")
                        setState("")
                        return false;
                    }else
                    if (data.hasOwnProperty("erro")) {
                        setCepError("O " + cep + " não foi encontrado em nenhum serviço de consulta")
                        setAddress("")
                        setCity("")
                        setNeighborhood("")
                        setNumber("")
                        setState("")
                        return false;
                    } else {

                        console.log(data)

                        setAddress(data.logradouro)
                        setCity(data.localidade)
                        setNeighborhood(data.bairro)
                        setState(data.uf)

                        // setAddress(data.street)
                        // setCity(data.city)
                        // setNeighborhood(data.neighborhood)
                        // setState(data.state)

                        setAddressError("")
                        setCityError("")
                        setNeighborhoodError("")
                        setStateError("")

                    }
                })
                .catch(err => console.log(err));
        } 
    }

    function buscarCNPJ(input) { 
        
        if(input.length == 18){
            input = input.replace(".", "").replace(".", "").replace("/", "").replace("-", "");

            fetch('https://brasilapi.com.br/api/cnpj/v1/'+input, {mode: 'cors'})
            .then((res) => res.json())
            .then((data) => {
                
                if(data?.type == "bad_request" || data?.type == "not_found"){
                    setCpfCnpjError("CNPJ não encontrado na Receita ")
                    return false;
                }
                else {

                    setName(data?.razao_social)
                    setNameFantasy(data?.nome_fantasia)
                    setCpfCnpj(data?.cnpj)
                    setAddress(data?.logradouro)
                    setNumber(data?.numero)
                    setCity(data?.municipio)
                    setComplement(data?.complemento)
                    setNeighborhood(data.bairro)
                    setState(data?.uf)
                    setCep(data?.cep)

                    setNameError("")
                    setCpfCnpjError("")
                    setAddressError("")
                    setNumberError("")
                    setCityError("")
                    setNeighborhoodError("")
                    setStateError("")
                    setCepError("")
                }
            })
            .catch(err => alert(err));
        }
    }

    const cancel = () => {
        navigate("/listCustomer");
    }
    
    return(

    <>

        <Container maxWidth="lx"  sx={{ bgcolor: '#EBEBEB'}}>
            
            <DrawerPrincipal divOpen={
                <div>
                    <div>

                    <div className="row mb-3">
                        <CustomizedBreadcrumbs title1={"Home"} link1={"/"} 
                            title2={"Lista de Clientes"} link2={"#"} 
                            title3={"Cadastro de Cliente"} link3={"#"} />
                    </div>

                    <ResponsiveDialog 
                        open={openDialog} 
                        handleClose={handleDialogClose}
                        title={titleDialog}
                        message={messageDialog} 
                        status={statusDialog}
                        />

                        <div className="row">

                            <h1 className="h3 mb-3 text-black font-weight-bold" >Cadastro de cliente</h1>

                        </div>

                        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
                            <div className="container">
                                <div className="row">     
                                    <div className="col-12">
                                            <Card>
                                                <div className="form-cadastro" style={{padding: 20}}>
                                                <Typography sx={{ width: 100 }} className="mb-3" gutterBottom variant="h7" component="div">
                                                        Dados gerais
                                                    </Typography>

                                                    <div className="row">
                                                       

                                                        <div className="col-md-3 mb-3">

                                                            <FormControl fullWidth size="small" error={typePersonError && typePersonError}>
                                                                <InputLabel id="demo-select-small">Tipo Pessoa</InputLabel>
                                                                <Select
                                                                    labelId="demo-select-small"
                                                                    id="demo-select-small"
                                                                    value={typePerson && typePerson}
                                                                    focused={typePerson && typePerson}
                                                                    label="Tipo Pessoa"
                                                                    onChange={(e) => {
                                                                        setTypePerson(e.target.value);
                                                                        setTypePersonError("");
                                                                    }}
                                                                >
                                                                    <MenuItem value={"Pessoa Física"}>Pessoa Física</MenuItem>
                                                                    <MenuItem value={"Pessoa Jurídica"}>Pessoa Jurídica</MenuItem>
                                                                    <MenuItem value={"Estrangeiro"}>Estrangeiro</MenuItem>
                                                                </Select>
                                                                <FormHelperText >{typePersonError ? typePersonError : ""}</FormHelperText>
                                                            </FormControl>
                                                        </div>
                                                        <div className="col-md-3 mb-3">

                                                            {showCpfCnpj(typePerson)}

                                                        </div> 
                                                        <div className="col-md-6 mb-3">
                                                            

                                                        <TextField
                                                            fullWidth                                                          
                                                            label={name ? "Nome do cliente" : "Nome do cliente"}
                                                            id="outlined-size-small"
                                                            focused={name && name}
                                                            size="small"
                                                            value={name && name}
                                                            onChange={(e)=> {
                                                                setName(e.target.value);
                                                                setNameError("");
                                                            }} 
                                                            error={nameError && nameError}
                                                            helperText={nameError ? nameError : ""}
                                                            />
                                                      
                                                        </div> 
 
                                                    </div>

                                                    <div className="row">


                                                        <div className="col-md-6">
                                                            {showJuridica(typePerson)}
                                                        </div>

                                                        <div className="col-md-3 mb-3">

                                                            <FormControl fullWidth size="small" error={typeClientError && typeClientError}>
                                                                <InputLabel id="demo-select-small">Tipo Cliente</InputLabel>
                                                                <Select
                                                                    labelId="demo-select-small"
                                                                    id="demo-select-small"
                                                                    value={typeClient && typeClient}
                                                                    label="Tipo Cliente"
                                                                    onChange={(e) => setTypeClient(e.target.value)}
                                                                >
                                                                    <MenuItem value="Agencia">Agência</MenuItem>
                                                                    <MenuItem value="anunciante">Anunciante</MenuItem>
                                                                    <MenuItem value="broker">Broker</MenuItem>
                                                                    <MenuItem value="locatario">Locatário</MenuItem>
                                                                </Select>
                                                                <FormHelperText >{typeClientError ? typeClientError : ""}</FormHelperText>
                                                            </FormControl>
                                                        </div>

                                                        <div className="col-md-2 mb-3">

                                                            {/* <MyComponent 
                                                                handleInput={(e)=> {
                                                                    handleInput(e);
                                                                    setCommissionError("")
                                                                }} 
                                                                value={commission && commission}
                                                                labelError={commissionError ? commissionError : ""}
                                                                labelInput="Comissão"/> */}
                                                            <TextField
                                                                label="Comissão"
                                                                id="commissao"
                                                                size="small"
                                                                value={commission && commission}
                                                                // sx={{ m: 1, width: '25ch' }}
                                                                onChange={(e)=> {
                                                                    setCommission(e.target.value)
                                                                    // setCommissionError("")
                                                                }} 
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                                }}
                                                            />
                                                           
                                                        </div>
                                                        <div className="col-md-1 mb-3">
                                                        <FormGroup>
                                                            <FormControlLabel control={<Checkbox checked={isActive && isActive} onClick={(e) => handleCheckd(e.target.checked)} />} label="Ativo" />
                                                        </FormGroup>
                                                        </div>

                                                    </div>

                                                </div>
                                            </Card>
                                            <div style={{padding: 5}}>
                                            </div>
                                            <Card>
                                                <CardContent>
                                                    <Typography sx={{ width: 14 }} className="mb-3" gutterBottom variant="h7" component="div">
                                                        Contato
                                                    </Typography>
                                                        <div className="row">
                                                            <div className="col-md-4 mb-3">
                                                                <TextField
                                                                    fullWidth
                                                                    focused={nameContact && nameContact}
                                                                    label="Nome "
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    value={nameContact && nameContact}
                                                                    onChange={(e)=>{
                                                                         setNameContact(e.target.value);
                                                                        }}
                                                                />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <TextField
                                                                    fullWidth
                                                                    focused={email && email}
                                                                    label="E-mail"
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    value={email && email}
                                                                    onChange={(e)=> {
                                                                        setEmail(e.target.value);
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <InputMask mask="(99) 99999-9999" 
                                                                    fullWidth
                                                                    maskChar={""}
                                                                    className="form-control" 
                                                                    focused={phone && phone}
                                                                    onChange={(e)=> {
                                                                        setPhone(e.target.value)
                                                                    }}
                                                                    value={phone && phone} 
                                                                    placeholder="Telefone"
                                                                    required={phone && phone} 
                                                                    
                                                                />
                                                            </div>

                                                        </div>
                                                </CardContent>

                                            </Card>
                                            <div style={{padding: 5}}>
                                            </div>
                                            <Card>
                                                <CardContent>
                                                    <Typography sx={{ width: 14 }} className="mb-3" gutterBottom variant="h7" component="div">
                                                        Endereço
                                                    </Typography>
                                                        <div className="row">
                                                            <div className="col-md-3 mb-3">
                                                                <TextField
                                                                    fullWidth
                                                                    focused={cep && cep}
                                                                    label="Cep "
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    value={cep && cep}
                                                                    onChange={(e)=> {
                                                                        buscarCepClient(e.target.value)
                                                                        setCep(e.target.value)
                                                                        setCepError("")
                                                                    }}
                                                                    error={cepError && cepError}
                                                                    helperText={cepError ? cepError : ""}
                                                                />
                                                            </div>
                                                            <div className="col-md-3 mb-3">
                                                                <TextField
                                                                    fullWidth
                                                                    focused={address && address}
                                                                    label="Endereço "
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    value={address && address}
                                                                    onChange={(e)=> {
                                                                        setAddress(e.target.value);
                                                                        setAddressError("")
                                                                    }}
                                                                    error={addressError && addressError}
                                                                    helperText={addressError ? addressError : ""}
                                                                />
                                                            </div>
                                                            <div className="col-md-2 mb-3">
                                                                <TextField
                                                                    fullWidth
                                                                    focused={number && number}
                                                                    label="Número"
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    value={number && number}
                                                                    onChange={(e)=> {
                                                                        setNumber(e.target.value);
                                                                        setNumberError("")
                                                                    }}
                                                                    error={numberError && numberError}
                                                                    helperText={numberError ? numberError : ""}
                                                                />
                                                            </div>
                                                            <div className="col-md-4 mb-3">
                                                                <TextField
                                                                    fullWidth
                                                                    focused={neighborhood && neighborhood}
                                                                    label="Bairro"
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    value={neighborhood && neighborhood}
                                                                    onChange={(e)=> {
                                                                        setNeighborhood(e.target.value);
                                                                        setNeighborhoodError("");
                                                                    }}
                                                                    error={neighborhoodError && neighborhoodError}
                                                                    helperText={neighborhoodError ? neighborhoodError : ""}
                                                                />
                                                            </div>

                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-3 mb-3">
                                                                <TextField
                                                                    fullWidth
                                                                    focused={complement && complement}
                                                                    label="Complemento "
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    value={complement && complement}
                                                                    onChange={(e)=> {
                                                                        setComplement(e.target.value);
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className="col-md-4 mb-3">
                                                                <TextField
                                                                    fullWidth
                                                                    focused={city && city}
                                                                    label="Cidade"
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    value={city && city}
                                                                    onChange={(e)=> {
                                                                        setCity(e.target.value);
                                                                        setCityError("")
                                                                    }}
                                                                    error={cityError && cityError}
                                                                    helperText={cityError ? cityError : ""}
                                                                />
                                                            </div>
                                                            <div className="col-md-2 mb-3">

                                                                <FormControl fullWidth size="small" error={stateError && stateError}>
                                                                    <InputLabel id="demo-select-small">Estado</InputLabel>
                                                                    <Select
                                                                        labelId="demo-select-small"
                                                                        id="demo-select-small"
                                                                        focusede={state && state}
                                                                        value={state && state}
                                                                        label="Estado"
                                                                        onChange={(e) => {
                                                                            setState(e.target.value);
                                                                            setStateError("")
                                                                        }}
                                                                    >
                                                                        <MenuItem value={"AC"}>AC</MenuItem>
                                                                        <MenuItem value={"AL"}>AL</MenuItem>
                                                                        <MenuItem value={"AP"}>AP</MenuItem>
                                                                        <MenuItem value={"AM"}>AM</MenuItem>
                                                                        <MenuItem value={"BA"}>BA</MenuItem>
                                                                        <MenuItem value={"CE"}>CE</MenuItem>
                                                                        <MenuItem value={"GO"}>GO</MenuItem>
                                                                        <MenuItem value={"MA"}>MA</MenuItem>
                                                                        <MenuItem value={"MT"}>MT</MenuItem>
                                                                        <MenuItem value={"MS"}>MS</MenuItem>
                                                                        <MenuItem value={"MG"}>MG</MenuItem>
                                                                        <MenuItem value={"PA"}>PA</MenuItem>
                                                                        <MenuItem value={"PB"}>PB</MenuItem>
                                                                        <MenuItem value={"PR"}>PR</MenuItem>
                                                                        <MenuItem value={"PE"}>PE</MenuItem>
                                                                        <MenuItem value={"PI"}>PI</MenuItem>
                                                                        <MenuItem value={"RJ"}>RJ</MenuItem>
                                                                        <MenuItem value={"RN"}>RN</MenuItem>
                                                                        <MenuItem value={"RS"}>RS</MenuItem>
                                                                        <MenuItem value={"RO"}>RO</MenuItem>
                                                                        <MenuItem value={"RR"}>RR</MenuItem>
                                                                        <MenuItem value={"SC"}>SC</MenuItem>
                                                                        <MenuItem value={"SP"}>SP</MenuItem>
                                                                        <MenuItem value={"SE"}>SE</MenuItem>
                                                                        <MenuItem value={"TO"}>TO</MenuItem>
                                                                        <MenuItem value={"DF"}>DF</MenuItem>
                                                                    </Select>
                                                                    <FormHelperText >{stateError ? stateError : ""}</FormHelperText>
                                                                </FormControl>

                                                            </div>

                                                            <div className="col-md-2 mb-3">

                                                                <FormControl fullWidth size="small"  error={countryError && countryError}>
                                                                    <InputLabel id="demo-select-small">Pais</InputLabel>
                                                                    <Select
                                                                        labelId="demo-select-small"
                                                                        id="demo-select-small"
                                                                        focused={country && country}
                                                                        value={country && country}
                                                                        label="Pais"
                                                                        onChange={(e) => {
                                                                            setCountry(e.target.value);
                                                                            setCountryError("")
                                                                        }}
                                                                    >
                                                                        <MenuItem value={"Brasil"} >Brasil</MenuItem>
                                                                    </Select>
                                                                    <FormHelperText >{countryError ? countryError : ""}</FormHelperText>
                                                                </FormControl>

                                                            </div>

                                                        </div>
                                                </CardContent>

                                            </Card>
                                            <div style={{padding: 5}}>
                                            </div>
                                            <Card>
                                                <CardContent>
                                                    <div className="row"> 
                                                        <Typography sx={{ width: 14 }} className="mb-3" gutterBottom variant="h7" component="div">
                                                            Observação
                                                        </Typography>
                                                        <div className="col-md-12 mb-3">
                                                            
                                                            <TextField
                                                                fullWidth
                                                                id="outlined-multiline-static"
                                                                focused={observation && observation}
                                                                label="Observação"
                                                                defaultValue={observation && observation}
                                                                multiline
                                                                rows={6}
                                                                onChange={(e) => {
                                                                    setObservation(e.target.value);
                                                                    setObservationError("")
                                                                }}
                                                                error={observationError && observationError}
                                                                helperText={observationError ? observationError : ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            </Card>

                                    </div>  {/** Fecha segundo lado */}

                                    <div className='row' style={{padding: 50}}>
                                            <div className="col-md-8">

                                            </div>
                                            <div className="col-md-2">
                                                <Button variant="contained" color="warning" onClick={cancel} sx={{ mr: 1 }}>
                                                    <HighlightOffIcon />{"Cancelar"}
                                                </Button>
                                            </div>
                                            <div className="col-md-2">

                                                {
                                                    permission?.isinsert ?
                                                        <Button variant="contained" onClick={id ? update : save} sx={{ mr: 1 }}>
                                                            <AddIcon /> {"Salvar"}
                                                        </Button>
                                                    :
                                                    ""
                                                }
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </Paper>
                    </div>
                </div>
            }/>
        </Container>

    </>

    )

}
export default NewCustomer;