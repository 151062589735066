import React from 'react';
import { Select } from 'antd';
import { Option } from 'antd/es/mentions';

const options = [];

for (let i = 10; i < 36; i++) {
  options.push({
    value: i.toString(36) + i,
    label: i.toString(36) + i,
  });
}

const SelectedTag = ({data, onChange}) => (
    <Select
        mode="tags"
        style={{
        width: '100%',
        }}
        size={"large"}
        placeholder="Painel"
        onChange={onChange}
        // options={data}
        
    >
        {data.map((option) => (
            <Option key={option.idpanel} value={option.idpanel}>
                {option.name}
            </Option>
        ))}
    </Select>
);

export default SelectedTag;
