import React from "react";
import { Card } from "@mui/material";
import DrawerPrincipal from "../../component/drawer/drawer-principal";
import './home.css';

function Home(){

    return (
        <>

            <DrawerPrincipal title={"Dashboard"} divOpen={
                    
                <div className="iframe-container">
                    <Card>
                        <div className="iframe-wrapper">
                            <iframe
                                className="responsive-iframe"
                                src="https://analytics.zoho.com/open-view/1907568000004871220"
                                allowFullScreen
                            />
                        </div>
                    </Card>
                </div>



            }/>
            {/* <ResponsiveDrawer title={"Dashboard"} divOpen={                
                <div>
                    <Card>
                        <iframe style={{width: '100%', height: 880, bgcolor: '#FFFFF'}} src="https://analytics.zoho.com/open-view/1907568000004871220" allowfullscreen></iframe> 
                    </Card>
                </div>
            }/> */}

        </>
    );
}


export default Home;