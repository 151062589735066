import { apiClient, createSession } from "./api";

export const URL_CLIENT = "http://localhost:3000/api/";

const userService = {

    
    async signIn(user){

        var users = {
            login: user.login,
            password: user.password
        }

        var retorno = await createSession(users); 

        console.log(retorno)

        return retorno;
    },

    async listarUser(){


        var retorno = await apiClient.get('/user');


        return retorno.data;
    },

    async editUser(id){

        var retorno = await apiClient.get("/user/"+id);

        return retorno.data;
    },

    async save(user){
        console.log(user)

        var retorno = await apiClient.post("/user", user);

        return retorno;

    },

    async listFilter(id){
        console.log(id)

        var retorno = await apiClient.get('/user/filter/'+id);

        return retorno.data;
    },


    async update(user){
        console.log(user)

        var retorno = await apiClient.put("/user", user);

        return retorno;

    },

    async delete(iduser){
        console.log(iduser)

        var retorno = await apiClient.delete("/user/"+iduser);

        return retorno;

    }

}

export default userService;