import React from "react";
import { useEffect, useState } from "react";
import ResponsiveDrawer from "../../component/drawer/drawer";
import TextField from '@mui/material/TextField';
import { Button, Typography, Paper, Card, FormControl, InputLabel, Select, MenuItem, Stack, ToggleButtonGroup, ToggleButton, Divider, TableHead, TableRow, Table, TableContainer, TableCell, CardContent, Autocomplete, Link, InputAdornment, FormHelperText, Container, Menu, MenuList, Box} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ResponsiveDialog from "../../component/dialog/dialog";
import serviceService from "../../service/serviceService";
import saleService from "../../service/saleService";
import customerService from "../../service/serviceCustomer";
import panelService from "../../service/servicePanel";
import MyComponent from "../../component/input-mask/input-mask";
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff'; 
import userService from "../../service/serviceUser";
import moment from "moment";
import circuitService from "../../service/serviceCircuit";
import CustomizedBreadcrumbs from "../../component/breadcrumbs/breadcrumbs";
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import DataRangerPicker from "../../component/datapicker/data-ranger-picker";
import UploadFile from "../../component/upload-file/upload-file";
import { apiClient } from "../../service/api";
import { saveAs } from 'file-saver';
import DialogYesOrNo from "../../component/dialog-yes-or-no/dialog-yes-or-no";
import 'moment/locale/pt-br';
import SimpleBackdrop from "../../component/loading/loading";
import dayjs from "dayjs";
import DrawerPrincipal from "../../component/drawer/drawer-principal";


function NewSale(){

    const location = useLocation();
    const { prepareData } = location.state || {};

    const usuario = JSON.parse(localStorage.getItem('user_token'));
    const permission = usuario.roles.filter(item=>item.name === "Vendas")[0];

    const permSendSale = usuario.roles.filter(item=>item.name === "Enviar Vendas")[0];

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");

    const [codPi, setCodPi] = useState('');
    const [codIntern, setCodIntern] = useState(0);
    const [codPiError, setCodPiError] = useState("");

    const [idsale, setIdSale] = useState(0);
    const [situation, setSituation] = useState("");
    const [situationError, setSituationError] = useState("");

    const [type, setType] = useState("");
    const [typeError, setTypeError] = useState("");

    const [number, setNumber] = useState(0);

    const [dateSale, setDateSale] = useState(new Date());
    const [dateSaleError, setDateSaleError] = useState(new Date());

    const [observation, setObservation] = useState("");

    const [discount, setDiscount] = useState(0);
    const [vlAdicional, setVlAditional] = useState(0);

    const [vlPermuta, setVlPermuta] = useState(0)

    const [idClient, setIdClient] = useState();
    const [idClientError, setIdClientError] = useState('');

    const [idsaller, setIdSaller] = useState('');
    
    const [qtdMonth, setQtdMonth] = useState(0);

    const [isSend, setIsSend] = useState(false);

    const [idPay, setIdPay] = useState(0);
    const [typePayment, setTypePayment] = useState("");
    const [typePaymentError, setTypePaymentError] = useState("");

    const [vlAmountReceivable, setVlAmountReceivable] = useState(0);
    
    const [dateReceivable, setDateReceivable] = useState(new Date());
    const [dateReceivableError, setDateReceivableError] = useState(new Date());

    const [datePrevisionSales, setDatePrevisionSales] = useState(new Date());
    const [datePrevisionSalesError, setDatePrevisionSalesError] = useState(new Date());
    
    const [dateLastPrevision, setDateLastPrevision] = useState(new Date());

    const [formCondition, setFormCondition] = useState("1x");
    const [formConditionError, setFormConditionError] = useState("");
    
    const [intervaloPagto, setIntervaloPagto] = useState("Mes");
    const [intervaloPagtoError, setIntervaloPagtoError] = useState(0);

    //Total geral
    const [totalItensSale, setTotalItensSale] = useState(0);
    const [totalValueAdicional, setTotalValueAdicional] = useState(0);
    const [totalDesconto, setTotalDesconto] = useState(0);
    const [totalGeralVenda, setTotalGeralVenda] = useState(0);
    const [ticketMiddle, setTicketMiddle] = useState(0);
    const [ticketMiddleLiquido, setTicketMiddleLiquido] = useState(0);
    const [idCurrent, setIdCurrent] = useState();


    const [totalAFvalue, setTotalAFvalue] = useState(0.0);
    const [totalDFvalue, setTotalDFvalue] = useState(0.0);
    const [totalAFaturar, setTotalAFaturar] = useState(0.0);
    const [totalLiquido, setTotalLiquido] = useState(0.0);

    const [listCustomer, setListCustomer] = useState([]);
    const [listService, setListService] = useState([]);
    const [listPanel, setListPanel] = useState([]);
    const [listUser, setListUser] = useState([]);
    const [listInserts, setListInserts] = useState([]);

    const [typeCalc, setTypeCalc] = useState('value');

    const [isFileExist, setIsFileExist] = useState(false);

    const [fileId, setFileId] = useState(0);

    const [filePI, setFilePI] = useState(0);



    const [openDialog, setOpenDialog] = useState();
    const [messageDialog, setMessageDialog] = useState();
    const [titleDialog, setTitleDialog] = useState();
    const [statusDialog, setStatusDialog] = useState();

    const [openDialogYesOrNo, setOpenDialogYesOrNo] = useState();
    const [messageDialogYesOrNo, setMessageDialogYesOrNo] = useState();
    const [titleDialogYesOrNo, setTitleDialogYesOrNo] = useState();
    const [statusDialogYesOrNo, setStatusDialogYesOrNo] = useState();
    const [inputDialog, setInputDialog] = useState();
    const [indexDialog, setIndexDialog] = useState();

    const [openDialogCampanhaYesOrNo, setOpenDialogCampanhaYesOrNo] = useState();
    const [messageDialogCampanhaYesOrNo, setMessageDialogCampanhaYesOrNo] = useState();
    const [titleDialogCampanhaYesOrNo, setTitleDialogCampanhaYesOrNo] = useState();
    const [statusDialogCampanhaYesOrNo, setStatusDialogCampanhaYesOrNo] = useState();
    const [inputDialogCampanha, setInputDialogCampanha] = useState();
    const [indexDialogCampanha, setIndexDialogCampanha] = useState();


    const [openDialogFree, setOpenDialogFree] = useState();
    const [messageDialogFree, setMessageDialogFree] = useState();
    const [titleDialogFree, setTitleDialogFree] = useState();
    const [statusDialogFree, setStatusDialogFree] = useState();

    const [listaIDCustomer, setListaIDCustomer] = useState([]);

    const [listIDUser, setListIDUser] = useState([]);
    const [fileSave, setFileSave] = useState();

    const [openLoading, setOpenLoading] = useState(false)

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const config = true ? {style: 'currency', currency: 'BRL'} : {minimumFractionDigits: 2};

    const [commissionFields, setCommissionFields] = useState([]);

    const [campanhaFields, setCampanhaFields] = useState([]);


    const addCampanhaEmpty = () => {

        var newCamp = {
            idItem: 0,
            idpanel: '', 
            idpanelError: '',
            panel: '',
            period: [],//[moment(new Date(), 'YYYY-MM-DD'), moment(new Date(), 'YYYY-MM-DD')],
            dtStart: dayjs(dayjs(), 'YYYY-MM-DD'),
            dtStartError: '',
            dtEnd: dayjs(dayjs(), 'YYYY-MM-DD'),
            qtdPeriod: 0,
            idservice: '',
            idserviceError: '',
            service: '',
            qtdService: 1,
            qtdInsert: 0,
            qtdInsertError: '',
            vlUnit: '0',
            vlTotal: '0',
            vlLiqPercMed: 0,
            percentLiquid: 0,
            vlTotalPercMed: 0,
            percentMedio: 0

        }

    setCampanhaFields([...campanhaFields, newCamp]);

    }

    const {id, isOpen} = useParams();
    
    let navigate = useNavigate();

    var apontador = 1;

    useEffect(() => {
        
        (async ()=> {
            setOpenLoading(true)
            
            if(id)  {
                saleService.edit(id)
                .then(item => {

                    moment.locale('pt-br');

                    var dataSa = moment(item.dateSale).format('YYYY-MM-DD');
                    var dataPay = moment(item.formPayment.dateReceivable).format('YYYY-MM-DD');
                    var dataPrevista = moment(item.formPayment.datePrevisionSales).format('YYYY-MM-DD');

                    setIdSale(item?.idsale)
                    setName(item?.name)
                    setCodPi(item?.codPi)
                    setCodIntern(item?.codIntern)
                    setNumber(item?.number)
                    setSituation(item?.situation)
                    setType(item?.type)
                    setQtdMonth(item?.qtdMonth)
                    setIsSend(item?.issend)
                    setDateSale(dataSa)
                    setObservation(item?.observation) 
                    setIdCurrent(item?.idCurrent)
                    setDiscount(item?.discount)
                    setVlAditional(item?.vlAdicional)

                    setVlPermuta(item?.permuta)
                    setTypeCalc(item?.typePercent)
                    setIdClient(item?.idclient)
                    setIdSaller(item?.idsaller) 

                    setIdPay(item?.formPayment.idPay)

                    setFormCondition(item?.formPayment.formCondition)
                    setTypePayment(item?.formPayment.typePayment)
                    setVlAmountReceivable(item?.formPayment.vlAmountReceivable)
                    setDateReceivable(dataPay)
                    setIntervaloPagto(item?.formPayment.intervalInstallment)

                    setDatePrevisionSales(dataPrevista)
                    setDateLastPrevision(dataPay)

                    setFilePI(item?.filePI)

                    setCampanhaFields(item?.items)
                    setCommissionFields(item?.commissions)

                    customerService.editCustomer(item?.idclient).then(item=> {
                        setListaIDCustomer(item);
                    });

                    
                    userService.editUser(item?.idsaller).then(item=> {                        
                        setListIDUser(item);
                    });

                    //--------------- calculos edição ------------------------------//
                    
                    //Calcular itens 
                    calculateTotalCampanhaEdit(item);

                    calcTotalCommissionEdit(item);

                    setOpenLoading(false)

                    //Reabrindo uma venda
                    if(isOpen){
                        setIsSend(false);
                        setSituation('Em Autorizacao')
                    }

                }).catch(error => {
                    console.log(error)
                });
            }else {
                userService.editUser(usuario.iduser).then(item=> {                        
                    setListIDUser(item);
                    setIdSaller(usuario.iduser)
                });

                // saleService.maxGenerateNumber().then(item=> {                        
                //     setCodPi(item);
                // });

                saleService.maxGenerateNumberCodIntern().then(item=> {                        
                    setCodIntern(item);
                });

                if(!prepareData){
                    addCampanhaEmpty();
                }

            }
            
            var listCust = [];
            customerService.listCustomerActive().then(item => { 
                
                item.forEach(element => {
                    listCust.push(element);
                });
    
                setListCustomer(listCust)
                
            }).catch(error => {
                console.log(error)
            });
    
    
            var listService = [];
            serviceService.listActive().then(item => {
    
                item.forEach(element => {
                    listService.push(element);
                });
    
                setListService(listService)
                
            }).catch(error => {
                console.log(error)
            });
    
    
            var listQtdInserts = [];
            serviceService.listQtdInsert().then(item => {
    
                item.forEach(element => {
                    listQtdInserts.push(element);
                });
    
                setListInserts(listQtdInserts)
                
            }).catch(error => {
                console.log(error)
            });


            var listPanel = [];
            panelService.listActive().then(item => {
    
                item.forEach(element => {
                    listPanel.push(element);
                });

                circuitService.listActive().then(circuit => {
                    circuit.forEach(element => {
                        listPanel.push(element);
                    });
                });
    
                setListPanel(listPanel)

            }).catch(error => {
                console.log(error)
            });


            var listUser = [];
            userService.listarUser().then(item => {
    
                item.forEach(element => {
                    listUser.push(element);
                });
    
                setListUser(listUser)
                
            }).catch(error => {
                console.log(error)
            });

            
            if(apontador === 1){
                apontador = 2; // Inserindo essa regra por conta dos dados duplicados no carregamento vindo da tela de disponiblidade
                loadingParamsAvailability();
            }

            setOpenLoading(false)   
           
        })();


    },[id]);


    const loadingParamsAvailability = () => {

        if(prepareData && prepareData.list){

            //Opção só é chamada quando vem da tela de Disponiblidade
            setSaleAvailability();
    
            let data = [...campanhaFields];
            data.forEach((item, index)=> {
                calculateTotal(index, prepareData.service.vlSale)
            })
    
            setCampanhaFields(data);

            handleInputDiscount(prepareData.discount)
        }

    }



    const setSaleAvailability = () => {

        // console.log(prepareData)

            // console.log(prepareData.list.length)

                for (let index = 0; index < prepareData.list.length; index++) {
                    const item = prepareData.list[index];

                   

                    let newCamp = {
                            idItem: 0,
                            idpanel: 0,
                            idpanelError: '',
                            panel: item, 
                            qtdPeriod: 0,
                            period: [], 
                            // dtStart: dayjs(dayjs(), 'YYYY-MM-DD'),
                            dtStart: dayjs(moment(prepareData.dtIni, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'YYYY-MM-DD'),
                            dtStartError: '',
                            // dtEnd:  dayjs(dayjs(), 'YYYY-MM-DD'),
                            dtEnd:  dayjs(moment(prepareData.dtEnd, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'YYYY-MM-DD'),
                            // idservice: 0,
                            idservice: prepareData.service.idservice,
                            idserviceError: '',
                            // service: '',
                            service: prepareData.service,
                            // qtdService: 0, 
                            qtdService: prepareData.days, 
                            // qtdInsert: 0,
                            qtdInsert: prepareData.qtd_insercao,
                            qtdInsertError: '',
                            totalInsert: 0,
                            vlUnit: prepareData.service.vlSale,
                            // vlUnit: '0',
                            vlTotal: '0'
                        }
                    

                        campanhaFields.push(newCamp);
                        setCampanhaFields(campanhaFields)
                    // console.log(newCamp)
                    // setCampanhaFields([...campanhaFields, newCamp])
                    // setTotalItensSale(campanhaFields.length)
                }
        
    }


    function save(){
        setIsButtonDisabled(true)
        setOpenLoading(true)

        if(valid()) {

            var formPaymentData = {
                typePayment: typePayment,
                vlAmountReceivable: vlAmountReceivable, 
                dateReceivable: dateReceivable,
                formCondition: formCondition,
                datePrevisionSales: datePrevisionSales,
                intervalInstallment: intervaloPagto
            }

            var data = {
                situation: situation, 
                name: name, 
                codPi: codPi,
                type: type, 
                qtdMonth: qtdMonth,
                number: number, 
                observation: observation, 
                dateSale: dateSale,
                discount: discount, 
                // vlAdicional: vlAdicional, 
                permuta: vlPermuta,
                vlTotalItems: totalItensSale, 
                vlTotal: totalGeralVenda,
                vlAFaturar: totalAFaturar,
                idclient: idClient,
                customer: listaIDCustomer,
                idsaller: idsaller, 
                typePercent: typeCalc,
                ticketMiddle: ticketMiddle,
                totalLiquid: parseFloat(totalLiquido),
                items: campanhaFields,
                commissions: commissionFields,
                formPayment: formPaymentData,
                idUser: usuario.iduser,
                userCreated: usuario.iduser,
                codIntern: codIntern,
                ticketMiddle2: ticketMiddleLiquido
            }

            console.log(data)

            saleService.save(data).then(item => {

                if(fileSave) {

                    var dataFile = {
                        file: fileSave,
                        idSale: item.data.idSale,
                        idUser: usuario.iduser
                    }
                    saleService.uploadPDF(dataFile).then(item => {
                        setOpen(false);
                        setIsFileExist(true)  
                        setOpenDialog(true);
                        setStatusDialog('success')
                        setTitleDialog("Muito bem!")
                        setMessageDialog("Venda cadastrada com sucesso!")
                        setOpenLoading(false)

                    }).catch(error => {
                        setIsButtonDisabled(false)
                        setOpenDialogFree(true);
                        setStatusDialogFree('error')
                        setTitleDialogFree("Ops")
                        setMessageDialogFree(error.response.data)
                        setOpenLoading(false)
                    });
                    setOpenLoading(false)

                }else {


                    if(type === 'Venda recorrente') {
                        setOpenDialog(true);
                        setStatusDialog('success')
                        setTitleDialog("Muito bem!")
                        setMessageDialog("Serão criadas " + qtdMonth + " vendas recorrentes com datas futuras a data desta venda ")
                        setOpenLoading(false)
    
                    }else {
                        setOpenDialog(true);
                        setStatusDialog('success')
                        setTitleDialog("Muito bem!")
                        setMessageDialog("Venda cadastrada com sucesso!")
                        setOpenLoading(false)
    
                    }
                    
                }

            }).catch(error => {
                setIsButtonDisabled(false)
                setOpenDialogFree(true);
                setStatusDialogFree('error')
                setTitleDialogFree("Ops")
                setMessageDialogFree(error.response.data)
                setOpenLoading(false)

            });

        }

        setIsButtonDisabled(false) 

    }


    function updateDate(){
        
        setIsButtonDisabled(true)
        setOpenLoading(true)

        var data = {
            idSale: idsale,
            datePrevision: datePrevisionSales,
            dateReceivable: dateReceivable,
            observation: observation
        }

        saleService.updateDate(data).then(item => {

            setOpenDialog(true);
            setStatusDialog('success')
            setTitleDialog("Muito bem!")
            setMessageDialog("Venda atualizada com sucesso")   
            
            setOpenLoading(false)


        }).catch(error => {
            setIsButtonDisabled(false)
            setOpenDialogFree(true);
            setStatusDialogFree('error')
            setTitleDialogFree("Ops")
            setMessageDialogFree(error.response.data)
            setOpenLoading(false)
        });

    }


    function update(){

        setIsButtonDisabled(true)
        setOpenLoading(true)

        if(valid()) {

            var formPaymentData = {
                idPay: idPay,
                typePayment: typePayment, 
                vlAmountReceivable: vlAmountReceivable, 
                dateReceivable: dateReceivable,
                formCondition: formCondition,
                datePrevisionSales: datePrevisionSales,
                intervalInstallment: intervaloPagto,
                dateLastPrevision: dateLastPrevision,
                idSale: idsale
            }

            var data = {
                idsale: idsale, 
                situation: situation, 
                name: name, 
                codPi: codPi,
                type: type, 
                qtdMonth: qtdMonth,
                number: number, 
                observation: observation, 
                dateSale: dateSale,
                discount: discount, 
                // vlAdicional: vlAdicional, 
                permuta: vlPermuta,
                vlTotalItems: totalItensSale, 
                vlTotal: totalGeralVenda,
                vlAFaturar: totalAFaturar,
                idclient: idClient,
                customer: listaIDCustomer,
                idsaller: idsaller, 
                typePercent: typeCalc,
                ticketMiddle: ticketMiddle,
                totalLiquid: parseFloat(totalLiquido),
                items: campanhaFields,
                commissions: commissionFields,
                formPayment: formPaymentData,
                idUser: usuario.iduser,
                userUpdated: usuario.iduser,
                codIntern: codIntern,
                ticketMiddle2: ticketMiddleLiquido,
                idCurrent: idCurrent
            }

            console.log(data)

            saleService.update(data).then(item => {


                if(fileSave) {

                    var dataFile = {
                        file: fileSave,
                        idSale: id, 
                        idUser: usuario.iduser
                    }
                        saleService.uploadPDF(dataFile).then(item => {
                            setOpen(false);
                            setIsFileExist(true)  
                            setOpenDialog(true);
                            setStatusDialog('success')
                            setTitleDialog("Muito bem!")
                            setMessageDialog("Venda atualizada com sucesso!")
                        }).catch(error => {
                            setIsButtonDisabled(false)
                            setOpenDialogFree(true);
                            setStatusDialogFree('error')
                            setTitleDialogFree("Ops")
                            setMessageDialogFree(error.response.data)
                        });
                        setOpenLoading(false)
                }else {
                    setOpenLoading(false)
                    setOpenDialog(true);
                    setStatusDialog('success')
                    setTitleDialog("Muito bem!")
                    setMessageDialog("Venda atualizada com sucesso!")
                    
                }



                setOpenDialog(true);
                setStatusDialog('success')
                setTitleDialog("Muito bem!")
                setMessageDialog("Venda atualizada com sucesso")   
                
                setOpenLoading(false)
            }).catch(error => {
                setIsButtonDisabled(false)
                setOpenDialogFree(true);
                setStatusDialogFree('error')
                setTitleDialogFree("Ops")
                setMessageDialogFree(error.response.data)
                setOpenLoading(false)
            });
        }   

    }

    function valid(){

        let thatsOk = true;
        let isButtonDesabled = true;

        let data = [...commissionFields];

        if(commissionFields.length > 0){

            commissionFields.forEach((item, index)=> {

                if(!data[index]['typeCommission']){
                    data[index]['typeCommissionError'] = 'Este campo é obrigatório'
                    isButtonDesabled = false;
                    thatsOk = false;
                }
                if(!data[index]['idClient']){
                    data[index]['idClientError'] = 'Este campo é obrigatório'
                    isButtonDesabled = false;
                     thatsOk = false;
                }
                if(!data[index]['dateReceivable']){
                    data[index]['dateReceivableError'] = 'Este campo é obrigatório'
                    isButtonDesabled = false;
                     thatsOk = false;
                }
                if(!data[index]['months']){
                    data[index]['monthsError'] = 'Este campo é obrigatório'
                    isButtonDesabled = false;
                     thatsOk = false;
                }
                if(!data[index]['percent']){
                    data[index]['percentError'] = 'Este campo é obrigatório'
                    isButtonDesabled = false;
                     thatsOk = false;
                }


    
            })
            setCommissionFields(data); 
        }


        // let dataCampanha = [...campanhaFields];

            // campanhaFields.forEach((item, index)=> {
            //     // if(!dataCampanha[index]['idpanel']){
            //     //     dataCampanha[index]['idpanelError'] = 'Este campo é obrigatório'
            //     //     console.log("Campo idpanel")
            //     //     thatsOk = false;
            //     // }
            //     if(!dataCampanha[index]['idservice']){
            //         dataCampanha[index]['idserviceError'] = 'Este campo é obrigatório'
            //          thatsOk = false;
            //          console.log("Campo idservice")
            //     }
            //     if(!dataCampanha[index]['qtdInsert']){
            //         dataCampanha[index]['qtdInsertError'] = 'Este campo é obrigatório'
            //          thatsOk = false;
            //          console.log("Campo qtdInsert")
            //     }
    
            // })
            // setCampanhaFields(dataCampanha);
        
        if(!name){
            setNameError("O nome precisa ser preenchido")
            thatsOk = false;
            isButtonDesabled = false;
        }
        if(!codPi){
            setCodPiError("O nome precisa ser preenchido")
            thatsOk = false;
            isButtonDesabled = false;
        }
        if(!type){
            setTypeError("O Tipo precisa ser preenchido")
            thatsOk = false;
            isButtonDesabled = false;
        }
        if(!situation){
            setSituationError("A Situação precisa ser preenchido")
            thatsOk = false;
            isButtonDesabled = false;
        }
        if(!typePayment){
            setTypePaymentError("O Tipo de Pagamento precisa ser preenchido")
            thatsOk = false;
            isButtonDesabled = false;
        }
        if(!formCondition && type !== 'Venda recorrente'){
            setFormConditionError("A forma de Pagamento precisa ser preenchido")
            isButtonDesabled = false;
            thatsOk = false;
        }
        if(!intervaloPagto && formCondition !== '1x' && type !== 'Venda recorrente'){
            setIntervaloPagtoError("A forma de Pagamento precisa ser preenchido")
            isButtonDesabled = false;
            thatsOk = false;
        }
        if(!idClient){
            setIdClientError("O Cliente precisa ser preechido")
            isButtonDesabled = false;
            thatsOk = false;
        }
        if(datePrevisionSales === "Data inválida" || !datePrevisionSales){
            setDatePrevisionSalesError("Esta data precisa ser preenchida")
            isButtonDesabled = false;
            thatsOk = false;
        }

        setOpenLoading(false)

        if(!isButtonDesabled){
            setIsButtonDisabled(isButtonDesabled)
        }

        return thatsOk;

    }

    
    const handleDialogClose = () => {
        setOpenDialog(false);
        navigate("/listSale")
    };

    const handleDialogCloseYesOrNo = () => {
        setOpenDialogYesOrNo(false);
        // navigate("/listSale")
    };

    const handleDialogCampanhaCloseYesOrNo = () => {
        setOpenDialogCampanhaYesOrNo(false);
        // navigate("/listSale")
    };

    const handleDialogCloseFree = () => {
        setOpenDialogFree(false);
       
    };

    
    const handleInputDiscount = (value) => {
        setDiscount(value);
        calculateDescontoTotal(value);

    }

    const handleInputAdicional = (value) => {
        setVlPermuta(value);
        calculatePermutaTotal(value);
    }


    const handleInputVlReceivable = (index, value) => {        
        handleCommissionChange(index, value, 'vlReceivable');
    }


    const handleInputValueReceivable = (value) => {
        setVlAmountReceivable(value);
    }
    
    const handleCampanhaChange = async (index, event, type) => {
        let data = [...campanhaFields];

        if(type === 'idpanel'){

            if(event?.idpanel){
                data[index]['idpanel'] = event?.idpanel;
                data[index]['panel'] = event;
            }

        } else if(type === 'idservice'){
            data[index]['idservice'] = event.idservice;

            data[index]['qtdService'] = parseInt(data[index]['qtdService']);
            //vamos ter que colocar o quantidade do novo cadastro
            data[index]['vlUnit'] = event.vlSale;

            let tot =  (parseInt(data[index]['qtdService']) * (data[index]['qtdInsert'] !== null ? parseInt(data[index]['qtdInsert']) : 0) * parseFloat(event?.vlSale));

            data[index]['vlTotal'] = parseFloat(tot);


            //Calcula o valor vindo da escolha do serviço da campanha.
            calculateTotal(index, event.vlSale)
            
            data[index]['service'] = event;
        } else 
            if(type === 'vlUnit'){
                data[index]['vlUnit'] = event;

        } else if(type === 'qtdInsert'){
            data[index]['qtdInsert'] = event;

            //pegar o valor total
            //pegar o valor total da campanha

            calculateTotal(index, parseFloat(data[index]['vlUnit']))


        } else if (type === 'serviceRandom'){

            data.forEach((item, index)=> {
                data[index]['idservice'] = event.idservice;
                data[index]['qtdService'] = (data[index]['qtdService'] ? parseInt(data[index]['qtdService']) : 1)
                data[index]['vlUnit'] = event.vlSale;

                data[index]['service'] = event;

                calculateTotal(index, event.vlSale)

            })

        }else if (type === 'insertRandom'){

                data.forEach((item, index)=> {
                    
                    data[index]['qtdInsert'] = event;
    
                    calculateTotal(index, parseFloat(data[index]['vlUnit']))
    
                })
        } else if(type === 'periodRandom') {
            
            var dtStart = event[0];
            var dtEnd = event[1];

            data.forEach((item, index)=> {

                data[index]['dtStart'] = event[0]
                data[index]['dtEnd'] = event[1]

                var diff = dayjs(dtEnd, 'YYYY-MM-DD').diff(dayjs(dtStart, 'YYYY-MM-DD'));
            
                var diffDays = (moment.duration(diff).asDays() + 1);
                
                data[index]['qtdService'] = parseInt(diffDays)
    
                var valorUnitario = data[index]['vlUnit'];
                calculateTotal(index, valorUnitario)

            })
            
        }else if(type === 'periodo'){
            
            data[index]['dtStart'] = event[0]
            data[index]['dtEnd'] = event[1]

            //Pegando data inicio
            var dtStart = event[0];
            //Pegando data final
            var dtEnd = event[1];

            //Calculando a diferença entre as duas datas somando mais 1 para dar o dia cheio
            var diff = dayjs(dtEnd, 'YYYY-MM-DD').diff(dayjs(dtStart, 'YYYY-MM-DD'));
            
            var diffDays = (moment.duration(diff).asDays() + 1);

            data[index]['qtdPeriod'] = parseInt(diffDays)
            data[index]['qtdService'] = parseInt(diffDays)

            var valorUnitario = data[index]['vlUnit'];
            calculateTotal(index, valorUnitario);

        }else{

            data[index][event.target.name] = event.target.value;
        }

        setCampanhaFields(data);
    }
 
    const addCampanhaFields = async () => {
        let newCamp = {
                idItem: 0,
                idpanel: '',
                idpanelError: '',
                panel: '', 
                qtdPeriod: 0,
                period: [], 
                dtStart: dayjs(dayjs(), 'YYYY-MM-DD'),
                dtStartError: '',
                dtEnd: dayjs(dayjs(), 'YYYY-MM-DD'),
                idservice: '',
                idserviceError: '',
                service: '',
                qtdService: 1, 
                qtdInsert: 0,
                qtdInsertError: '',
                totalInsert: 0,
                vlUnit: '0',
                vlTotal: '0',
                vlLiqPercMed: 0,
                percentLiquid: 0,
                vlTotalPercMed: 0,
                percentMedio: 0
            }

        setCampanhaFields([...campanhaFields, newCamp])

        setTotalItensSale(campanhaFields.length)
        
    }

    const addComissionFields = () => {
        
        let newFields = {
                idCommission: 0,
                idClient: '', 
                idClientError: '',
                customer: '',
                typeCommission: '',
                typeCommissionError: '',
                percent: '',
                percentError: '',
                vlReceivable: 0,
                months: 1,
                monthsError: '',
                dateReceivable: new Date(),
                dataReceivableError: '',
                vlTotal: '0'
            }

        setCommissionFields([...commissionFields, newFields])

        
    }

    const handleCommissionChange = (index, event, type) => {
        let data = [...commissionFields];
        
        if(type === 'idClient'){
            data[index]['idClient'] = event.idcustomer;
            data[index]['percent'] = event.commission ;
            data[index]['customer'] = event;
            data[index]['percentError'] = event.commission ? '' : 'Campo obrigatório';
            calculatePercentCommission(index, event.commission);
        }else if (type === 'vlReceivable'){
            data[index]['vlReceivable'] = event;
        }else {
            data[index][event.target.name] = event.target.value;
        }

        setCommissionFields(data); 
    }


    const handleChangeClient = (input, newInput) => {
        setIdClient(input.idcustomer)
        setListaIDCustomer(input)
    }

    const handleChangeUser = (input, newInput) => {
        setIdSaller(input.iduser)
        setListIDUser(input)
    }


    //--------------- calculo de itens/Campanha ---------------------

    async function getTotalAtualizado(){
        return campanhaFields.reduce((a, b) => parseFloat(a) + parseFloat(b.vlTotal), 0)
    }


    // --------------------- Fim Recalculo ---------------------//

    //CalulateTotal é chamado em: 
    //Serviço item campanha 
    const calculateTotal = async (index, value) => {
        let data = [...campanhaFields];

        var quantidade = data[index]['qtdService'];
        data[index]['vlTotal'] = parseFloat(value) * (data[index]['qtdInsert'] ? parseFloat(data[index]['qtdInsert']) : 0) * parseFloat(quantidade);
        
        setDiscount(0)
        setVlAditional(0)
        setTotalDesconto(0)
        setTotalValueAdicional(0)

        var total = await getTypeCalcPercentOrValue(typeCalc, 0, 'calczero');

        setTotalGeralVenda(total);
        setTotalItensSale(total);

        recalcularCommission(total);
        
       await calculateTicketMiddle(total, index)
    //    await calculateTicketMiddleLiquido(total)

        //Calculando valor total médio para total item 
        // await calculateTotalPercentualMedio(total);
        //Calculando valor totalLiquido médio para total item 
        // await calculateVlLiqPercentualMedio(total);

    }


    const calculateVlLiqPercentualMedio = async (totalLiquido) => {
        let data = [...campanhaFields]; 

        data.forEach((item, index) => {
            var totalCampanha = data.reduce((a, b) => parseFloat(a) + parseFloat(b.vlTotal), 0);
            var percentual = (parseFloat(item.vlTotal) / totalCampanha * 100);
            var vlLiqPercMed = (totalLiquido * percentual / 100);
            data[index]['vlLiqPercMed'] = vlLiqPercMed;
            data[index]['percentLiquid'] = percentual;

            console.log('[percentLiquid] ', percentual)

        });

    }


    async function recalcularCommission(total){
        
         //Recalcular os itens da comissão.
         if(commissionFields.length > 0) {

            var valor = commissionFields.reduce((a, b) => parseFloat(a) + parseFloat(b.vlReceivable), 0);

            if(valor){
                commissionFields.forEach((item, index)=> {
                    calcItemCommission(index, item.percent, total);
                });
            }else{

                var totalSemPermuta = total;

                setTotalLiquido(totalSemPermuta)
                setTotalAFaturar(totalSemPermuta)
                setVlAmountReceivable(totalSemPermuta)

                await calculateVlLiqPercentualMedio(totalSemPermuta);
                await calculateTotalPercentualMedio(totalSemPermuta);
            }
        }else{
            
            var totalSemPermuta = total;

            setTotalLiquido(totalSemPermuta)
            setTotalAFaturar(totalSemPermuta)
            setVlAmountReceivable(totalSemPermuta)

            await calculateVlLiqPercentualMedio(totalSemPermuta);
            await calculateTotalPercentualMedio(totalSemPermuta);
        }

        await calculateTicketMiddleLiquido(totalSemPermuta);

       
        
    }

    async function calculateTicketMiddleLiquido(totalLiquid){
        var totalInsert = 0;

        campanhaFields.forEach((item, index) => {
            let data = [...campanhaFields];
            
            var totalInsertItem = (parseInt(item.qtdInsert) * parseInt(item.qtdService ? item.qtdService : 0));

            data[index]['totalInsert'] = totalInsertItem;
            totalInsert = parseFloat(totalInsert) + parseFloat(totalInsertItem);
        })

        var totalTicket = (parseFloat(totalLiquid) / parseFloat(totalInsert));

        setTicketMiddleLiquido(totalTicket);

    }

    async function calculateTicketMiddle(total, index){
            var totalInsert = 0;

            campanhaFields.forEach((item, index) => {
                let data = [...campanhaFields];
                
                var totalInsertItem = (parseInt(item.qtdInsert) * parseInt(item.qtdService ? item.qtdService : 0));

                data[index]['totalInsert'] = totalInsertItem;
                totalInsert = parseFloat(totalInsert) + parseFloat(totalInsertItem);
            })

            var totalTicket = (total === 0 && totalInsert === 0 ? 0 : parseFloat(total) / parseFloat(totalInsert));

            setTicketMiddle(totalTicket);

    }


    async function calcItemCommission(index, value, total){

            let data = [...commissionFields];

            if(data[index]['typeCommission'] == 'Antes'){
    
                //Valor a receber do item
                data[index]['vlReceivable'] = (value * total / 100);  

            }
            
            var totalAF = await getTotalCommissionAF();
            setTotalAFvalue(totalAF);

            
            if(data[index]['typeCommission'] === 'Depois') {

               var totalA = commissionFields
                .filter((item) => item.typeCommission === 'Antes')
                .reduce((a, b) => parseFloat(a) + parseFloat(b.vlReceivable), 0)

                var totalFaturamento = (parseFloat(total) - parseFloat(totalA));

                data[index]['vlReceivable'] = (value * parseFloat(totalFaturamento) / 100);
            }
            else        
                if(data[index]['typeCommission'] === 'sobreDF'){

                    var geralsFinal = await getTotalCommissionFat();
    
                    var liquidoTotal =  parseFloat(total) - parseFloat(geralsFinal)

                    //O útimo valor do tipo Antes do faturamento do array 
                    data[index]['vlReceivable'] = (value * parseFloat(liquidoTotal) / 100);  
    
                }
    
            var totalDF = await getTotalCommissionDF();
            setTotalDFvalue(totalDF);
            
    
            //-------- calculo totais ---------------
            
            // Total da venda - totalAF = faturamento
            var totalFaturamento = (parseFloat(total) - parseFloat(totalAF)) - vlPermuta;
            setTotalAFaturar(totalFaturamento);
    
            //calculando total liquido
            var totalGeralsFinal = await getTotalCommissionFat();
    
            var totalLiquidoFinal =  parseFloat(total) - parseFloat(totalGeralsFinal)
            setTotalLiquido(totalLiquidoFinal);
    
            //valor a receber do Informações de Pagamento
            setVlAmountReceivable(totalFaturamento);

            await calculateTicketMiddleLiquido(totalLiquidoFinal);

            await calculateVlLiqPercentualMedio(totalLiquidoFinal);

            await calculateTotalPercentualMedio(totalFaturamento);
    }

    const getTypeCalcPercentOrValue = async (type, value, calczero) => {
        var total = await getTotalAtualizado();

        var totalComDescontoGeral = 0;

        if(calczero === 'calczero') {

            totalComDescontoGeral = total;

        } else {

            if(type === 'percent') {
                var totalPlusAditional = parseFloat(total) + parseFloat(totalValueAdicional);
                totalComDescontoGeral = (totalPlusAditional - (totalPlusAditional * parseFloat(value) / 100))
            }else{
                totalComDescontoGeral = ((parseFloat(total) + parseFloat(totalValueAdicional ? totalValueAdicional : 0)) - parseFloat(value));
            }
        }

        return totalComDescontoGeral;
    }

    //Calcular desconto
    const calculateDescontoTotal = async (value) => {

        var totalDescontos = parseFloat(value);

        var total = await getTypeCalcPercentOrValue(typeCalc, value);

        setTotalDesconto(totalDescontos);

        await recalcularCommission(total);
        
        setTotalGeralVenda(total);

        await calculateTicketMiddle(total, 0);

    }

    const calculateTotalPercentualMedio = async (totalAFaturar) => {

        console.log('[totalFaturamento] ', totalAFaturar)

        let data = [...campanhaFields]; 

        data.forEach((item, index) => {

            var totalCampanha = data.reduce((a, b) => parseFloat(a) + parseFloat(b.vlTotal), 0);

            var percentual = (parseFloat(item.vlTotal) / totalCampanha * 100);

            var vlTotalPercMed = totalAFaturar * percentual / 100;
            
            data[index]['vlTotalPercMed'] = vlTotalPercMed;
            data[index]['percentMedio'] = percentual;

            console.log('[percentMedio] ', percentual)

        });

    }


    //Calcular adicional
    const calculatePermutaTotal = async (permuta) => {

        var total = await getTotalAtualizado();

        var totalDiscountGlobal = 0;

        if(typeCalc === 'percent') {
            totalDiscountGlobal = (parseFloat(total) * parseFloat(totalDesconto) / 100)
        }else{
            totalDiscountGlobal = totalDesconto;
        }

        var totalGeral = (parseFloat(total) - parseFloat(permuta)) - totalDiscountGlobal;

        
        setTotalAFaturar(totalGeral);
        setVlAmountReceivable(totalGeral);
        setTotalLiquido(totalGeral)

        recalcularCommission(totalGeral);

        await calculateTicketMiddleLiquido(totalGeral)

    }

    //-------------------- Calculando comissão -------------- --------------------

   

 

    async function getTotalCommissionAF(){
        return commissionFields
                .filter((item) => item.typeCommission === 'Antes')
                .reduce((a, b) => parseFloat(a) + parseFloat(b.vlReceivable), 0)
    }

    async function getTotalCommissionDF(){
        return commissionFields
                .filter((item) => item.typeCommission !== 'Antes' )
                .reduce((a, b) => parseFloat(a) + parseFloat(b.vlReceivable), 0)
    }
    

    async function getTotalCommissionFat(){
        return commissionFields
                .reduce((a, b) => parseFloat(a) + parseFloat(b.vlReceivable), 0)
    }

    //----------- totais sobre o ultimo valor ------------------
    // async function getTotalCommissionBeforeLast(){
    //     return commissionFields.filter((item)=>item.typeCommission == 'Antes').slice(-1).reduce((a, b) => a + b.vlReceivable, 0);
    // }

    // async function getTotalCommissionAfterLast(){
    //     return commissionFields.filter((item)=>item.typeCommission == 'Depois').slice(-1).reduce((a, b) => a + b.vlReceivable, 0);
    // }

    //----------------------------------------------------------


    const calculatePercentCommission = async (index, value) => {
        console.log("calculatePercentCommission")

        let data = [...commissionFields];

        var total = parseFloat(totalGeralVenda,2);


        if(data[index]['typeCommission'] === 'Antes'){

            //Valor a receber do item
            data[index]['vlReceivable'] = (parseFloat(value) * total / 100);  

        }
        
        var totalAF = await getTotalCommissionAF();
        
        
        setTotalAFvalue(totalAF);
        
        if(data[index]['typeCommission'] === 'Depois') {
            var totalFaturamento = (total - parseFloat(totalAF));
            data[index]['vlReceivable'] = (parseFloat(value) * parseFloat(totalFaturamento) / 100);
        }
        else        
            if(data[index]['typeCommission'] === 'sobreDF'){

                data[index]['vlReceivable'] = 0;

                var totalDF = await getTotalCommissionDF();
                var totalAFaturar = (total - parseFloat(totalAF));
                var liquido = (totalAFaturar - totalDF) - parseFloat(vlPermuta);

                //O útimo valor do tipo Antes do faturamento do array 
                data[index]['vlReceivable'] = ((value ? parseFloat(value) : 0) * parseFloat(liquido) / 100);  

            }

        var totalDF = await getTotalCommissionDF();
        setTotalDFvalue(totalDF);



        //-------- calculo totais ---------------
        
        var permuta = parseFloat(vlPermuta)

        // Total da venda - totalAF = faturamento
        var totalFaturamento = (parseFloat(total) - parseFloat(totalAF)) - permuta;
        setTotalAFaturar(totalFaturamento);

        //calculando total liquido
        var totalGeralsFinal = await getTotalCommissionFat();

        var totalLiquidoFinal =  (parseFloat(total) - parseFloat(totalGeralsFinal)) - permuta
        setTotalLiquido(totalLiquidoFinal);

        //valor a receber do Informações de Pagamento
        setVlAmountReceivable(totalFaturamento);
        
        await calculateTicketMiddleLiquido(totalLiquidoFinal)

        await calculateTotalPercentualMedio(totalFaturamento)

        await calculateVlLiqPercentualMedio(totalLiquidoFinal)

    }


 
 
    const deleteCommission = (index, input) => {

        setOpenDialogYesOrNo(true);
        setStatusDialogYesOrNo('error')
        setTitleDialogYesOrNo("Deletar comissão do cliente " + input.customer.name)
        setMessageDialogYesOrNo("Tem certeza que deseja deletar a comissão do cliente "+ input.customer.name + " ? ")
        setInputDialog(input)
        setIndexDialog(index)



    }

    const deleteCommissionAndRecalc = (index, input) => {
        console.log("Deletando item commission");

        if(id && input.idCommission) {
            saleService.deleteItemCommission(input.idCommission, id);
            commissionFields.splice(index, 1);
            recalculateTotalCommissionEdit(commissionFields);
            handleDialogCloseYesOrNo();
        }else {
            commissionFields.splice(index, 1);
            recalculateTotalCommissionEdit(commissionFields);
            handleDialogCloseYesOrNo();
        }
    }


    const deleteCampanha = (index, input) => {
        setOpenDialogCampanhaYesOrNo(true);
        setStatusDialogCampanhaYesOrNo('error')
        setTitleDialogCampanhaYesOrNo("Deletar campanha do painel  " + input.panel.name)
        setMessageDialogCampanhaYesOrNo("Tem certeza que deseja deletar o painel " + input.panel.name + " ? ")
        setInputDialogCampanha(input)
        setIndexDialogCampanha(index)
    }

    const deleteCampanhaAndRecalc = (index, input) => {

        console.log(input.idItem)

        if(id && input.idItem){
            saleService.deleteItemCampanha(input.idItem, id)
            campanhaFields.splice(index, 1);
    
            calculateTotalExclusao(campanhaFields)
    
            handleDialogCampanhaCloseYesOrNo();
        }else {
            campanhaFields.splice(index, 1);
            calculateTotalExclusao(campanhaFields);
            handleDialogCampanhaCloseYesOrNo();
        }

    }


    const calculateTotalExclusao = async (item) => {

        var totalCampanha = item.reduce((a, b) => parseFloat(a) + parseFloat(b.vlTotal), 0)

        setTotalItensSale(totalCampanha)

        var vlDiscount = parseFloat(item.discount);
        var vlAcrescimo = parseFloat(item.vlAdicional);
    
        setTotalDesconto(vlDiscount ? vlDiscount : 0)
        setTotalValueAdicional(vlAcrescimo ? vlAcrescimo : 0)

        var totalComDescontoGeral = 0;
        
        if(vlDiscount > 0) {
            if(item.typePercent === 'percent') {

                var totalPlusAditional = (parseFloat(totalCampanha) + vlAcrescimo);
                
                totalComDescontoGeral = (totalPlusAditional - (totalPlusAditional * vlDiscount / 100))
            }else{
                totalComDescontoGeral = ((parseFloat(totalCampanha) + vlAcrescimo) - vlDiscount);
            }

            setTotalGeralVenda(totalComDescontoGeral);
        }else {
            setTotalGeralVenda(parseFloat(totalCampanha));
        }
        
        await recalcularCommission(totalComDescontoGeral ? totalComDescontoGeral : totalCampanha);

    }




    //------------------- Calculo de edição --------------------


    const createTemplatePanel = async (newValue, index) => {

       return newValue?.panels?.forEach(item => (
            addCampanhaFieldsEdit(item, index)
        ));
    }

    const addCampanhaFieldsEdit = async (item, index) => {

            let newCamp = {
                iditem: 0,
                idpanel: '',
                panel: item, 
                qtdPeriod: 0,
                dtStart: dayjs(dayjs(), 'YYYY-MM-DD'),
                dtEnd: dayjs(dayjs(), 'YYYY-MM-DD'),
                idservice: '',
                service: '',
                qtdService: '',
                qtdInsert: '',
                totalInsert: 0,
                vlUnit: '0',
                vlTotal: '0',
                vlLiqPercMed: 0,
                percentLiquid: 0,
                vlTotalPercMed: 0,
                percentMedio: 0
                
            }

        campanhaFields.push(newCamp);

        setCampanhaFields([...campanhaFields])

    // setTotalItensSale(campanhaFields.length)

    }


    async function calcTotalCommissionEdit(item){
        
        var totalSale = await calcTotalSaleEdit(item);
        
         var totalAF = item.commissions.filter((item) => item.typeCommission === 'Antes')
         .reduce((a, b) => parseFloat(a) + parseFloat(b.vlReceivable), 0)
 
 
         var totalDF = item.commissions
                 .filter((item) => item.typeCommission !== 'Antes')
                 .reduce((a, b) => parseFloat(a) + parseFloat(b.vlReceivable), 0)

         setTotalAFvalue(parseFloat(totalAF));
         setTotalDFvalue(parseFloat(totalDF));
 

         //Total a faturar
         var totalAFaturar = (parseFloat(totalSale) - parseFloat(totalAF)) - parseFloat(item.permuta);

         setTotalAFaturar(totalAFaturar);
         
         var totalLiquido = (parseFloat(totalAFaturar) - totalDF);
 
         setTotalLiquido(parseFloat(totalLiquido))
         setVlAmountReceivable(parseFloat(totalAFaturar))


         var totalInsert = item.items.reduce((a, b) => parseInt(a) + parseInt(b.totalInsert), 0);
        
         var totalTicket = parseFloat(totalLiquido) / totalInsert;

         setTicketMiddleLiquido(totalTicket)

        //  await calculateTicketMiddleLiquido(totalLiquido)
 
    }

    async function calcTotalSaleEdit(item){

        var totalFat = item.items
        .reduce((a, b) => parseFloat(a) + parseFloat(b.vlTotal), 0)

        var fatura = totalFat + parseFloat(item.vlAdicional ? item.vlAdicional : 0);

        var faturar = 0;

        if(item.typePercent === 'value'){
            faturar = fatura - parseFloat(item.discount)
        }else{
            
            faturar = fatura - (fatura * parseFloat(item.discount) / 100)
        }


        return faturar;
    }

    const calculateTotalCampanhaEdit = async (item) => {

        var totalCampanha = item.items.reduce((a, b) => parseFloat(a) + parseFloat(b.vlTotal), 0)

        setTotalItensSale(totalCampanha)

        var vlDiscount = parseFloat(item.discount);
        var vlAcrescimo = parseFloat(item.vlAdicional ? item.vlAdicional : 0);

        setTotalDesconto(vlDiscount)
        setTotalValueAdicional(vlAcrescimo)

        var totalComDescontoGeral = 0;
        
        if(vlDiscount > 0) {
            if(item.typePercent === 'percent') {

                var totalPlusAditional = (parseFloat(totalCampanha) + vlAcrescimo);
                
                totalComDescontoGeral = (totalPlusAditional - (totalPlusAditional * vlDiscount / 100))
            }else{
                totalComDescontoGeral = ((parseFloat(totalCampanha) + vlAcrescimo) - vlDiscount);
            }

            setTotalGeralVenda(totalComDescontoGeral);
        }else {

            totalComDescontoGeral = totalCampanha;
            setTotalGeralVenda(parseFloat(totalCampanha) + vlAcrescimo);
        }

        var totalInsert = item.items.reduce((a, b) => parseInt(a) + parseInt(b.totalInsert), 0);
        
        var totalTicket = parseFloat(totalComDescontoGeral) / totalInsert;

        setTicketMiddle(totalTicket);

    }




    async function recalcTotalSaleEdit(item){

        var totalFat = campanhaFields
        .reduce((a, b) => parseFloat(a) + parseFloat(b.vlTotal), 0)

        var fatura = totalFat + parseFloat(vlAdicional ? vlAdicional : 0);

        var faturar = 0;

        if(typeCalc === 'value'){
            faturar = fatura - parseFloat(discount ? discount : 0)
        }else{
            faturar = fatura - (fatura * parseFloat(discount ? discount : 0) / 100)
        }

        return faturar;
    }

    async function recalculateTotalCommissionEdit(item){

        var totalSale = await recalcTotalSaleEdit(campanhaFields);

         var totalAF = item.filter((item) => item.typeCommission === 'Antes')
         .reduce((a, b) => parseFloat(a) + parseFloat(b.vlReceivable), 0)
 
 
         var totalDF = commissionFields
                 .filter((item) => item.typeCommission !== 'Antes')
                 .reduce((a, b) => parseFloat(a) + parseFloat(b.vlReceivable), 0)
 
         setTotalAFvalue(parseFloat(totalAF));
         setTotalDFvalue(parseFloat(totalDF));
 
 
         //Total a faturar
         var totalAFaturar = (parseFloat(totalSale) - parseFloat(totalAF) - parseFloat(vlPermuta));
 
         setTotalAFaturar(totalAFaturar);
         
         var totalLiquido = (parseFloat(totalAFaturar) - totalDF);
 
         setTotalLiquido(parseFloat(totalLiquido))
         setVlAmountReceivable(parseFloat(totalAFaturar))
 
     }




    const showPeriod = (value) => {

        if(value === 'Venda recorrente'){
            return(
                <>
                    <div className="col-md-2 mb-3">
                        <TextField
                            fullWidth
                            focused={qtdMonth && qtdMonth}
                            label="Qtd meses"
                            disabled={isSend ? true : false}
                            id="outlined-size-small"
                            size="small"
                            value={qtdMonth && qtdMonth}
                            onChange={(e)=> setQtdMonth(e.target.value)}
                        />
                    </div> 
                </>
            )    
        }

    }

    const cancel = () => {
        navigate("/listSale");
    }
      
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openService = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleCloseService = () => {
      setAnchorEl(null);
    };

    const [anchorElInsert, setAnchorElInsert] = React.useState(null);
    const openInsert = Boolean(anchorElInsert);

    const handleClickInsert = (event) => {
      setAnchorElInsert(event.currentTarget);
    };
    const handleCloseInsert = () => {
      setAnchorElInsert(null);
    };

    const [open, setOpen] = useState(false);

    const handleFileUpload = (files) => {
        setFileSave(files);
      };

    const handleClose = () => setOpen(false);

    const handleCloseLoading = () => setOpenLoading(false);
    
    const listUpload = async (filename) => {
        
        console.log(filename)

        await apiClient.get('/sale/download/'+filename, 
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }, 
                responseType: 'blob' 
            }).then((response) => {
                
                // Exibe o arquivo no navegador usando o file-saver
                saveAs(response.data, 'arquivo.pdf');
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const changeDatePrevisioSales = async (date)=> {
        const newDate = moment(date).subtract(10, 'days').format('YYYY-MM-DD');
        setDatePrevisionSales(newDate);
    }

    const validatePrevision = async (datePrev) => {

        if(datePrev > dateReceivable){
            
            setIsButtonDisabled(false)
            setOpenDialogFree(true);
            setStatusDialogFree('error')
            setTitleDialogFree("Previsão de faturamento ")
            setMessageDialogFree('A data de previsão de fatutamento não pode ser maior que a data do recebimento')
            setOpenLoading(false)

            changeDatePrevisioSales(dateReceivable);

        }

    }
    


    return(

    <>

    <Container maxWidth="lx" sx={{ bgcolor: '#EBEBEB'}}>

            <DrawerPrincipal  title={"Lançamento de venda"} divOpen={
                <div>

                    <div>
                    <div className="row mb-3">
                        <CustomizedBreadcrumbs title1={"Home"} link1={"/"} 
                                title2={"Lista de Vendas"} link2={"#"} 
                                title3={"Cadastro de Venda"} link3={"#"}/>
                    </div>

                    <ResponsiveDialog 
                        open={openDialog} 
                        handleClose={handleDialogClose}
                        title={titleDialog}
                        message={messageDialog} 
                        status={statusDialog}
                        />

                    <ResponsiveDialog 
                        open={openDialogFree} 
                        handleClose={handleDialogCloseFree}
                        title={titleDialogFree}
                        message={messageDialogFree} 
                        status={statusDialogFree}
                        />

                    <DialogYesOrNo 
                        open={openDialogYesOrNo} 
                        handleClose={handleDialogCloseYesOrNo}
                        input={inputDialog}
                        handleOk={deleteCommissionAndRecalc}
                        index={indexDialog}
                        title={titleDialogYesOrNo}
                        message={messageDialogYesOrNo} 
                        status={statusDialogYesOrNo}
                        />

                    <DialogYesOrNo 
                        open={openDialogCampanhaYesOrNo} 
                        handleClose={handleDialogCampanhaCloseYesOrNo}
                        input={inputDialogCampanha}
                        handleOk={deleteCampanhaAndRecalc}
                        index={indexDialogCampanha}
                        title={titleDialogCampanhaYesOrNo}
                        message={messageDialogCampanhaYesOrNo} 
                        status={statusDialogCampanhaYesOrNo}
                        />


                        <SimpleBackdrop open={openLoading} 
                            handleClose={handleCloseLoading} 
                            title={"Salvando informações..."} 
                            />

                        <div className="row">

                            <h1 className="h3 mb-3 text-black font-weight-bold" >Cadastro de Venda</h1>

                        </div>

                        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 1 }}>
                                <Card>
                                    <div className="form-cadastro" style={{padding: 5}}>
                                    
                                        <div className="row">
                                            <Typography sx={{ width: 300 }} className="mb-3" gutterBottom variant="h6" component="div">
                                                Informações de Venda 
                                            </Typography>
                                            
                                        </div>

                                        <div className="row">

                                            <div className="col-md-2 mb-3">
                                                    <FormControl fullWidth size="small" error={situationError && situationError}>
                                                        <InputLabel id="demo-select-small">Situação da negociação</InputLabel>
                                                        <Select
                                                            labelId="demo-select-small"
                                                            id="demo-select-small"
                                                            value={situation && situation}
                                                            focused={situation && situation}
                                                            label="Situação da negociação"
                                                            disabled={isSend ? true : false}
                                                            onChange={(e) => {
                                                                setSituation(e.target.value);
                                                                setSituationError("");
                                                            }}
                                                        >
                                                            <MenuItem value={"Em Orcamento"}>Em Orçamento</MenuItem>
                                                            <MenuItem value={"Em Autorizacao"}>Em Autorização</MenuItem>
                                                            {
                                                                permSendSale?.isinsert ?  
                                                                    <MenuItem value={"Venda"}>Venda</MenuItem>
                                                                : 
                                                                <MenuItem disabled={true} value={"Venda"}>Venda</MenuItem>
                                                            }
                                                            <MenuItem value={"Orcamento recusado"}>Orçamento recusado</MenuItem>

                                                        </Select>
                                                        <FormHelperText >{situationError ? situationError : ""}</FormHelperText>
                                                    </FormControl>
                                                                                             
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <FormControl fullWidth size="small" error={typeError && typeError}>
                                                        <InputLabel id="demo-select-small">Tipo da venda</InputLabel>
                                                        <Select
                                                            labelId="demo-select-small"
                                                            id="demo-select-small"
                                                            value={type && type}
                                                            focused={type && type}
                                                            disabled={isSend ? true : false}
                                                            label="Tipo da venda"
                                                            onChange={(e) => {
                                                                setType(e.target.value);
                                                                showPeriod(e.target.value);
                                                                setTypeError('')
                                                            }}
                                                        > 
                                                            <MenuItem value={"Venda avulsa"}>Venda avulsa</MenuItem>
                                                            <MenuItem value={"Venda recorrente"}>Venda recorrente</MenuItem>
                                                        </Select>
                                                        <FormHelperText >{typeError ? typeError : ""}</FormHelperText>
                                                    </FormControl>
                                            </div>  
                                            
                                            
                                                {showPeriod(type)}
                                                
                                            
                                            <div className="col-md-3 mb-3">

                                                    <Autocomplete
                                                        size='small'
                                                        id="combo-box-demo"
                                                        value={listIDUser && listIDUser}
                                                        sx={{ width: '100%' }}
                                                        getOptionLabel={(listUser) => listUser ? `${listUser.name}` : ""}
                                                        options={listUser}
                                                        isOptionEqualToValue={(option, value) => option.name === value.name}
                                                        onChange={(event, newValue) => handleChangeUser(newValue)}
                                                        disabled={isSend ? true : false}
                                                        noOptionsText={"Nenhum Usuário com esse nome foi encontrando"}
                                                        renderInput={(params) => (
                                                            <TextField
                                                            {...params}
                                                            label="Escolher vendedor"
                                                            />
                                                        )}
                                                    />
                                            </div> 
                                            <div className="col-md-3 mb-3">

                                                <Autocomplete
                                                        size='small'
                                                        id="combo-box-demo"
                                                        value={listaIDCustomer && listaIDCustomer}
                                                        sx={{ width: '100%' }}
                                                        getOptionLabel={(listCustomer) => `${listCustomer.name ? listCustomer.name : "Escolha o cliente"}`}
                                                        options={listCustomer}
                                                        isOptionEqualToValue={(option, value) => option.name === value.name}
                                                        disabled={isSend ? true : false}
                                                        onChange={(event, newValue) => {
                                                            handleChangeClient(newValue)
                                                            setIdClientError('')
                                                        }}
                                                        noOptionsText={"Nenhum Cliente com esse nome foi encontrando"}
                                                        renderInput={(params) => (
                                                            <TextField
                                                            {...params}
                                                            label="Escolher cliente"
                                                            error={!idClient ? idClientError : ''}
                                                            helperText={!idClient ? idClientError : ''}
                                                            />
                                                        )}
                                                    />
                                                                  
                                            </div>

                                        </div>

                                        <div className="row">
                                            
                                            <div className="col-md-3 mb-3">
                                                
                                                <TextField
                                                    fullWidth                                                            
                                                    label={dateSale ? "Data da venda" : "Data da Venda"}
                                                    id="outlined-size-small"
                                                    focused={dateSale && dateSale}
                                                    type="date"
                                                    size="small"
                                                    value={dateSale && dateSale}
                                                    disabled={isSend ? true : false}
                                                    defaultValue={dateSale && dateSale}
                                                    onChange={(e)=> {
                                                        setDateSale(e.target.value);
                                                        setDateSaleError("");
                                                    }} />
                                            </div> 

                                            <div className="col-md-5 mb-3">
                                                <TextField
                                                    fullWidth
                                                    focused={name && name}
                                                    label="Nome da campanha"
                                                    id="outlined-size-small"
                                                    size="small"
                                                    disabled={isSend ? true : false}
                                                    value={name && name}
                                                    error={nameError && nameError}
                                                    helperText={nameError ? nameError : ""}
                                                    onChange={(e)=> {
                                                        setName(e.target.value);
                                                        setNameError("");
                                                    }}
                                                />
                                            </div> 

                                            <div className="col-md-2 mb-3">
                                                <TextField
                                                    fullWidth
                                                    focused={codPi && codPi}
                                                    label="Código P.I"
                                                    id="outlined-size-small"
                                                    size="small"
                                                    max="30"
                                                    defaultValue=""
                                                    disabled={isSend ? true : false}
                                                    value={ codPi && codPi}
                                                    error={codPiError && codPiError}
                                                    helperText={codPiError ? codPiError : ""}
                                                    onChange={(e)=> {
                                                        setCodPi(e.target.value);
                                                        setCodPiError("");
                                                    }}
                                                />
                                            </div> 

                                            <div className="col-md-2 mb-3">
                                                <TextField
                                                    fullWidth
                                                    focused={codIntern && codIntern}
                                                    label="Código Interno"
                                                    id="outlined-size-small"
                                                    size="small"
                                                    type="number"
                                                    disabled={true}
                                                    value={ codIntern && codIntern}
                                                    onChange={(e)=> {
                                                        setCodIntern(e.target.value);
                                                    }}
                                                />
                                            </div> 

                                        </div>
                                        
                                    </div>
                                </Card>
                                    
                                {/* <Box  className="mt-3"> */}
                                    
                                    <Card style={{padding: 5}} className="mt-3">
                                            <div className="row">

                                                <Typography sx={{ width: 300 }} className="mb-3" gutterBottom variant="h6" component="div">
                                                    Informações da campanha 
                                                </Typography>
                                            </div>
                                            <div className="row mb-2">
                                            <TableContainer >
                                                <Table >
                                                    <TableHead>
                                                        <TableRow style={{backgroundColor: '#e4f2f7'}}>
                                                            <TableCell className="col-2"><strong>Painel</strong></TableCell>
                                                            <TableCell className="col-3" >
                                                                <strong>Período</strong>
                                                               
                                                                <DataRangerPicker 
                                                                    size={'small'}
                                                                    handleChange={(event) => handleCampanhaChange(0, event, 'periodRandom')} 
                                                                    // input={[dayjs('2023-09-10', 'YYYY-MM-DD'), dayjs('2023-09-10', 'YYYY-MM-DD')]}
                                                                        />
                                                                
                                                            </TableCell>
                                                            <TableCell className="col-1" >
                                                                <strong>Dias</strong>
                                                                
                                                            </TableCell>

                                                            <TableCell className="col-2" >
                                                                <div>
                                                                    <div>
                                                                    <strong>Serviço</strong>
                                                                    <Button
                                                                        id="basic-button"
                                                                        aria-controls={openService ? 'basic-menu' : undefined}
                                                                        aria-haspopup="true"
                                                                        aria-expanded={openService ? 'true' : undefined}
                                                                        onClick={handleClick}
                                                                        startIcon={<ManageAccountsOutlinedIcon color="success"/>}
                                                                    >
                                                                        
                                                                    </Button>
                                                                    <Menu
                                                                        id="long-menu"
                                                                        MenuListProps={{
                                                                        'aria-labelledby': 'long-button',
                                                                        }}
                                                                        anchorEl={anchorEl}
                                                                        open={openService}
                                                                        onClose={handleCloseService}
                                                                        PaperProps={{
                                                                        style: {
                                                                            maxHeight: '20ch',
                                                                            // width: '20ch',
                                                                        },
                                                                        }}
                                                                    >
                                                                            <MenuList sx={{ width: 320, maxWidth: '100%', height: '300ch' }}>
                                                                                <Autocomplete
                                                                                    disablePortal
                                                                                    size='small'
                                                                                    id="combo-box-demo"
                                                                                    name="idservice"
                                                                                    // value={input.service && input.service}
                                                                                    options={listService}
                                                                                    sx={{ width: '100%' }}
                                                                                    disabled={isSend ? true : false}
                                                                                    getOptionLabel={(listService) => listService ? `${listService.name}` : ""}
                                                                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                                                                    onChange={(event, newValue) => {
                                                                                        handleCampanhaChange(0, newValue, 'serviceRandom');
                                                                                    }}
                                                                                    noOptionsText={"Nenhum Serviço com esse nome foi encontrando"}
                                                                                    renderInput={(params) => (
                                                                                        <TextField
                                                                                        {...params}
                                                                                        label="Escolher Serviço"
                                                                                        />
                                                                                    )}
                                                                                />  
                                                                            </MenuList>
                                                                    </Menu>
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className="col-1" >
                                                                    <strong>inserções</strong>

                                                                    <Button
                                                                        id="basic-button"
                                                                        aria-controls={openInsert ? 'basic-menu' : undefined}
                                                                        aria-haspopup="true"
                                                                        aria-expanded={openInsert ? 'true' : undefined}
                                                                        onClick={handleClickInsert}
                                                                        startIcon={<ManageAccountsOutlinedIcon color="success"/>}
                                                                    >
                                                                        
                                                                    </Button>
                                                                    <Menu
                                                                        id="long-menu"
                                                                        MenuListProps={{
                                                                        'aria-labelledby': 'long-button',
                                                                        }}
                                                                        anchorEl={anchorElInsert}
                                                                        open={openInsert}
                                                                        onClose={handleCloseInsert}
                                                                        PaperProps={{
                                                                        style: {
                                                                            maxHeight: '20ch',
                                                                            // width: '20ch',
                                                                        },
                                                                        }}
                                                                    >
                                                                            <MenuList sx={{ width: 320, maxWidth: '100%', height: '300ch' }}>
                                                                                <Autocomplete
                                                                                    disablePortal
                                                                                    size='small'
                                                                                    id="combo-box-demo"
                                                                                    name="qtdInsert"
                                                                                    // value={input.qtdInsert && input.qtdInsert}
                                                                                    options={listInserts}
                                                                                    sx={{ width: '100%' }}
                                                                                    disabled={isSend ? true : false}
                                                                                    getOptionLabel={(listInserts) => listInserts ? `${listInserts}` : ""}
                                                                                    isOptionEqualToValue={(option, value) => option === value}
                                                                                    onChange={(event, newValue) => {
                                                                                        handleCampanhaChange(0, newValue, 'insertRandom');
                                                                                    }}
                                                                                    noOptionsText={"Nenhum inserção cadastrada no momento com esse valor"}
                                                                                    renderInput={(params) => (
                                                                                        <TextField
                                                                                        {...params}
                                                                                        label="Qtd inserção"
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </MenuList>
                                                                    </Menu>        
                                                                    
                                                            </TableCell>
                                                            <TableCell className="col-1" ><strong>Unitário </strong></TableCell>
                                                            <TableCell className="col-2" ><strong>Total </strong></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                </Table>
                                            </TableContainer>
                                        </div>

                                        {campanhaFields.map((input, index)=>{
                                            
                                            return ( <Box
                                                    sx={{
                                                        width: '100%',
                                                        
                                                    }}
                                                    >
                                                
                                                    <div className="row" key={input.idpanel}>

                                                        <div className="col-2 mt-3">
                                                            <Autocomplete
                                                                    size='small'
                                                                    id="country-select-demo"
                                                                    value={input.panel && input.panel}
                                                                    name="idpanel"
                                                                    sx={{ width: '100%' }}
                                                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                                                    getOptionLabel={(listPanel) =>  listPanel ? `${listPanel.name}` : "" }
                                                                    options={listPanel}
                                                                    disabled={isSend ? true : false}
                                                                    onChange={(event, newValue) => {
                                                                        handleCampanhaChange(index, newValue, 'idpanel');
                                                                        campanhaFields.splice(index, 1); 
                                                                        createTemplatePanel(newValue, index);
                                                                        input.idpanelError = '';
                                                                    }}
                                                                    noOptionsText={"Nenhum Painel com esse nome foi encontrando"}
                                                                    renderInput={(params) => (
                                                                            <TextField
                                                                            {...params}
                                                                            label="Escolher Painel"
                                                                            error={!input.panel ? input.idpanelError : ''}
                                                                            helperText={!input.panel ? input.idpanelError : ''}
                                                                        />
                                                                    )}
                                                                />
                                                        </div>

                                                        <div className="col-3 mt-3">
                                                            <FormControl fullWidth size="large">
                                                                    
                                                                    <DataRangerPicker 
                                                                        handleChange={(event) => handleCampanhaChange(index, event, 'periodo')} 
                                                                        input={[dayjs(input.dtStart, 'YYYY-MM-DD'), dayjs(input.dtEnd, 'YYYY-MM-DD')]} 
                                                                        disabledDate={isSend ? true : false}
                                                                        />
                                                                       
                                                            </FormControl>
                                                        </div>
                                                        <div className="col-1 mt-3">
                                                            <TextField
                                                                fullWidth                                                            
                                                                label={input.qtdService ? "Qtd" : "Qtd"}
                                                                id="outlined-size-small"
                                                                name="qtdService"
                                                                disabled="true"
                                                                sx={{ width: 60 }}
                                                                focused={input.qtdService && input.qtdService}
                                                                type="number"
                                                                size="small"
                                                                value={input.qtdService && input.qtdService}
                                                                onChange={(event) => {
                                                                    handleCampanhaChange(index, event)
                                                                }} />

                                                        </div>

                                                        <div className="col-2 mt-3">


                                                            <Autocomplete
                                                                disablePortal
                                                                size='small'
                                                                id="combo-box-demo"
                                                                name="idservice"
                                                                value={input.service && input.service}
                                                                options={listService}
                                                                sx={{ width: '100%' }}
                                                                disabled={isSend ? true : false}
                                                                getOptionLabel={(listService) => listService ? `${listService.name}` : ""}
                                                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                                                onChange={(event, newValue) => {
                                                                    handleCampanhaChange(index, newValue, 'idservice');
                                                                    input.idserviceError = ''
                                                                }}
                                                                noOptionsText={"Nenhum Serviço com esse nome foi encontrando"}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                    {...params}
                                                                    label="Escolher Serviço"
                                                                    error={!input.service ? input.idserviceError : ''}
                                                                    helperText={!input.service ? input.idserviceError : ''}
                                                                    />
                                                                )}
                                                            />                                                        
                                                        </div>

                                                        <div className="col-1 mt-3">

                                                                <Autocomplete
                                                                    disablePortal
                                                                    size='small'
                                                                    id="combo-box-demo"
                                                                    name="qtdInsert"
                                                                    value={input.qtdInsert && input.qtdInsert}
                                                                    options={listInserts}
                                                                    sx={{ width: '100%' }}
                                                                    disabled={isSend ? true : false}
                                                                    getOptionLabel={(listInserts) => listInserts ? `${listInserts}` : ""}
                                                                    isOptionEqualToValue={(option, value) => option === value}
                                                                    onChange={(event, newValue) => {
                                                                        handleCampanhaChange(index, newValue, 'qtdInsert');
                                                                        input.qtdInsertError = '';
                                                                    }}
                                                                    noOptionsText={"Nenhum inserção cadastrada no momento com esse valor"}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            label="Qtd inserção"
                                                                            error={!input.qtdInsert ? input.qtdInsertError : ''}
                                                                            helperText={!input.qtdInsert ? input.qtdInsertError : ''}
                                                                        />
                                                                    )}
                                                                />

                                                        </div>

                                                    
                                                        <div className="col-1 mt-3">
                                                            
                                                        <Box
                                                            sx={{
                                                                color: 'success.dark',
                                                                display: 'inline',
                                                                fontWeight: 'bold',
                                                                mx: 0.5,
                                                                fontSize: 14,
                                                            }}
                                                            >

                                                            {parseFloat(input.vlUnit).toLocaleString('pt-BR', config)}
                                                        </Box>

                                                        </div>
                                                        <div className="col-2 mt-3">
                                                            <div className="row">
                                                                <div className="col-8">
                                                                <Box
                                                                    sx={{
                                                                        color: 'primary.dark',
                                                                        display: 'inline',
                                                                        fontWeight: 'bold',
                                                                        mx: 0.5,
                                                                        fontSize: 14,
                                                                    }}
                                                                    >
                                                                        {input.vlTotal.toLocaleString('pt-BR', config)}
                                                                </Box>
                                                                    {/* <Typography variant="h6"> */}
                                                                        {/* {input.vlTotal.toLocaleString('pt-BR', config)} */}
                                                                    {/* </Typography> */}
                                                                </div>
                                                                <div className="col-2" >
                                                                    <FormControl fullWidth size=""  >
                                                                        {isSend ? 
                                                                            <Link href="#"  style={{display: 'none'}}>
                                                                                <DeleteIcon fontSize="medium" color="error" onClick={(event) => deleteCampanha(index, input)} />
                                                                            </Link>
                                                                        :
                                                                            <Link href="#" >
                                                                                <DeleteIcon fontSize="medium" color="error" onClick={(event) => deleteCampanha(index, input)} />
                                                                            </Link>
                                                                        }
                                                                    </FormControl>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    
                                                    </div>
                                                </Box>
                                            );

                                        })}
                                        
                                            <div className="row mb-3">
                                                <div className="col-md-2">
                                                
                                                </div>
                                                <div className="col-md-2">
                                                
                                                </div>
                                                <div className="col-md-2">
                                                
                                                </div>
                                                <div className="col-md-4">

                                                </div>
                                                <div className="col-md-2">
                                                    
                                                </div>
                                            </div>


                                            <div className="row " style={{padding: 15}}>
                                                <div className="col-md-2 ">
                                                <Stack direction="row" spacing={2}>
                                                    <Button variant="contained" disabled={isSend ? true : false}  size="small" onClick={addCampanhaFields} startIcon={<AddIcon />}>
                                                        Adicionar
                                                    </Button>
                                                </Stack>
                                                </div>
                                                <div className="col-md-10 ">
                                                </div>
                                            </div>           
                                            <Divider />
                                            <div className="row " style={{padding: 15}}>
                                                <div className="col-md-1 ">
                                                    <ToggleButtonGroup
                                                        value={typeCalc}
                                                        exclusive
                                                        disabled={isSend ? true : false}
                                                        onChange={(e)=> {
                                                            setTypeCalc(e.target.value)
                                                        }}
                                                        size="small"
                                                        color="primary"
                                                        aria-label="text alignment"
                                                        >
                                                            <ToggleButton value="value" aria-label="left aligned">
                                                                R$
                                                            </ToggleButton>
                                                            <ToggleButton value="percent" aria-label="centered">
                                                                %
                                                            </ToggleButton>
                                                    </ToggleButtonGroup>
                                                    
                                                </div>
                                                <div className="col-md-2">
                                                    {
                                                        typeCalc === 'value'? 
                                                        <MyComponent  
                                                            handleInput={handleInputDiscount} 
                                                            value={discount && discount}
                                                            isDisable={isSend ? true : false}
                                                            labelInput="Desconto"/> 

                                                        :
                                                        <TextField
                                                            label="Desconto"
                                                            id="Desconto"
                                                            size="small"
                                                            disabled={isSend ? true : false}
                                                            value={discount && discount}
                                                            onChange={(e)=> {
                                                                handleInputDiscount(e.target.value)
                                                            }} 
                                                            InputProps={{ 
                                                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                            }}
                                                        />
                                                    }
                                                </div>
                                                <div className="col-md-2">

                                                    <MyComponent 
                                                        handleInput={handleInputAdicional} 
                                                        value={vlPermuta && vlPermuta}
                                                        isDisable={isSend ? true : false}
                                                        labelInput="Permuta"/>
                                                </div>
                                            </div>
                                    </Card>
                                    
                                    <div className="row">
                                        <div className="row mt-3" style={{background: 'rgb(236 241 249)'}}>
                                            <div className="col-2">
                                                <p style={{fontSize: '13px'}}>Total de Iten(s) <p>{campanhaFields.length} </p></p> 
                                            </div> 
                                            <div className="col-2">
                                                <p style={{fontSize: '13px'}}>Ticket médio : <p>{ticketMiddle.toLocaleString('pt-BR', config)}</p> </p>
                                            </div>
                                            <div className="col-1">
                                            </div>
                                            <div className="col-md-2">
                                            </div> 
                                            <div className="col-md-2 ">
                                                <p style={{fontSize: '13px'}}>Itens da venda  <p style={{color: 'blue'}}>{totalItensSale.toLocaleString('pt-BR', config)} </p></p> 
                                                {/* <p style={{fontSize: '13px'}}>Valores adicionais  <p style={{color: 'blue'}}>{totalValueAdicional.toLocaleString('pt-BR', config)} </p></p>  */}
                                            </div> 
                                            <div className="col-md-2">
                                                <p style={{fontSize: '13px'}}> Desconto  <p style={{color: 'red'}}>{
                                                    typeCalc === 'value' ? 
                                                        totalDesconto.toLocaleString('pt-BR', config)
                                                    :
                                                    totalDesconto + '%'
                                                } </p></p> 
                                            </div> 
                                            <div className="col-md-1">
                                                <p style={{fontSize: '13px'}}> Total
                                                    <p style={{color: 'green'}} id="totalGeral">
                                                        {totalGeralVenda.toLocaleString('pt-BR', config)} 
                                                    </p>
                                                </p>
                                            </div> 

                                        </div>
                                    </div>

                                    <Card style={{padding: 5}} className="mt-3">
                                        <div className="row">
                                            <Typography sx={{ width: 400 }} className="mb-3" gutterBottom variant="h6" component="div">
                                                Informações de Comissionamentos 
                                            </Typography>
                                            
                                        </div>
                                        <div className="row mb-2">
                                            <TableContainer >
                                                <Table >
                                                    <TableHead>
                                                        <TableRow style={{backgroundColor: '#e4f2f7'}}>
                                                            <TableCell className="col-md-2" ><strong>Tipo Comissão</strong></TableCell>
                                                            <TableCell className="col-md-3"><strong>Nome Parceiro</strong></TableCell>
                                                            <TableCell className="col-md-2" ><strong>Data de recebimento </strong></TableCell>
                                                            <TableCell className="col-md-1" ><strong>Parcelas</strong></TableCell>
                                                            <TableCell className="col-md-1" ><strong>Percentual</strong></TableCell>
                                                            <TableCell className="col-md-2" ><strong>Valor a receber</strong></TableCell>
                                                            <TableCell className="col-md-1" ><strong>Ações </strong></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                        
                                        {commissionFields.map((input, index)=>{
                                            return ( <div className="row" key={index}>

                                                    <div className="col-md-2 mb-3"> 
                                                        <FormControl fullWidth size="small"  error={input.typeCommissionError && input.typeCommissionError}>
                                                            <InputLabel id="demo-select-small">Tipo de Commissão</InputLabel>
                                                            <Select
                                                                labelId="demo-select-small"
                                                                id="demo-select-small"
                                                                name="typeCommission"
                                                                disabled={isSend ? true : false}
                                                                value={input.typeCommission && input.typeCommission}
                                                                focused={input.typeCommission && input.typeCommission}
                                                                label="Situação da negociação"
                                                                onChange={(event) => {
                                                                    handleCommissionChange(index, event);
                                                                    input.typeCommissionError = ''
                                                                }}
                                                            > 
                                                                {/* <MenuItem disabled="true" value={"Antes"}>Antes do faturamento</MenuItem> */}
                                                                <MenuItem value={"Depois"}>Depois do faturamento</MenuItem>
                                                                <MenuItem value={"sobreDF"}>Sobre o ultimo valor DF </MenuItem>

                                                            </Select>
                                                            <FormHelperText >{input.typeCommissionError ? input.typeCommissionError : ""}</FormHelperText>
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <FormControl fullWidth size="small"  error={input.idClientError && input.idClientError}>
                                                            <Autocomplete
                                                                    disablePortal
                                                                    size='small'
                                                                    id="combo-box-demo"
                                                                    name="idClient"
                                                                    value={input.customer && input.customer}
                                                                    sx={{ width: 280}}
                                                                    disabled={isSend ? true : false}
                                                                    getOptionLabel={(listCustomer) => listCustomer ? `${listCustomer.name}` : ""}
                                                                    options={listCustomer}
                                                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                                                    onChange={(event, newValue) => {
                                                                        handleCommissionChange(index, newValue, 'idClient');
                                                                        input.idClientError = ''
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                        {...params}
                                                                        label="Escolher cliente"
                                                                        error={!input.idClient ? input.idClientError : ''}
                                                                        helperText={!input.idClient ? input.idClientError : ''}
                                                                        />
                                                                    )}
                                                                />
                                                        </FormControl>
                                                    </div>

  
                                                    <div className="col-md-2">
                                                    
                                                        <TextField
                                                            fullWidth                                                            
                                                            label={input.dateReceivable ? "Data de recebimento" : "Data de recebimento"}
                                                            id="outlined-size-small"
                                                            name="dateReceivable"
                                                            disabled={isSend ? true : false}
                                                            focused={input.dateReceivable && input.dateReceivable}
                                                            type="date"
                                                            size="small"
                                                            value={input.dateReceivable && input.dateReceivable}
                                                            error={input.dataReceivableError && input.dataReceivableError}
                                                            helperText={!input.dateReceivable ? input.dataReceivableError : ''}
                                                            onChange={(event) => {
                                                                handleCommissionChange(index, event, 'dataReceivable');
                                                                input.dataReceivableError = '';
                                                            }}
                                                        />

                                                    </div>

                                                    <div className="col-md-1">
                                                    
                                                        <TextField
                                                            fullWidth                                                             
                                                            label={input.months ? "Parcelas" : "Parcelas"}
                                                            id="outlined-size-small"
                                                            name="months"
                                                            disabled={isSend ? true : false}
                                                            focused={input.months && input.months}
                                                            type="number"
                                                            size="small"
                                                            value={input.months && input.months}
                                                            error={input.monthsError && input.monthsError}
                                                            helperText={input.monthsError ? input.monthsError : ''}
                                                            onChange={(event) => {
                                                                handleCommissionChange(index, event);
                                                                input.monthsError = '';
                                                            }}
                                                        />

                                                    </div>

                                                    <div className="col-md-1">
                                                        <TextField
                                                            fullWidth                                                            
                                                            label={input.percent ? "%" : "%"}
                                                            name="percent"
                                                            id="outlined-size-small"
                                                            disabled={isSend ? true : false}
                                                            focused={input.percent && input.percent}
                                                            size="small"
                                                            value={input.percent && input.percent}
                                                            error={input.percentError && input.percentError}
                                                            helperText={input.percentError ? input.percentError : ''}
                                                            onChange={(event) => {
                                                                handleCommissionChange(index, event)
                                                                calculatePercentCommission(index, event.target.value)
                                                                input.percentError = ''
                                                            }}
                                                        />
                                                        
                                                    </div>
                                                    <div className="col-md-2">

                                                            <MyComponent 
                                                            handleInput={(event) => handleInputVlReceivable(index, event)} 
                                                            value={input.vlReceivable && input.vlReceivable}
                                                            disabled={isSend ? true : false}
                                                            isDisable="true"
                                                            labelInput="Valor a receber"/>

                                                    </div>
                                                    
                                                    <div className="col-md-1">
                                                        <Stack direction="row" alignItems="center">
                                                        {isSend ?
                                                             <Link href="#" style={{display: 'none'}}>
                                                                <DeleteIcon fontSize="medium" disabled={isSend ? true : false}  color="error" onClick={(event) => deleteCommission(index, input)} />
                                                             </Link>
                                                            : 
                                                            <Link href="#">
                                                                <DeleteIcon fontSize="medium" disabled={isSend ? true : false}  color="error" onClick={(event) => deleteCommission(index, input)} />
                                                            </Link>
                                                        }
                                                        </Stack>
                                                    </div>
                                                    
                                                </div>
                                            );
                                            })
                                        }
                                        <div className="row"> 
                                            <div className="col-md-5">
                                            
                                            </div>
                                            <div className="col-md-5">

                                            </div>
                                            <div className="col-md-2">
                                            
                                            </div>
                                        </div>

                                        <div className="row " style={{padding: 15}}>
                                            <div className="col-md-2 ">
                                                <Stack direction="row" spacing={2}>
                                                    <Button variant="contained" disabled={isSend ? true : false}  onClick={addComissionFields} size="small" startIcon={<AddIcon />}>
                                                        Adicionar
                                                    </Button>
                                                </Stack>
                                            </div>
                                            <div className="col-md-10 ">
                                            </div>
                                        </div>    
                                    </Card>
                                    <div className="row">
                                        <div className="row mt-4" style={{background: 'rgb(236 241 249)', padding: 5}}>
                                            <div className="col-2">
                                                <p style={{fontSize: '13px'}}>Total de Iten(s) <p>{commissionFields.length} </p></p> 
                                            </div> 
                                            <div className="col-2">
                                                <p style={{fontSize: '13px'}}>Ticket médio 2 : <p>{ticketMiddleLiquido.toLocaleString('pt-BR', config)}</p> </p>
                                            </div>
                                            <div className="col-md-2 ">
                                                {/* <p style={{fontSize: '13px'}}>
                                                    Comissões AF <p style={{color: 'blue'}}> {totalAFvalue.toLocaleString('pt-BR', config)} </p>
                                                    
                                                </p>  */}
                                            </div> 
                                            <div className="col-md-2 ">
                                                <p style={{fontSize: '13px'}}>Total a faturar  <p style={{color: 'blue'}}> {totalAFaturar.toLocaleString('pt-BR', config)} </p></p> 
                                            </div> 
                                            <div className="col-md-2 ">
                                                <p style={{fontSize: '13px'}}> Comissões DF  <p style={{color: 'red'}}> {totalDFvalue.toLocaleString('pt-BR', config)}</p></p> 
                                            </div> 
                                            <div className="col-md-2">
                                            <p style={{fontSize: '13px'}}> Total líquido
                                                <p style={{color: 'green'}}> {totalLiquido.toLocaleString('pt-BR', config)}</p>
                                            </p>
                                            </div> 

                                        </div>
                                    </div>


                                    <Card style={{padding: 5}} className="mt-3">
                                        <div className="row">
                                            <Typography sx={{ width: 300 }} className="mb-3" gutterBottom variant="h6" component="div">
                                                Informações de Pagamento
                                            </Typography>
                                        </div>
                                        
                                        <div className="row">

                                            <div className="col-md-2">
                                                <FormControl fullWidth size="small" error={typePaymentError && typePaymentError}>
                                                    <InputLabel id="demo-select-small">Tipo </InputLabel>
                                                    <Select
                                                        labelId="demo-select-small"
                                                        id="demo-select-small"
                                                        disabled={isSend ? true : false}
                                                        value={typePayment && typePayment}
                                                        focused={typePayment && typePayment}
                                                        label="Situação da negociação"
                                                        onChange={(e) => {
                                                            setTypePayment(e.target.value);
                                                            setTypePaymentError("");
                                                        }}
                                                    >
                                                        <MenuItem disabled={true} value={"Pix"}>Pix</MenuItem>
                                                        <MenuItem value={"Boleto"}>Boleto</MenuItem>
                                                        <MenuItem value={"Deposito bancario"}>Deposito bancário</MenuItem>
                                                        
                                                    </Select>
                                                    <FormHelperText >{typePaymentError ? typePaymentError : ""}</FormHelperText>
                                                </FormControl>
                                            </div>

                                            <div className="col-md-2 mb-3">
                                                <FormControl fullWidth size="small" error={formConditionError && formConditionError}>
                                                        <InputLabel id="demo-select-small">Condição </InputLabel>
                                                        <Select
                                                            labelId="demo-select-small"
                                                            id="demo-select-small"
                                                            value={type === 'Venda recorrente' ? "1x" : formCondition && formCondition}
                                                            disabled={(isSend || type === 'Venda recorrente') ? true : false}
                                                            focused={formCondition && formCondition}
                                                            label="Situação da negociação"
                                                            onChange={(e) => {
                                                                setFormCondition(e.target.value);
                                                                setFormConditionError("");
                                                            }}
                                                        >
                                                            <MenuItem value={"1x"}>1x</MenuItem>
                                                            <MenuItem value={"2x"}>2x</MenuItem>
                                                            <MenuItem value={"3x"}>3x</MenuItem>
                                                            <MenuItem value={"4x"}>4x</MenuItem>
                                                            <MenuItem value={"5x"}>5x</MenuItem>
                                                            <MenuItem value={"6x"}>6x</MenuItem>
                                                            <MenuItem value={"7x"}>7x</MenuItem>
                                                            <MenuItem value={"8x"}>8x</MenuItem>
                                                            <MenuItem value={"9x"}>9x</MenuItem>
                                                            <MenuItem value={"10x"}>10x</MenuItem>
                                                        </Select>
                                                        <FormHelperText >{formConditionError ? formConditionError : ""}</FormHelperText>
                                                    </FormControl>
                                            </div>

                                            <div className="col-md-2">

                                                    <FormControl fullWidth size="small" error={intervaloPagtoError && intervaloPagtoError}>
                                                        <InputLabel id="demo-select-small">Intervalo </InputLabel>
                                                        <Select
                                                            labelId="select-interval"
                                                            id="select-interval"
                                                            value={type === 'Venda recorrente' ? "Mes" : intervaloPagto === "30" ? "Mes" : intervaloPagto && intervaloPagto}
                                                            disabled={isSend || formCondition === '1x' || type === 'Venda recorrente' ? true : false}
                                                            focused={intervaloPagto && intervaloPagto}
                                                            label="Intervalo entre parcelas"
                                                            onChange={(e) => {
                                                                setIntervaloPagto(e.target.value);
                                                                setIntervaloPagtoError("");
                                                            }}
                                                        >
                                                            <MenuItem value={"15"}>15</MenuItem>
                                                            <MenuItem value={"Mes"}>Mês</MenuItem>
                                                        </Select>
                                                        <FormHelperText >{intervaloPagtoError ? intervaloPagtoError : ""}</FormHelperText>
                                                    </FormControl>


                                                 {/* <TextField
                                                    fullWidth                                                            
                                                    label={intervaloPagto ? "Intervalo entre parcelas" : "Intervalo entre parcelas"}
                                                    id="outlined-size-small"
                                                    name="vlTotal"
                                                    focused={intervaloPagto && intervaloPagto}
                                                    size="small"
                                                    disabled={isSend || formCondition == '1x' ? true : false}
                                                    value={intervaloPagto && intervaloPagto}
                                                    error={formCondition != '1x' && intervaloPagtoError && intervaloPagtoError}
                                                    helperText={formCondition != '1x' && intervaloPagtoError ? intervaloPagtoError : ""}
                                                    onChange={(e) => {
                                                        setIntervaloPagto(e.target.value);
                                                        setIntervaloPagtoError("");
                                                    }}
                                                /> */}
                                            </div>

                                            <div className="col-md-2">
                                                <MyComponent 
                                                    handleInput={handleInputValueReceivable} 
                                                    value={vlAmountReceivable && vlAmountReceivable}
                                                    isDisable="true"
                                                    labelInput="Valor a receber"
                                                    />
                                                
                                            </div>
                                            <div className="col-md-2">
                                                
                                                <TextField
                                                    fullWidth                                                            
                                                    label={dateReceivable ? "Data do recebimento" : "Data do recebimento"}
                                                    id="outlined-size-small"
                                                    focused={dateReceivable && dateReceivable}
                                                    // disabled={isSend ? true : false}
                                                    type="date"
                                                    size="small"                                                    
                                                    value={dateReceivable && dateReceivable}
                                                    onChange={(e)=> {
                                                        setDateReceivable(e.target.value);
                                                        setDateReceivableError("");
                                                        changeDatePrevisioSales(e.target.value);
                                                    }} />

                                            </div>

                                            <div className="col-md-2">
                                                
                                                <TextField
                                                    fullWidth                                                            
                                                    label={datePrevisionSales ? "Previsão de Faturamento" : "Previsão de Faturamento"}
                                                    id="outlined-size-small"
                                                    focused={datePrevisionSales && datePrevisionSales}
                                                    // disabled={isSend ? true : false}
                                                    type="date"
                                                    size="small"                                                    
                                                    value={datePrevisionSales && datePrevisionSales}
                                                    onChange={(e)=> {
                                                        setDatePrevisionSales(e.target.value);
                                                        setDatePrevisionSalesError("");
                                                        validatePrevision(e.target.value);
                                                    }} />
                                                {/* <FormHelperText style={{color: "red" }}>{datePrevisionSalesError ? datePrevisionSalesError : ""}</FormHelperText> */}
                                            </div>
                                            
                                            
                                        </div>
                                        
                                    </Card>

                                    <Card className="mt-2">
                                        <CardContent>
                                            <div className="row "> 
                                                <Typography sx={{ width: 300 }} className="mb-3" gutterBottom variant="h6" component="div">
                                                    Observações de pagamento
                                                </Typography>
                                                <div className="col-md-12 mb-3">
                                                    
                                                    <TextField
                                                        fullWidth
                                                        id="outlined-multiline-static"
                                                        focused={observation && observation}
                                                        label="Observação" 
                                                        // disabled={isSend ? true : false}
                                                        defaultValue={observation && observation}
                                                        multiline
                                                        rows={6}
                                                        onChange={(e) => setObservation(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>

                                        <div className='row' style={{padding: 50}}>
                                            <div className="col-md-3">
                                               
                                               {

                                                    fileId ? 

                                                    <Button variant="contained" color="success" disabled={isSend ? true : false} onClick={() => setOpen(true)}>
                                                        <AddIcon /> Anexar P.I
                                                    </Button>
                                                        // <Button variant="contained" color="success" onClick={listUpload} sx={{ mr: 1 }} >
                                                        //     <PictureAsPdfOutlinedIcon />  Download P.I
                                                        // </Button> 
                                                    :
                                                        <Button variant="contained" color="success" disabled={isSend ? true : false} onClick={() => setOpen(true)}>
                                                            <AddIcon /> Anexar P.I
                                                        </Button>

                                                }
                                               
                                            </div>
                                            <div className="col-md-5">
                                            </div>
                                            <div className="col-md-2">
                                                <Button variant="contained" color="warning" onClick={cancel} sx={{ mr: 1 }}>
                                                    <HighlightOffIcon />{"Cancelar"}
                                                </Button>
                                            </div>
                                            <div className="col-md-2">
                                                {
                                                    permission?.isinsert && !isSend ? 
                                                        <Button 
                                                            variant="contained" 
                                                            disabled={isButtonDisabled && isButtonDisabled}
                                                            onClick={id ? update : save} sx={{ mr: 1 }}>
                                                            <AddIcon /> Salvar
                                                        </Button>
                                                    :
                                                   
                                                    <Button 
                                                        variant="contained" 
                                                        onClick={updateDate} sx={{ mr: 1 }}>
                                                        <AddIcon /> Salvar
                                                    </Button> 
                                                    
                                                }
                                            </div>

                                        </div>

                                <UploadFile 
                                open={open} 
                                isDownload={filePI.idfile ? true : false}
                                listUpload={listUpload}
                                file={filePI}
                                handleUpload={handleFileUpload}
                                handleClose={handleClose}
                                />

                        </Paper>
                    </div>
                </div>
            }/>

        </Container>

    </>

    )

}
export default NewSale;