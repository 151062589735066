import React from 'react';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import moment from 'moment';


const MenuDropdown = ({list}) => {
    
    const content = (
        list.map(element => {
            return (
                <div>
                    <p>
                       {
                        element.isAvailable ? 
                            <p><CheckCircleFilled style={{color: 'green'}} /> - {moment(element.date).format('DD/MM/YYYY')} </p>
                        : 
                            <p><CloseCircleFilled style={{color: 'red'}}/> - {moment(element.date).format('DD/MM/YYYY')} </p>
                       } 
                    </p>
                </div>            
            )
            
        })
    );

    return (
        <Popover content={content} title="Disponibilidade">
        <Button type="primary">Status</Button>
      </Popover>
    );

  };

export default MenuDropdown;