import { apiClient } from "./api";


const panelService = {


    async list(){

        var retorno = await apiClient.get('/panel');

        return retorno.data;
    },

    async listActive(){

        var retorno = await apiClient.get('/panel/active');

        return retorno.data;
    },

    async edit(id){

        var retorno = await apiClient.get("/panel/"+id);
        console.log(retorno.data)
        return retorno.data;
    },

    async listPanelFilter(name){
        console.log(name)

        var retorno = await apiClient.get('/panel/filter/'+name);

        return retorno.data;
    },

    async save(customer){
        console.log(customer)

        var retorno = await apiClient.post("/panel", customer);

        return retorno;

    },

    async update(customer){
        console.log(customer)

        var retorno = await apiClient.put("/panel", customer);

        return retorno;

    },

    async delete(idcustomer){
        console.log(idcustomer)

        var retorno = await apiClient.delete("/panel/"+idcustomer);

        return retorno;

    }

}

export default panelService;